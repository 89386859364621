import {
  isInitialized as isIfcInitialized,
  navigate,
  ServicesKeys,
} from '@canalplus/ifc-onecore';
import { getQsValueByKey } from '@canalplus/mycanal-commons';
import type { PassUserDataEnriched } from '@canalplus/sdk-pass';
import type { ApiV2Profile } from '@dce-front/hodor-types/api/v2/me/profiles/definitions';
import Cookies from 'js-cookie';
import { CookieKey } from '../../constants/cookie';
import { PASS_TOKEN_VALIDITY } from '../../constants/limits';
import { Queries } from '../../constants/url';
import { getCurrentProfile } from '../../services/profiles/profileStorageService';
import type { IState } from '../../store/types/State-type';
import { getClientSideUserInfos } from './getClientSideUserInfos';

/**
 * Get an encoded **passID** from the last `passId` | `p_pass_token` cookie\
 * Each time we pass a passId, we need to encode it!
 *
 * @returns The **encoded passId** or `undefined`
 */
export const getEncodedPassId = (): string | undefined => {
  const passId =
    Cookies.get(CookieKey.PassId) || Cookies.get(CookieKey.PPassToken);

  if (passId) {
    return encodeURIComponent(passId);
  }

  return undefined;
};

/**
 * Handle client side redirection to select region page
 *
 */
export const clientSideSelectRegionRedirection = (contentID?: string): void => {
  if (isIfcInitialized()) {
    navigate({
      skey: ServicesKeys.SELECT_REGION,
      context: contentID ? { deeplink: { contentId: contentID } } : undefined,
    }).catch(console.error);
  }
};

/**
 * Handle client-side redirection to oneshop webapp
 */
export const clientSideOneShopRedirection = (contentID?: string): void => {
  if (isIfcInitialized()) {
    navigate({
      skey: ServicesKeys.ONE_SHOP,
      context: contentID ? { deeplink: { contentId: contentID } } : undefined,
    });
  }
};

export const sortProfiles = (profiles: ApiV2Profile[]): ApiV2Profile[] => {
  const currentProfile = getCurrentProfile();

  const defaultProfileIndex = profiles.findIndex(
    (profile) =>
      currentProfile && profile.profileId === currentProfile.profileId
  );

  const sortedProfiles = [...profiles];

  if (defaultProfileIndex > -1) {
    sortedProfiles.splice(defaultProfileIndex, 1);

    profiles[defaultProfileIndex] &&
      sortedProfiles.unshift(profiles[defaultProfileIndex]);
  }

  return sortedProfiles;
};

export const isPassTokenExpired = (passTokenCreationDate: number): boolean => {
  // passToken must be renewed if created more than 4 hours ago (=14400000ms)
  const endValidityDate = new Date().getTime() - PASS_TOKEN_VALIDITY;
  return passTokenCreationDate <= endValidityDate;
};

export const generatePassToken = async (state: IState): Promise<string> => {
  const { passToken } = await getClientSideUserInfos(state);

  return passToken;
};

export const isDiscoverMode = (subscriberId?: number): boolean =>
  subscriberId !== undefined && subscriberId < 0;

export const shouldUsePassCache = (): boolean => {
  if (
    getQsValueByKey(window.location.href, 'passCache') === 'false' ||
    getQsValueByKey(window.location.href, Queries.RefreshRight) === 'true'
  ) {
    return false;
  }
  return true;
};

export interface IUserData {
  authenticated: boolean;
  macroEligibility: string;
  microEligibility: string;
  offerZone: string;
  epgidOTT: string;
  epgid: string;
  reco: boolean;
  analyticsId: string;
  dmpId: string;
  collectUserData: boolean;
  analytics?: boolean;
  trackingPub?: boolean;
  anonymousTracking?: boolean;
}
/**
 * This formatter helps extract from the user data the only piece of information that we are allowed to store on the server side /
 * i.e the only data that are allowed to be cached by Akamai
 */
export const formatUserData = ({
  isAuthenticated,
  macroEligibility,
  microEligibility,
  offerZone,
  epgid_OTT,
  epgid,
  reco,
  id_analytics,
  dmpId,
  collectUserData,
  analytics,
  trackingPub,
  anonymousTracking,
}: PassUserDataEnriched): IUserData => ({
  authenticated: isAuthenticated,
  epgidOTT: epgid_OTT,
  epgid,
  macroEligibility,
  microEligibility,
  offerZone,
  reco,
  analyticsId: id_analytics,
  dmpId,
  collectUserData,
  analytics,
  trackingPub,
  anonymousTracking,
});

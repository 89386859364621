import classNames from 'classnames';
import type { JSX } from 'react';
import { PureComponent, createRef } from 'react';
import styles from './VideoBackground.module.css';

export interface IVideoBackgroundProps {
  showVideoDelay?: number; // ms
  videoUrl: string;
  isBottomCropped?: boolean;
  isVideoPlayed?: boolean;
}

export interface IVideoBackgroundState {
  showVideo: boolean;
}

export class VideoBackground extends PureComponent<
  IVideoBackgroundProps,
  IVideoBackgroundState
> {
  static defaultProps = {
    showVideoDelay: 0,
    isBottomCropped: false,
    isVideoPlayed: true,
  };

  public videoRef = createRef<HTMLVideoElement>();

  public showDelayId!: ReturnType<typeof setTimeout>;

  public checkVideoLoadedId!: ReturnType<typeof setInterval>;

  public state = { showVideo: false };

  public componentDidMount(): void {
    const videoElement = this.videoRef.current as HTMLVideoElement;
    videoElement.setAttribute('muted', 'true'); // fixes React's inability to add this attr properly
    videoElement.setAttribute('playsinline', 'true'); // fixes autoplay in mobile safari
    this.showDelayId = setTimeout(
      () => this.checkVideoLoaded(),
      this.props.showVideoDelay
    );
  }

  public async componentDidUpdate(
    prevProps: IVideoBackgroundProps
  ): Promise<void> {
    if (prevProps.isVideoPlayed !== this.props.isVideoPlayed) {
      const videoElement = this.videoRef.current as HTMLVideoElement;
      if (!this.props.isVideoPlayed) {
        videoElement.pause();
      } else {
        await videoElement.play();
      }
    }
  }

  public componentWillUnmount(): void {
    // Timers need to be cleared in case component unmounts before video is loaded/displayed
    clearTimeout(this.showDelayId);
    clearInterval(this.checkVideoLoadedId);
  }

  public checkVideoLoaded = (): void => {
    // Check if the video element has loaded every 250ms
    this.checkVideoLoadedId = setInterval(() => {
      if (this.videoRef.current && this.videoRef.current.readyState === 4) {
        this.setState({ showVideo: true });
        clearInterval(this.checkVideoLoadedId);
      }
    }, 250);
  };

  public render(): JSX.Element {
    return (
      <div
        className={classNames(styles.VideoBackground, {
          [styles['VideoBackground--show']]: this.state.showVideo,
        })}
        data-testid="videobackground-container"
      >
        <video
          ref={this.videoRef}
          className={classNames(styles.VideoBackground__video, {
            [styles.VideoBackground__video__bottomCropped]:
              this.props.isBottomCropped,
          })}
          autoPlay
          muted
          loop
          data-testid="videobackground-video"
        >
          <source src={this.props.videoUrl} type="video/mp4" />
        </video>
      </div>
    );
  }
}

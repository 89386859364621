import type { ViewportToImageDimensions } from '@canalplus/dive';
import type { ResizeMode } from '@canalplus/mycanal-commons';
import classNames from 'classnames';
import type { ImgHTMLAttributes, JSX } from 'react';
import IconEdit from '../../assets/svg/edit.svg';
import IconKids from '../../assets/svg/kids.svg';
import { ImageType } from './../ImageType/ImageType';
import styles from './ProfileAvatar.module.css';

export type ProfileAvatarProps = {
  altImage: string;
  avatar: string;
  customClass?: string;
  dimensions: ViewportToImageDimensions;
  isEditable?: boolean;
  isKidsProfile?: boolean;
  isTvDevice?: boolean;
  resizeMode?: ResizeMode;
  loading?: ImgHTMLAttributes<HTMLImageElement>['loading'];
};

export function ProfileAvatar({
  altImage = '',
  avatar,
  customClass = undefined,
  dimensions,
  isEditable = false,
  isKidsProfile = false,
  isTvDevice,
  resizeMode,
  loading,
}: ProfileAvatarProps): JSX.Element {
  return (
    <div className={classNames(styles.ProfileAvatar)}>
      {isEditable && (
        <div
          className={classNames(styles.ProfileAvatar__iconEdit, customClass)}
        >
          <IconEdit />
        </div>
      )}
      {isKidsProfile && (
        <div
          className={classNames(styles.ProfileAvatar__iconKids, customClass)}
        >
          <IconKids />
        </div>
      )}
      <ImageType
        URLImage={{ default: decodeURI(avatar) }}
        dimensions={dimensions}
        altImage={altImage}
        isTvDevice={isTvDevice}
        resizeMode={resizeMode}
        loading={loading}
      />
    </div>
  );
}

import type {
  ApiV2Context,
  ApiV2OnClick,
} from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  AUTHENTICATION,
  DOWNLOAD_MANAGER,
  EXTERNAL_SITE,
  LAUNCH_ONE_SHOP,
  SELECT_REGION,
} from '../../constants/links';

export type LinkerProps = {
  ariaLabel?: string;
  ariaLabelledBy?: string;
  children?: React.ReactNode;
  className?: string;
  data?: {
    mainOnClick?: ApiV2OnClick & {
      trackingContext?: ApiV2Context;
    };
    context?: string;
  };
  id?: string;
  objKey?: string;
  onClick?: (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => void;
  onConnectClick?: () => void;
  onOneShopClick?: (contentID?: string) => void;
  onSelectRegionClick?: (contentID?: string) => void;
  replace?: boolean;
  scrollableElementId?: string;
  scrollLogicalPosition?: string;
  target?: string;
  title?: string;
  isTvDevice?: boolean;
};

export const Linker = ({
  ariaLabel,
  ariaLabelledBy,
  children,
  className,
  data = {},
  id,
  objKey,
  onClick,
  onConnectClick,
  onOneShopClick,
  onSelectRegionClick,
  replace,
  scrollableElementId,
  scrollLogicalPosition,
  target,
  title,
  isTvDevice = false,
  ...rest
}: LinkerProps) => {
  const location = useLocation<any>();

  const { mainOnClick: dataOnClick } = data || {};
  const { path, displayTemplate } = dataOnClick || {};
  const newTarget = isTvDevice ? '_self' : dataOnClick?.target || target;

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
      if (onClick) {
        onClick(e);
      }
    },
    [onClick]
  );

  if (
    displayTemplate === EXTERNAL_SITE ||
    displayTemplate === DOWNLOAD_MANAGER
  ) {
    return (
      <a
        onClick={handleClick}
        href={dataOnClick?.URLWebsite || dataOnClick?.URLPage}
        className={className}
        target={newTarget}
        id={id}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
      >
        {children}
      </a>
    );
  }

  if (displayTemplate === AUTHENTICATION) {
    return (
      <a
        id={id}
        className={className}
        onClick={onConnectClick}
        role="button"
        target={newTarget}
      >
        {children}
      </a>
    );
  }

  if (displayTemplate === LAUNCH_ONE_SHOP) {
    return (
      <a
        id={id}
        className={className}
        onClick={() => {
          onOneShopClick?.(dataOnClick?.contentID);
        }}
        role="button"
        target={newTarget}
      >
        {children}
      </a>
    );
  }

  if (displayTemplate === SELECT_REGION) {
    return (
      <button
        id={id}
        className={className}
        onClick={() => {
          onSelectRegionClick?.(dataOnClick?.contentID);
        }}
        type="button"
      >
        {children}
      </button>
    );
  }

  const state = {
    ...(data?.context === 'immersive' && {
      immersive: {
        mainOnClick: dataOnClick,
        stackSize: (location?.state?.immersive?.stackSize || 0) + 1,
      },
    }),
    ...(data?.context !== 'immersive' && {
      page: { mainOnClick: dataOnClick },
    }),
  };

  return (
    <Link
      data-testid="link-testid"
      className={className}
      onClick={handleClick}
      to={{
        pathname: path,
        state: Object.keys(state).length ? state : null,
      }}
      title={title}
      replace={replace}
      id={id}
      {...rest}
    >
      {children}
    </Link>
  );
};

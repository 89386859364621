import type { ResizeMode } from '@canalplus/mycanal-commons';
import {
  OfferLocation,
  OfferZone,
  Platform,
  PlatformGroup,
  PlatformGroupMap,
  type Lang,
} from '@canalplus/sdk-core';
import type { HodorSdkConfig } from '@canalplus/sdk-hodor';
import type {
  ApiV2Authenticate,
  ApiV2InitResponse,
} from '@dce-front/hodor-types';
import type { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import type { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import type { ApiV2PageTracking } from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import type { BranSdkSearchPageParameters } from '@dce-front/hodor-types/sdks/bran/page/search/definition';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { RenderSource } from '../../constants/renderSource';
import type {
  ApplicationState,
  UserAgent,
  WhoIsWatchingForceDisplay,
  ZoneInfo,
} from './application-types';

export const initialState: ApplicationState = {
  requestId: '',
  isFetching: false,
  renderSource: RenderSource.SERVER,
  onePlayerIsSupported: false,
  zoneInfo: {
    offerLocation: OfferLocation.fr,
    lang: 'fr',
    offerZone: OfferZone.cpfra,
  },
  platform: Platform.Web,
  platformGroup: PlatformGroup.Web,
  settings: {
    hostname: '',
    showAdultDisclaimer: true,
    searchUrl: 'http://testUrl.com/{cmsToken}/{searchKey}',
    userAgent: {
      isMobile: {
        isAny: true,
        isAndroid: false,
        isIOS: false,
        isWindows: false,
      },
      isBrowser: {
        isSafari: false,
      },
      isDesktop: {
        isAny: false,
        isMacOs: false,
        isWindows: false,
      },
    },
  },
  isClearCacheCalled: false,
  token: '',
  navigation: [],
  footer: {
    tree: [],
  },
  headerLevelPageList: [],
  firstLevelPageList: [],
  whoIsWatching: { disabled: false, inSession: false, forceDisplay: 'auto' },
  shortVideoIds: [],
};

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    /** Sets App's rendering source (client/server) */
    renderSource(state, action: PayloadAction<RenderSource>) {
      return {
        ...state,
        renderSource: action.payload,
      };
    },

    /** Download To Go has successfully loaded */
    loadD2GSuccess(state) {
      return {
        ...state,
        settings: {
          ...state.settings,
          isD2GScriptLoaded: true,
        },
      };
    },

    /** Requests call to Authentication services */
    serviceAuthenticateRequest(state) {
      return {
        ...state,
        isFetching: true,
      };
    },

    /** Sets App's fetching status & Authenticate response */
    serviceAuthenticateSuccess(
      state,
      action: PayloadAction<ApiV2Authenticate>
    ) {
      const {
        arborescence: navigation = [],
        footer,
        logo = {},
        settings,
        socialMedia,
        startupNotifications = [],
        token,
        userMenu,
      } = action.payload;

      return {
        ...state,
        footer: { ...state.footer, tree: footer },
        isFetching: false,
        logo,
        navigation,
        settings: { ...state.settings, ...settings },
        socialMedia,
        startupNotifications,
        token,
        userMenu: userMenu?.map((menu) => ({
          ...menu,
          ...(menu.picto && { picto: menu.picto.toLowerCase() }),
        })),
      };
    },

    /** Sets App's User Agent object */
    setUserAgent(state, action: PayloadAction<UserAgent>) {
      return {
        ...state,
        settings: {
          ...state.settings,
          userAgent: action.payload,
        },
      };
    },

    /** Action dispatched when you open the search */
    setSearchPageParameters(
      state,
      action: PayloadAction<BranSdkSearchPageParameters>
    ) {
      return {
        ...state,
        searchPageParameters: action.payload,
      };
    },

    /** Action dispatched when deeplink search is trigger **/
    setSearchDeeplinkPageParameters(
      state,
      action: PayloadAction<BranSdkSearchPageParameters>
    ) {
      return {
        ...state,
        searchDeeplinkPageParameters: action.payload,
      };
    },

    /** Sets App's hostname */
    setHostname(state, action: PayloadAction<string>) {
      return {
        ...state,
        settings: {
          ...state.settings,
          hostname: action.payload,
        },
      };
    },

    /** Replace settings object */
    overrideSettings(
      state,
      action: PayloadAction<Partial<ApplicationState['settings']>>
    ) {
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      };
    },

    /** Sets ZoneInfo values */
    setZoneInfo(state, action: PayloadAction<ZoneInfo>) {
      return {
        ...state,
        zoneInfo: action.payload,
      };
    },

    setLocaleFromHodor(state, action: PayloadAction<string>) {
      const locale =
        action.payload.length <= 2
          ? action.payload
          : action.payload.split('-')[0];

      return {
        ...state,
        zoneInfo: {
          ...state.zoneInfo,
          lang: locale as Lang,
        },
      };
    },

    /** Hides Header component */
    hideHeader(state) {
      return {
        ...state,
        header: {
          isHidden: true,
        },
      };
    },

    /** Hides Footer component */
    hideFooter(state) {
      return {
        ...state,
        footer: {
          isHidden: true,
        },
      };
    },

    /** Shows GDPR component */
    showGDPR(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        GDPR: {
          shouldShowGDPR: action.payload,
        },
      };
    },

    /** Set the container mode according the query parameter in the url */
    setInContainerMode(state, action: PayloadAction<string>) {
      return {
        ...state,
        header: {
          ...state.header,
          isInContainer: action.payload,
        },
      };
    },

    setContextInstantiation(state, action: PayloadAction<Platform>) {
      return {
        ...state,
        platform: action.payload,
        platformGroup: PlatformGroupMap[action.payload],
      };
    },

    setHeaderLevelPageList(state, action: PayloadAction<string[]>) {
      return {
        ...state,
        headerLevelPageList: action.payload,
        firstLevelPageList: action.payload,
      };
    },

    setFirstLevelPageList(state, action: PayloadAction<string[]>) {
      return {
        ...state,
        firstLevelPageList: [
          ...state.firstLevelPageList,
          ...action.payload.filter(
            (pathname) => !state.firstLevelPageList.includes(pathname)
          ),
        ],
      };
    },

    setApplicationResize(state, action: PayloadAction<ResizeMode>) {
      return {
        ...state,
        resize: action.payload,
      };
    },

    setApplicationHodorSdkConfig(state, action: PayloadAction<HodorSdkConfig>) {
      return {
        ...state,
        hodorSdkConfig: action.payload,
      };
    },

    setApplicationConfigurationJson(state, action) {
      return {
        ...state,
        settings: { ...state.settings, ...action.payload.globalSettings },
      };
    },

    setWhoIsWatchingUserSession(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        whoIsWatching: {
          ...state.whoIsWatching,
          inSession: action.payload,
        },
      };
    },

    setWhoIsWatchingDisabled(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        whoIsWatching: {
          ...state.whoIsWatching,
          disabled: action.payload,
        },
      };
    },

    resetWhoIsWatchingSettings(state) {
      return {
        ...state,
        whoIsWatching: {
          inSession: false,
          disabled: false,
          forceDisplay: 'auto',
        },
      };
    },

    setForceWhoIsWatching(
      state,
      action: PayloadAction<WhoIsWatchingForceDisplay>
    ) {
      return {
        ...state,
        whoIsWatching: {
          ...state.whoIsWatching,
          forceDisplay: action.payload,
        },
      };
    },

    setApplicationShortVideoIds(state, action: PayloadAction<string[]>) {
      return {
        ...state,
        shortVideoIds: action.payload,
      };
    },

    setApplicationInitTracking(
      state,
      action: PayloadAction<ApiV2InitResponse['tracking']>
    ) {
      return {
        ...state,
        initTracking: action.payload,
      };
    },

    updateSlideshowTrackingContext(
      state,
      action: PayloadAction<ApiV2Context | undefined>
    ) {
      return {
        ...state,
        startupNotificationsTrackingContext: action.payload,
      };
    },

    updateSlideshowTracking(
      state,
      action: PayloadAction<Tracking | ApiV2PageTracking | undefined>
    ) {
      return {
        ...state,
        startupNotificationsTracking: action.payload,
      };
    },
    setSlideShowNotificationOpen(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isStartupNotificationOpen: action.payload,
      };
    },
  },
});

export const {
  hideFooter,
  hideHeader,
  loadD2GSuccess,
  overrideSettings,
  renderSource,
  resetWhoIsWatchingSettings,
  serviceAuthenticateRequest,
  serviceAuthenticateSuccess,
  setApplicationConfigurationJson,
  setApplicationHodorSdkConfig,
  setApplicationInitTracking,
  setApplicationResize,
  setApplicationShortVideoIds,
  setContextInstantiation,
  setFirstLevelPageList,
  setHeaderLevelPageList,
  setHostname,
  setInContainerMode,
  setLocaleFromHodor,
  setSearchPageParameters,
  setSearchDeeplinkPageParameters,
  setUserAgent,
  setWhoIsWatchingDisabled,
  setWhoIsWatchingUserSession,
  setForceWhoIsWatching,
  setZoneInfo,
  showGDPR,
  updateSlideshowTracking,
  updateSlideshowTrackingContext,
  setSlideShowNotificationOpen,
} = applicationSlice.actions;

export const applicationReducer = applicationSlice.reducer;

export type ApplicationActions = ReturnType<
  (typeof applicationSlice.actions)[keyof typeof applicationSlice.actions]
>;

import {
  QueryClient,
  type QueryClientConfig,
  type QueryObserverOptions,
} from '@tanstack/react-query';
import {
  DEFAULT_CACHE_TIME,
  DEFAULT_STALE_TIME,
} from '../../constants/reactQuery';

export type ReactQueryConfig = {
  cacheTime: QueryObserverOptions['gcTime'];
} & Pick<QueryObserverOptions, 'staleTime'>;

export const defaultReactQueryConfig: ReactQueryConfig = {
  cacheTime: DEFAULT_CACHE_TIME,
  staleTime: DEFAULT_STALE_TIME,
};

export const createQueryClient = (
  reactQueryConfig?: ReactQueryConfig,
  options: Pick<QueryClientConfig, 'queryCache' | 'mutationCache'> = {}
): QueryClient =>
  new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        ...(reactQueryConfig || defaultReactQueryConfig),
      },
    },
    ...options,
  });

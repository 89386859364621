import {
  ButtonLogo,
  Media,
  MediaFooter,
  MediaOverlay,
  Sticker,
  StickerVariant,
} from '@canalplus/dive';
import type { ImageSize } from '@canalplus/mycanal-commons';
import { DIMENSIONS, Ratio } from '@canalplus/mycanal-commons';
import { CreativeMediaCard } from '@canalplus/mycanal-creativemedia';
import type { PersoLists } from '@canalplus/sdk-hodor';
import { Template, TitleDisplayMode } from '@canalplus/sdk-hodor';
import classNames from 'classnames/bind';
import type { JSX } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { REFRESH_PROGRESS_BAR_MS } from '../../../../constants/limits';
import { isOngoing } from '../../../../helpers/card/card-helper';
import { useInvariantSelector } from '../../../../helpers/hooks/useInvariantSelector';
import { useIsTvDevice } from '../../../../helpers/hooks/useIsTvDevice';
import { getHodorSticker } from '../../../../helpers/stickers/stickers-helper';
import I18n from '../../../../lang';
import { featUpcomingHodorStickerSelector } from '../../../../store/slices/application-selectors';
import { imageQualityPercentageBySettingsSelector } from '../../../../store/slices/user-selectors';
import type { ContentStrateV5 } from '../../../../templates/LandingV5/data/formatter';
import { CardRemoveButton } from '../../../CardContents/CardRemoveButton/CardRemoveButton';
import Linker from '../../../Linker/Linker';
import type { LinkerData } from '../../../Linker/types';
import { useRoutingContext } from '../../../Page/RoutingContext';
import { useIsFrom } from '../../../Page/useIsFrom';
import DiveProgressBar from '../../../ProgressBar/DiveProgressBar';
import { useGetDiveStickerProps } from '../../../Sticker/Sticker';
import ThumborMediaImage from '../../../ThumborMediaImage/ThumborMediaImage';
import styles from '../ContentRowTemplateItem.css';
import { RankItemStandard } from './RankItemStandard';

const cx = classNames.bind(styles);

export type ContentRowTemplateItemStandardProps = {
  content: ContentStrateV5;
  imageSize?: ImageSize;
  isBackgroundTransparent?: boolean;
  isRemovableItem?: boolean;
  listType?: PersoLists;
  onClickRemove?: (
    event: React.MouseEvent,
    contentID: string,
    listType: PersoLists
  ) => void;
  ratio: Ratio;
  titleDisplayMode?: TitleDisplayMode;
  isTop10?: boolean;
};

export function ContentRowTemplateItemStandard({
  content,
  titleDisplayMode = TitleDisplayMode.All,
  ratio,
  imageSize = 'normal',
  isBackgroundTransparent = false,
  isRemovableItem = false,
  isTop10 = false,
  listType,
  onClickRemove,
}: ContentRowTemplateItemStandardProps): JSX.Element | null {
  const { t } = I18n.useTranslation();

  const isFeatUpcomingHodorSticker = useInvariantSelector(
    featUpcomingHodorStickerSelector
  );
  const isFromLanding = useIsFrom(Template.Landing);
  const isFromDetail = useIsFrom(Template.DetailPage);
  const routingContext = useRoutingContext();
  const isFromImmersive = routingContext === 'immersive' && !isFromLanding;
  const isTvDevice = useIsTvDevice();
  const qualityUserSettings = useSelector(
    imageQualityPercentageBySettingsSelector
  );

  const linkerData = useMemo(
    () =>
      ({
        contentID: content?.contentID,
        mainOnClick: content?.onClick,
        context: content?.context,
      }) satisfies LinkerData,
    [content]
  );

  const { startTime, endTime } = content;

  const [isLive, setIsLive] = useState(isOngoing({ startTime, endTime }));

  const hodorSticker = getHodorSticker(
    content.stickers,
    isFeatUpcomingHodorSticker
  );

  const stickerProps = useGetDiveStickerProps({
    sticker: hodorSticker,
    startTime: startTime,
    endTime: endTime,
    variant:
      !content.userProgress && isLive && content.isInOffer
        ? StickerVariant.Live
        : undefined,
  });

  const updateLiveStatus = useCallback(() => {
    const isOnGoing = isOngoing({ startTime, endTime });
    setIsLive(isOnGoing);
  }, [endTime, startTime]);

  useEffect(() => {
    const updateLiveStatusInterval = setInterval(
      updateLiveStatus,
      REFRESH_PROGRESS_BAR_MS
    );
    return () => clearInterval(updateLiveStatusInterval);
  }, [updateLiveStatus]);

  const isDescriptionVisible =
    titleDisplayMode === TitleDisplayMode.Description ||
    titleDisplayMode === TitleDisplayMode.All;

  const URLImage = content.URLImage;

  const { URLImageForDarkMode = URLImage, imageSpecificities } = content;

  const isTitleVisible = titleDisplayMode !== TitleDisplayMode.None;
  const title = isTitleVisible ? content.title : '';
  const subtitle =
    isTitleVisible && titleDisplayMode !== TitleDisplayMode.TitleOnly
      ? content.subtitle
      : '';
  const description = isDescriptionVisible ? content.description : '';
  const image = {
    default: URLImageForDarkMode,
  };

  const isLogoChannel = imageSpecificities === 'isLogo';

  const isCreativeMedia = content.type === Template.CreativeMedia;
  const isBottomTitle = !(
    [TitleDisplayMode.LogoTyped, TitleDisplayMode.None] as TitleDisplayMode[]
  ).includes(titleDisplayMode);
  const isClickableCard = !!content.onClick?.URLPage;

  const ariaLabel = isTop10
    ? `Top ${content.rank} ${content.title}`
    : content.title;

  const dimensions = (DIMENSIONS as any)[ratio][imageSize];

  const progressBar = content.userProgress ? (
    <DiveProgressBar progress={content.userProgress} showIconOnComplete />
  ) : isLive ? (
    <DiveProgressBar startTime={startTime} endTime={endTime} isLive />
  ) : undefined;

  const shouldDisplayTitle =
    TitleDisplayMode.LogoTyped && !content.isLogoTyped && !isBottomTitle;

  const mediaCard = (
    <Media
      aspectRatio={ratio}
      aria-label={ariaLabel}
      image={
        <ThumborMediaImage
          dimensions={dimensions}
          url={URLImage}
          alt={content.altImage}
        />
      }
      variant={isBackgroundTransparent ? 'ghost' : 'solid'}
      overlay={
        <MediaOverlay
          actionableElementTop={
            isRemovableItem && onClickRemove && !isTvDevice ? (
              // This component will be remove when it will be refactored dive side
              <CardRemoveButton
                contentID={content.contentID as string}
                listType={listType}
                onClickRemove={onClickRemove}
                hasSticker={Boolean(stickerProps?.variant || hodorSticker)}
              />
            ) : undefined
          }
          logo={
            content.URLLogoChannel ? (
              <ThumborMediaImage
                dimensions={
                  isTvDevice
                    ? DIMENSIONS.LOGO_CHANNEL.large
                    : DIMENSIONS.LOGO_CHANNEL.normal
                }
                url={content.URLLogoChannel}
                alt={content.altLogoChannel}
              />
            ) : undefined
          }
          title={shouldDisplayTitle ? title : undefined}
          subtitle={!shouldDisplayTitle ? subtitle : undefined}
          progressBar={progressBar}
          sticker={stickerProps ? <Sticker {...stickerProps} /> : undefined}
        />
      }
      footer={
        isBottomTitle ? (
          <MediaFooter
            title={title}
            subtitle={subtitle}
            description={description}
          />
        ) : undefined
      }
      data-tv-focusable={!isClickableCard}
      className={cx('contentRowTemplateItem__itemLink', {
        'contentRowTemplateItem__itemLink--top10__media': isTop10,
      })}
    />
  );

  // Clickable card
  if (isClickableCard) {
    const itemClassnames = cx('contentRowTemplateItem', {
      'contentRowTemplateItem--carousel':
        ratio === Ratio.Ratio166 && !isDescriptionVisible,
      'contentRowTemplateItem--immersive': isFromImmersive,
      'contentRowTemplateItem--logoTyped':
        titleDisplayMode === TitleDisplayMode.LogoTyped,
      'contentRowTemplateItem--logoTyped--top10':
        titleDisplayMode === TitleDisplayMode.LogoTyped && isTop10,
      'contentRowTemplateItem--detailV5': isFromDetail,
      'contentRowTemplateItem--top10': isTop10,
      'contentRowTemplateItem--creativeMedia': isCreativeMedia,
      'contentRowTemplateItem--creativeMediaInDetailV5':
        isCreativeMedia && isFromDetail,
      'contentRowTemplateItem--isBottomTitle': isBottomTitle,
    });

    return (
      <li className={itemClassnames}>
        <Linker
          className={cx('contentRowTemplateItem__itemLink', {
            'contentRowTemplateItem__itemLink--top10': isTop10,
          })}
          data={linkerData}
          title={ariaLabel}
          ariaLabel={ariaLabel}
        >
          {isTop10 && !!content.rank && (
            <RankItemStandard rank={content.rank} />
          )}
          {isLogoChannel ? (
            <ButtonLogo
              as="div"
              logo={
                <ThumborMediaImage
                  url={image.default}
                  dimensions={
                    isLogoChannel
                      ? DIMENSIONS.LOGO_CHANNEL.extraLarge
                      : dimensions
                  }
                  alt={content.altImage}
                  height="auto"
                />
              }
            />
          ) : (
            <>
              {isCreativeMedia && image.default ? (
                <CreativeMediaCard
                  title={title}
                  image={image.default}
                  qualityUserSettings={qualityUserSettings}
                  t={t}
                  isImmersive={false}
                  description={description}
                />
              ) : (
                mediaCard
              )}
            </>
          )}
        </Linker>
      </li>
    );
  }

  // Not clickable card
  return (
    <li className={styles.contentRowTemplateItem}>
      {isLogoChannel ? (
        <ButtonLogo
          as="button"
          logo={
            <ThumborMediaImage
              url={image.default}
              dimensions={
                isLogoChannel ? DIMENSIONS.LOGO_CHANNEL.extraLarge : dimensions
              }
              alt={content.altImage}
              height="auto"
            />
          }
        />
      ) : (
        mediaCard
      )}
    </li>
  );
}

import type { LogApiMetadata, Logger } from '@canalplus/mycanal-logger';
import { Service } from '@canalplus/mycanal-logger';
import type { AcmApi } from '../constants/paths';
import { getErrorCode } from './getErrorCode';
import { getErrorMessage } from './getErrorMessage';
import { getErrorStack } from './getErrorStack';
import { getErrorStatusCode } from './getErrorStatusCode';

type LogErrorParameters = {
  error: unknown;
  logger: Logger;
  baseErrorLogs: Partial<LogApiMetadata>;
  api: AcmApi;
};

export const logError = ({
  error,
  logger,
  baseErrorLogs,
  api,
}: LogErrorParameters): void => {
  const statusCode = getErrorStatusCode(error);
  const errorMessage = getErrorMessage(error);
  const errorStack = getErrorStack(error);
  const errorCode = getErrorCode(error);

  logger.error(
    `ACM ${api} ${statusCode} Description: ${errorMessage}`,
    logger.generateApiMetadata(Service.Acm, api, {
      ...baseErrorLogs,
      errorMessage,
      statusCode,
      ...(errorStack && { errorStack }),
      ...(errorCode && { errorCode }),
    })
  );
};

import type { ImageSize, Ratio } from '@canalplus/mycanal-commons';
import classNames from 'classnames';
import type { JSX } from 'react';
import { Fragment } from 'react';
import { PlaceHolderItem } from './PlaceHolderItem';
import styles from './Placeholder.module.css';

export type PlaceholderProps = {
  nbPlaceholder?: number;
  ratio: Ratio;
  imageSize: ImageSize;
  isFromDetail?: boolean;
};

export function Placeholder({
  nbPlaceholder = 10,
  ratio,
  imageSize,
  isFromDetail = false,
}: PlaceholderProps): JSX.Element {
  return (
    <div className={styles.placeholder}>
      <ul
        data-ratio={`${ratio}${imageSize}`}
        className={classNames(styles.placeholder__container)}
      >
        {Array.from({ length: nbPlaceholder }, (_, index) => (
          <Fragment key={index}>
            <PlaceHolderItem ratio={ratio} isFromDetail={isFromDetail} />
          </Fragment>
        ))}
      </ul>
    </div>
  );
}

import type { JSX } from 'react';
import { Children, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntersectionObserver } from '../../helpers/hooks/useIntersectionObserver';

export type LazyLoaderProps = {
  children: React.ReactNode;
  initialDisplayCount?: number;
  loadMoreCount?: number;
  enableLazyLoad?: boolean;
};

/**
 * LazyLoader
 *
 * Renders a limited number of children components, and adds more as the user scrolls vertically.
 *
 * @param initialDisplayCount The number of children to be initially displayed
 * @param loadMoreCount The number of children to add when load-more is triggered
 * @returns {nodes} children
 */
function LazyLoader({
  children,
  initialDisplayCount = 10,
  loadMoreCount = 10,
  enableLazyLoad = true,
}: LazyLoaderProps): JSX.Element {
  const [maxDisplayCount, setMaxDisplayCount] = useState(initialDisplayCount);

  const renderNextChildren = useCallback(() => {
    // Don't move this condition inside the call of useIntersectionObserver (isEnabled) because it has some side effects
    // for contentgrid with infinitequery (number of Children will change and make isEnabled false so we want to keep it true)
    if (maxDisplayCount < Children.count(children)) {
      setMaxDisplayCount(
        (prevMaxDisplayCount) => prevMaxDisplayCount + loadMoreCount
      );
    }
  }, [loadMoreCount, maxDisplayCount, children]);

  const { refCallback, entry } = useIntersectionObserver({
    isEnabled: enableLazyLoad,
    threshold: 0,
  });

  useEffect(() => {
    if (entry?.isIntersecting) {
      renderNextChildren();
    }
  }, [entry?.isIntersecting, renderNextChildren]);

  const displayChildren = useMemo(() => {
    return Children.toArray(children).slice(0, maxDisplayCount); // Limit displayChildren to the current maxDisplayCount}
  }, [children, maxDisplayCount]);

  return (
    <>
      {displayChildren}
      <div
        style={{
          position: 'relative',
          height: '1px',
          width: '1px',
          top: '-100px',
        }}
        ref={refCallback}
      />
    </>
  );
}

export default LazyLoader;

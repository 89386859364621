import type { JSX } from 'react';
import IconClose from '../../assets/svg/close.svg';
import { ModalTypes } from '../../constants/modalTypes';
import type { closeModal } from '../../store/slices/modal';
import type { UserSettingsState } from '../../store/slices/user-type';
import type { AppHistory } from '../../typings/routing';
import LoadableExternalServiceModal from './ExternalServiceModal/LoadableExternalServiceModal';
import LoadableModal from './LoadableModal';
import LoadableMobileAppDownloadModal from './MobileAppDownloadModal/LoadableMobileAppDownloadModal';
import LoadableUserExitsSettingsModal from './UserSettingsModal/LoadableUserExitsSettingsModal';
import LoadableUserSettingsModal from './UserSettingsModal/LoadableUserSettingsModal';
import LoadableZonesListModal from './ZonesListModal/LoadableZonesListModal';
import { useCloseConfirm } from './useCloseConfirm';

export type ModalContainerProps = {
  children?: React.ReactNode;
  afterClosing?: () => void;
  closeAction: typeof closeModal;
  isMobile: boolean;
  isOpen: boolean;
  modalType: ModalTypes;
  modalProps?: any;
  history: AppHistory;
  clickedElement?: HTMLElement | null;
};

/**
 * This container should be present in a page only one time.
 * It permits to display a modal with content
 * after the dispatch of an Redux action `OPEN_MODAL`.
 *
 * @param isOpen             toggles modal's visibility
 * @param closeAction        function triggered when clicking the close button
 * @param afterClosing       function triggered after clicking the close button
 * @param modalType          type of pfv, live, hapi, epg
 * @param modalProps         options passed to modal
 * @param children           component(s)/dom element to render inside modal
 */
function ModalContainer({
  afterClosing,
  closeAction,
  isOpen,
  modalType,
  modalProps,
  children,
  history,
  clickedElement,
}: ModalContainerProps): JSX.Element | null {
  const closeConfirm = useCloseConfirm();

  if (!isOpen) {
    return null;
  }

  const onClosing = async (
    modalTypeToClose: ModalTypes = modalType,
    settings?: UserSettingsState
  ) => {
    const isSettings = !!settings?.nextActionBeforeSaving;

    if (isSettings) {
      await closeConfirm(settings, history);
    } else {
      closeAction(modalTypeToClose);
    }

    // Get back focused element for accessibility
    if (clickedElement) {
      clickedElement.focus();
    }

    if (afterClosing) {
      afterClosing();
    }
  };

  const closeButtonIcon = <IconClose />;

  switch (modalType) {
    case ModalTypes.MOBILE_APP_DOWNLOAD:
      return (
        <LoadableMobileAppDownloadModal
          closeButtonIcon={closeButtonIcon}
          isOpen={isOpen}
          closeAction={() => onClosing(modalType)}
          {...modalProps}
        />
      );

    case ModalTypes.USER_SETTINGS_MODAL:
      return (
        <LoadableUserSettingsModal
          isOpen={isOpen}
          closeAction={() => onClosing(modalType)}
          {...modalProps}
        />
      );

    case ModalTypes.USER_EXITS_SETTINGS_MODAL:
      return (
        <LoadableUserExitsSettingsModal
          isOpen={isOpen}
          closeAction={onClosing}
          {...modalProps}
        />
      );

    case ModalTypes.ZONES_LIST_MODAL:
      return (
        <LoadableZonesListModal
          closeButtonIcon={closeButtonIcon}
          isOpen={isOpen}
          closeAction={() => onClosing(modalType)}
          {...modalProps}
        />
      );

    case ModalTypes.GENERIC_MODAL:
      return (
        <LoadableModal
          closeAction={() => onClosing(modalType)}
          closeButtonIcon={closeButtonIcon}
          isOpen={isOpen}
          {...modalProps}
        >
          {children}
        </LoadableModal>
      );

    case ModalTypes.EXTERNAL_SERVICE_STREAM_MODAL:
      return (
        <LoadableExternalServiceModal
          isOpen={isOpen}
          closeAction={() => onClosing(modalType)}
          {...modalProps}
        />
      );

    case ModalTypes.EXTERNAL_SERVICE_DOWNLOAD_MODAL:
      return (
        <LoadableExternalServiceModal
          isOpen={isOpen}
          closeAction={() => onClosing(modalType)}
          isDownload
          {...modalProps}
        />
      );

    default:
      return null;
  }
}

export default ModalContainer;

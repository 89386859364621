import type { PassClientIds } from '../../../config/application/types';

/** Get clientId for **clientIds** and an **url** given */
export const getClientId = (clientIds: PassClientIds, url?: string): string => {
  const clientIdKeyFromUrl = Object.keys(clientIds).find((clientIdKey) =>
    url?.includes(clientIdKey)
  );

  return clientIdKeyFromUrl && clientIdKeyFromUrl in clientIds
    ? clientIds[clientIdKeyFromUrl]!
    : clientIds.default;
};

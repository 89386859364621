import { Platform } from './platform';

/**
 * All hapi OS.\
 * Note that this is a sdk enum mean to be independent from one-core, hodor, ect...
 */
export const HapiOs = {
  Vidaa: 'vidaa',
  TitanOs: 'titanOs',
  WebOs: 'webOs',
  TizenOs: 'tizenOs',
  Default: 'default',
} as const;

/**
 * All hapi OS.
 */
export type HapiOs = (typeof HapiOs)[keyof typeof HapiOs];

/**
 * Mapping to get Hapi OS from Platform.
 * @example HapiOsByPlatformMap[Platform.Hisense] // "Vidaa"
 */
export const HapiOsByPlatformMap: Record<Platform, HapiOs> = {
  [Platform.Hisense]: HapiOs.Vidaa,
  [Platform.Philips]: HapiOs.TitanOs,
  [Platform.LG]: HapiOs.WebOs,
  [Platform.Samsung]: HapiOs.TizenOs,
  [Platform.FireTV]: HapiOs.Default,
  [Platform.G11]: HapiOs.Default,
  [Platform.G10]: HapiOs.Default,
  [Platform.G10LCPI]: HapiOs.Default,
  [Platform.G9]: HapiOs.Default,
  [Platform.G9LCPI]: HapiOs.Default,
  [Platform.G9LCPISat]: HapiOs.Default,
  [Platform.G9Mini]: HapiOs.Default,
  [Platform.G9Sat]: HapiOs.Default,
  // Poland-specific GLine devices
  [Platform.G9LBis]: HapiOs.Default,
  [Platform.G9LNCP]: HapiOs.Default,
  [Platform.G9SE]: HapiOs.Default,
  [Platform.OrangeKey]: HapiOs.Default,
  [Platform.OrangeManhattan]: HapiOs.Default,
  [Platform.OrangeMib4]: HapiOs.Default,
  [Platform.OrangePlay]: HapiOs.Default,
  [Platform.OrangeStellar]: HapiOs.Default,
  [Platform.PlayStation4]: HapiOs.Default,
  [Platform.PlayStation5]: HapiOs.Default,
  [Platform.SFR7IPTV]: HapiOs.Default,
  [Platform.SFR8IPTV]: HapiOs.Default,
  [Platform.SFR8Cable]: HapiOs.Default,
  [Platform.Web]: HapiOs.Default,
};

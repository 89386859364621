import type { FetchOptions } from '@canalplus/mycanal-fetch';
import { HodorError, HodorInvalidPassTokenError } from '@canalplus/sdk-hodor';
import Logger from '../../helpers/logger/logger-helper';
import { getClientSideUserInfos } from '../../helpers/user/getClientSideUserInfos';
import { waitForPassTokenAsync } from '../../helpers/waitForPassToken/waitForPassToken-helper';
import type { ApiOptions } from '../../services/types';
import { getPassToken } from '../../store/slices/user';
import { profileIdSelector } from '../../store/slices/user-selectors';
import type { IState } from '../../store/types/State-type';

export type ApiPersoResponse = Response | void;

/**
 * apiCallWithTokenpassRenewal
 *
 * Perso API call
 *
 * if the call fails because of a 403 (invalid tokenPass) it will
 * renew it through the pass sdk and try the call one more time
 *
 * @param  {string}   [apiCall]   url web service response
 * @param  {object}   [options]   fetch options
 * @returns {object}   unadulterated json from API
 */
const apiCallWithTokenpass = async <T = ApiPersoResponse,>(
  apiCall: (options: ApiOptions) => Promise<T | undefined>,
  dispatch: Redux.Dispatch,
  state: IState,
  profileId?: string
): Promise<T | undefined> => {
  // Since this is a perso call we have to wait for the tokenPass
  const tokenPass = await waitForPassTokenAsync();

  if (!apiCall || !tokenPass) {
    Logger.error(
      `PersoApi::apiCallWithTokenpassRenewal should be called with a valid callback and tokenPass`,
      {
        apiCall,
        tokenPass,
      }
    );

    return undefined;
  }

  const baseHeaders = {
    tokenPass,
    ...(profileId !== undefined ? { 'xx-profile-id': profileId } : {}),
  };

  // Adding the base headers for perso calls in addition
  // to the one in the option object
  const options = {
    headers: baseHeaders,
  };

  const tokenRenewal = async () => {
    const userInfos = await getClientSideUserInfos(state);
    const newPassToken = userInfos.passToken;

    dispatch(getPassToken(newPassToken));

    options.headers = { ...options.headers, tokenPass: newPassToken };
    const newResponse = await apiCall(options);

    return newResponse;
  };

  try {
    const response: T | undefined = await apiCall(options);

    return response;
  } catch (error) {
    if (
      error instanceof HodorError &&
      !(error instanceof HodorInvalidPassTokenError)
    ) {
      throw error;
    }

    let errorLogged = error;
    // Since we have multiple ways of handling api calls, we cover the new way of handling them
    // (throwing an apiError containing the response the status)
    try {
      return await tokenRenewal();
    } catch (tokenRenewalError) {
      errorLogged = tokenRenewalError;
    }

    Logger.error(
      `PersoApi::apiCallWithTokenpassRenewal Error occurs during fetchPerso ${errorLogged}`
    );
    return undefined;
  }
};

export const apiCallWithTokenpassRenewal =
  <T = ApiPersoResponse,>(
    apiCall: (options: FetchOptions) => Promise<T | undefined>
  ) =>
  async (
    dispatch: Redux.Dispatch,
    getState: () => IState
  ): Promise<T | undefined> => {
    // Since this is a perso call we have to wait for the tokenPass
    const state = getState();
    const profileId = profileIdSelector(state)?.toString();

    // @TODO Need a profileId 0 by default because is required for hodor service
    // To see with hodor team if it possible to fix this and pass undefined instead of 0
    return apiCallWithTokenpass<T>(apiCall, dispatch, state, profileId || '0');
  };

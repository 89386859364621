import type { ApiV2InitResponse } from '@dce-front/hodor-types';
import type {
  HodorSdkConfig,
  HodorSdkConfigDrogonInitParameters,
} from '../config/types';

/** Merge the Drogon parameters from `ApiV2InitResponse` in the `HodorSdkConfig` */
export const getMergedConfigWithInit = (
  hodorSdkConfig: HodorSdkConfig,
  {
    URLAuthenticate,
    URLAvatars = '',
    userDetails: {
      crmSegments,
      experiments,
      featureToggles = [],
      like,
      segments,
    },
  }: ApiV2InitResponse
): HodorSdkConfig => {
  const drogonInitParameters: Required<HodorSdkConfigDrogonInitParameters> = {
    crmSegments,
    experiments,
    featureToggles,
    like,
    segments,
  };

  return {
    ...hodorSdkConfig,
    ...drogonInitParameters,
    urls: {
      authenticate: URLAuthenticate,
      avatars: decodeURI(URLAvatars),
    },
  };
};

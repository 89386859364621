import { useEffect } from 'react';
import type { Key } from '../constants/keys';
import { useStore } from './useStore';

export function useKeySequence(sequence: number[], callback: () => void): void {
  const store = useStore();

  useEffect(() => {
    let index = 0;

    function handleKey(key: Key) {
      if (sequence[index] === key) {
        index += 1;

        if (index >= sequence.length) {
          callback();
          index = 0;
        }
      } else {
        index = 0;
      }
    }

    store.addListener('key', handleKey);

    return () => {
      store.removeListener('key', handleKey);
    };
  }, [store, sequence, callback]);
}

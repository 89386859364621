import { DEFAULT_TIMEOUT_CALLBACK, ERROR_NO_WINDOW_DEFINED } from './constants';
import { getRPC } from './rpc';
import type { IIfc, RPCConfig, RPCRequest, RPCResponse } from './types';

/**
 * Get an new instance IFC base on the config params.
 * Return the IFC object with methods addIfcEventListener, removeIfcEventListener, rpc
 *
 * @param otherWindow A reference of the object window to communicate with
 * @param targetOrigin The targetOrigin of the window to communicate with (http + domain. Example : https://localhost).
 * @param timeout (optional default 10000 ms) Timeout in ms for all calls. After this time, the promise for a call rpc is rejected.
 * @param onMessage (optional) You can pass a callback to call when receive jsonrpc messages
 */
export const getIFC = (
  otherWindow: Window,
  targetOrigin: string,
  timeout: number = DEFAULT_TIMEOUT_CALLBACK,
  onMessage?: (data: RPCResponse) => void
): IIfc => {
  if (typeof window === 'undefined') {
    throw new Error(ERROR_NO_WINDOW_DEFINED);
  }

  // Config global of the ifc
  const config: RPCConfig = {
    otherWindow,
    targetOrigin,
    timeout,
    jsonRpcVersion: '2.0',
  };
  // uid sended to postMessage. It's incremented before every postMessage
  // Start from 10000 to avoid conflict with old R7 lib if we use the both libs together
  let uid = 10000;

  const getUID = () => {
    uid += 1;
    return uid;
  };

  const sendPostMessage = <TParams = unknown>(
    uidParam: number,
    method: RPCRequest['method'],
    params?: TParams
  ): void => {
    const dataToSend: RPCRequest<TParams> = {
      jsonrpc: config.jsonRpcVersion,
      id: uidParam,
      method,
      params,
    };

    // Send data with postMessage
    const targetWindow = config.otherWindow;
    targetWindow.postMessage(dataToSend, config.targetOrigin);
  };

  const { rpc, onMessage: onMessageRpc } = getRPC(
    config,
    sendPostMessage,
    getUID
  );

  // To receive an RPC postMessage and call the callback corresponding
  window.addEventListener('message', (event): void => {
    const { origin, data } = event;

    // check origin for security
    // the origin must be the same of targetOrigin in config
    if (targetOrigin !== '*' && origin !== targetOrigin) {
      return;
    }

    // call callback if is defined
    if (onMessage) {
      onMessage(data);
    }

    /**
     * Check format messages. We manage here only RPC response. We call callback if:
     * - is jsonrpc
     * - data has no property method (if has property 'method', it's a request)
     */
    if (!data.jsonrpc || data.method) {
      return;
    }

    // Manage classic rpc messages
    onMessageRpc(data);
  });

  return { rpc };
};

import {
  addQueriesParam,
  getStringMapFromParams,
} from '@canalplus/mycanal-commons';
import type {
  PerformWsFromPathParametersClientSpecific,
  PerformWsFromPathParametersServerSpecific,
  PerformWsFromPathRedirect,
} from '../types';

export type GetDifferentPathRedirectParameters = {
  path: string;
} & Partial<
  Pick<PerformWsFromPathParametersClientSpecific, 'search'> &
    Pick<PerformWsFromPathParametersServerSpecific, 'query'>
>;

/**
 * Compute the redirection path by adding `query` or `search` to the `path` provided.\
 * Take care of the orangebox case.
 */
export const getDifferentPathRedirect = ({
  path,
  query,
  search,
}: GetDifferentPathRedirectParameters): PerformWsFromPathRedirect => {
  const onClickWithQueries = [query, search].reduce<string>(
    (accumulator, queries) => {
      if (queries) {
        return addQueriesParam(accumulator, getStringMapFromParams(queries));
      }

      return accumulator;
    },
    path
  );

  return {
    redirect: onClickWithQueries,
  };
};

import { VIEWPORTS } from '../dimensions/constants';

/**
 * Get default Viewports
 *  - Returns `VIEWPORTS.ASCENDING` if `isDesktopOnly` and `isMobileOnly` are false
 *  - Returns `VIEWPORTS.DESKTOP_ASCENDING` if `isDesktopOnly` is true
 *  - Returns `VIEWPORTS.MOBILE_ASCENDING` if `isMobileOnly` is true
 */
export const getDefaultViewports = (
  isDesktopOnly?: boolean,
  isMobileOnly?: boolean
) => {
  if (isDesktopOnly) {
    return VIEWPORTS.DESKTOP_ASCENDING;
  }
  if (isMobileOnly) {
    return VIEWPORTS.MOBILE_ASCENDING;
  }
  return VIEWPORTS.ASCENDING;
};

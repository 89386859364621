import { ProfileModal } from '@canalplus/mycanal-commons';
import { useStore } from '@canalplus/one-navigation';
import type { ApiV2AvatarV1, ApiV2AvatarsV2 } from '@dce-front/hodor-types';
import type { JSX } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useQueryTemplate } from '../../helpers/hooks/useQueryTemplate/useQueryTemplate';
import {
  isKidsProfileSettingsSelector,
  modalCurrentViewSelector,
} from '../../store/slices/profilesModal-selectors';
import AvatarSelection from './AvatarSelection/AvatarSelection';
import AvatarV2Selection from './AvatarV2Selection/AvatarV2Selection';
import ProfileDeleteConfirmation from './ProfileDeleteConfirmation/ProfileDeleteConfirmation';
import ProfileCreation from './ProfileForm/ProfileCreation';
import ProfileEdition from './ProfileForm/ProfileEdition';
import ProfilesList from './ProfilesList/ProfilesList';
import { useAvatarContext } from './hooks/useAvatarContext';
import type { AvatarsData } from './types';

export type ProfilesViewHandlerProps = {
  setOpenModal: (isModalOpen: boolean) => void;
};

function ProfilesViewHandler({
  setOpenModal,
}: ProfilesViewHandlerProps): JSX.Element {
  const currentView: ProfileModal = useSelector(modalCurrentViewSelector);
  const isKidsProfileSelected = useSelector(isKidsProfileSettingsSelector);
  const { avatarUrl, avatarTemplate, isAvatarV2 } = useAvatarContext();

  const addIsKidsUrlParam = (originalUrl: string): string => {
    const url = new URL(originalUrl);
    url.searchParams.set('isKids', 'true');
    return url.toString();
  };

  const [{ data = {} }] = useQueryTemplate<ApiV2AvatarV1 | ApiV2AvatarsV2>(
    avatarUrl,
    {
      template: avatarTemplate,
    }
  );

  const [{ data: dataIsKids = {} }] = useQueryTemplate<ApiV2AvatarsV2>(
    addIsKidsUrlParam(avatarUrl),
    {
      template: avatarTemplate,
    },
    {
      enabled: isAvatarV2,
    }
  );

  const avatarsWithKids: AvatarsData = {
    default: data,
    kids: dataIsKids,
  };

  const store = useStore();
  useEffect(() => {
    store.focusDefault();
  }, [currentView, store]);

  switch (currentView) {
    case ProfileModal.ProfileListView:
      return <ProfilesList />;
    case ProfileModal.ProfileCreationView:
    case ProfileModal.ProfileCreationViewFromLanding:
      return (
        <ProfileCreation
          avatars={
            isAvatarV2
              ? isKidsProfileSelected
                ? avatarsWithKids.kids?.strates || []
                : (avatarsWithKids.default as ApiV2AvatarsV2)?.strates || []
              : (avatarsWithKids.default as ApiV2AvatarV1)?.contents
          }
          setOpenModal={setOpenModal}
        />
      );
    case ProfileModal.ProfileEditionView:
      return (
        <ProfileEdition
          avatars={
            isAvatarV2
              ? isKidsProfileSelected
                ? avatarsWithKids.kids?.strates || []
                : (avatarsWithKids.default as ApiV2AvatarsV2)?.strates || []
              : (avatarsWithKids.default as ApiV2AvatarV1)?.contents
          }
        />
      );
    case ProfileModal.AvatarSelectionView:
      return isAvatarV2 ? (
        <AvatarV2Selection
          avatarStrates={
            isKidsProfileSelected
              ? avatarsWithKids?.kids?.strates || []
              : (avatarsWithKids?.default as ApiV2AvatarsV2)?.strates || []
          }
        />
      ) : (
        <AvatarSelection avatars={(data as ApiV2AvatarV1)?.contents} />
      );
    case ProfileModal.DeleteConfirmationView:
      return <ProfileDeleteConfirmation />;
    default:
      return <ProfilesList />;
  }
}

export default ProfilesViewHandler;

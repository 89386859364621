import type { ApiV2OnClick } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import { Heading } from '../Heading/Heading';
import { HeadingLevels } from '../Heading/HeadingTypes';
import styles from './TemplateHeader.module.css';

export type TemplateHeaderProps = {
  displayBigTitle: boolean;
  Linker: (props: any) => ReactNode;
  button?: {
    onClick?: ApiV2OnClick;
    title?: string;
  };
  isFromCreativeMedia?: boolean;
  isFromDetail?: boolean;
  isFromShowcase?: boolean;
  isTvDevice?: boolean;
  subtitle?: string;
  theme?: Record<string, any>;
  title?: string;
};

export function TemplateHeader({
  button,
  displayBigTitle,
  isFromDetail,
  isFromShowcase,
  isFromCreativeMedia,
  isTvDevice = false,
  Linker,
  subtitle,
  theme = {},
  title,
}: TemplateHeaderProps) {
  return (
    <div
      className={classNames(styles.templateHeader, theme.templateHeader)}
      data-testid="template-header-container"
    >
      <div className={styles.templateHeader__topContent}>
        <div
          className={classNames(
            styles.templateHeader__title,
            {
              [styles.templateHeader__title__showcase]: isFromShowcase,
              [styles['templateHeader__title--dark']]:
                isFromDetail || isFromShowcase || isFromCreativeMedia,
            },
            theme.templateHeader__title
          )}
        >
          <Heading
            text={title}
            isBig={displayBigTitle}
            isFromDetail={isFromDetail}
            isFromShowcase={isFromShowcase}
          />
        </div>

        {!isTvDevice && button && button.title && (
          <div className={styles.templateHeader__button}>
            <Linker
              data={{ mainOnClick: button.onClick }}
              title={button.title}
              className={classNames(
                styles.templateHeader__link,
                theme.templateHeader__link
              )}
              aria-label={`${button.title} ${title}`}
            >
              {button.title}
            </Linker>
          </div>
        )}
      </div>
      {subtitle && (
        <div
          className={classNames(
            styles.templateHeader__subtitle,
            {
              [styles.templateHeader__subtitle__showcase]: isFromShowcase,
              [styles.templateHeader__subtitle__truncate]: !isFromShowcase,
              [styles.templateHeader__subtitle__dark]:
                isFromDetail || isFromShowcase,
            },
            theme.templateHeader__subtitle
          )}
        >
          <Heading
            text={subtitle}
            level={HeadingLevels.LEVEL_3}
            isFromDetail={isFromDetail}
            isFromShowcase={isFromShowcase}
          />
        </div>
      )}
    </div>
  );
}

import type {
  OneCoreContext,
  OneCoreProfile,
  OneCoreSystem,
} from '@canalplus/ifc-onecore';
import {
  getContext,
  getProfile,
  getSystem,
  refreshProfile,
  setContext,
  setExternalFrameVersion,
} from '@canalplus/ifc-onecore';
import { ResizeMode } from '@canalplus/mycanal-commons';
import { OfferLocation, OfferZone, OneCorePlatform } from '@canalplus/sdk-core';
import type {
  CreateTokenParameters,
  PassUserDataEnriched,
} from '@canalplus/sdk-pass';
import { BufferQueries, Queries } from '../../constants/url';
import { getPublicConfig } from '../config/config-helper';
import { getResizeModeAuto } from '../resize/ResizeHelper';
import { getBuild } from './getBuild';
import { patchIfcForMocks } from './ifc-onecore.patch';

/**
 * Initial query params when start app
 */
let initialQueryParams = '';

/**
 * To save the initial query params when start app
 * @param queryStr string
 * @returns void
 */
export const setInitialQueryParams = (queryStr: string): void => {
  initialQueryParams = queryStr;
};

/**
 * To get the initial query params when we started the app
 * @returns string
 */
const getInitialQueryParams = (): string => {
  return initialQueryParams;
};

function parseQs(str: string): Record<string, string> {
  return str
    .replace(/^[?&]/, '')
    .split('&')
    .reduce((query, param) => {
      const [key, value] = param.split('=');

      if (key && value) {
        return { ...query, [key]: decodeURIComponent(value) };
      }

      return query;
    }, {});
}

function serializeQs(query: Record<string, string | number | boolean>): string {
  return Object.entries(query)
    .reduce<string[]>((qs, [key, value]) => [...qs, `${key}=${value}`], [])
    .join('&');
}

export type OneDiscoveryBufferUrlParameters = {
  profile: OneCoreProfile;
  system: OneCoreSystem;
  context: OneCoreContext;
  query: Record<string, string>;
};

/**
 * Filter object queries to have only supported queries values for buffer entry page.
 * See enum BufferQueries to know supported queries values
 * @param queryParams Record<string, string>
 * @returns Record<string, string> queryParams filtered
 */
export function filterQueryParamsBuffer(
  queryParams: Record<string, string>
): Record<string, string> {
  return Object.entries(BufferQueries)
    .filter((entry: [string, BufferQueries]) => queryParams[entry[1]])
    .reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue[1]]: queryParams[currentValue[1]],
      }),
      {}
    );
}

/**
 * Parse the one-core deeplink path. This prevents unwanted behavior when one-core sends things such as "#consent/"
 * @param oneCorePath Raw path returned by one-core
 * @returns the pathname if successfully parsed, undefined otherwise
 */
export function parseOneCoreDeeplinkPath(
  oneCorePath?: string
): string | undefined {
  if (!oneCorePath) {
    return undefined;
  }

  try {
    const parsedUrl = new URL(`${window.location.origin}${oneCorePath}`);

    return parsedUrl.pathname !== '/' ? parsedUrl.pathname : undefined;
  } catch {
    return undefined;
  }
}

/** Compute the OneDiscovery buffer url. */
export function getOneDiscoveryBufferUrl({
  profile,
  system,
  context,
  query,
}: OneDiscoveryBufferUrlParameters): string {
  const offerLocation = (
    profile.offerLocation || OfferLocation.fr
  ).toLowerCase();
  const offerZone = (profile.offerZone || OfferZone.cpfra).toLowerCase();
  const { deeplink: { callbackState, updateRights } = {} } = context;

  const redirectQueries: Record<string, string> = {
    ...(callbackState && { [Queries.CallbackState]: callbackState }),
    ...(updateRights && { [Queries.UpdateRights]: String(updateRights) }),
    [Queries.Display]: 'tv',
    [Queries.Build]: getBuild(navigator.userAgent),
    [Queries.OfferLocation]: offerLocation,
    [Queries.OfferZone]: offerZone,
    [Queries.Buffer]: 'false',
  };

  const filteredQueryParamsBuffer = filterQueryParamsBuffer(query);

  // Copy params from query (except path)
  Object.entries(filteredQueryParamsBuffer).forEach(([key, value]) => {
    if (key !== Queries.Path) {
      redirectQueries[key] = value;
    }
  });

  if (profile.passId && (profile.accountId || profile.subscriberId)) {
    redirectQueries[Queries.Token] = encodeURIComponent(profile.passId);
  }

  if (system.target) {
    redirectQueries[Queries.Platform] =
      Object.entries(OneCorePlatform).find(
        ([, value]) => value === system.target
      )?.[0] || OneCorePlatform.Web;
  }

  if (profile.askForConsent) {
    redirectQueries[Queries.DisplayGDPR] = 'true';
  }

  // Handle initial history
  let homePath = ([OfferLocation.fr, OfferLocation.it] as string[]).includes(
    offerLocation
  )
    ? '/'
    : `/${offerLocation}/`;

  // Temporary dev for german-speaking switzerland (waiting for the multi-language)
  if (offerZone === OfferZone.cpchd) {
    homePath = `/${offerLocation}/de/`;
  }

  const initialHistoryEntries = [homePath];

  const { deeplink: { path: rawDeepLinkPath } = {}, lastUrl } = context;

  const firstLastUrl = (lastUrl as string[])?.[0];
  const isLastUrlWithContext = firstLastUrl && callbackState && updateRights;

  // We take in priority the path given via qs to buffer page, otherwise we try to extract a deeplink
  // path given by one-core
  const deepLinkPath =
    filteredQueryParamsBuffer.path ||
    parseOneCoreDeeplinkPath(rawDeepLinkPath) ||
    firstLastUrl;

  if (isLastUrlWithContext) {
    // Refresh profile directly it should not loop because we clear context lastUrl
    refreshProfile({ forceRefresh: true });
  }

  if (deepLinkPath) {
    initialHistoryEntries.push(deepLinkPath);
  }

  redirectQueries[Queries.InitialHistoryEntries] = encodeURIComponent(
    JSON.stringify(initialHistoryEntries)
  );
  let redirectPath = deepLinkPath || filteredQueryParamsBuffer.path || homePath;

  if ($_BUILD_RENDERMODE_CSR) {
    // The full CSR instance can be hosted within a subfolder (as for Orange).
    // It is therefore required to systematically point to a root url '/' and to manage our Hodor page calls through a "path" query.
    redirectPath = window.location.pathname;
  }

  setContext({
    context: {
      // reset context created after redirecting wait @oneCore NWA-6697 clean context api
      lastUrl: undefined,
    },
  });

  return `${redirectPath}?${serializeQs(redirectQueries)}`;
}

/**
 * Use Ifc to compute the OneDiscovery buffer url and redirect to it.
 * @param queryParamsStr string queryParams from buffer page
 * @returns void
 */
export async function redirectToAppWithQueriesAndOneCoreData(
  queryParamsStr: string,
  replace: boolean = false,
  done?: (options: {
    profile: OneCoreProfile;
    system: OneCoreSystem;
    context: OneCoreContext;
  }) => void
): Promise<void> {
  const queryParamsBuffer = parseQs(queryParamsStr);

  // if no resize query param, apply detection resize auto
  if (
    !queryParamsBuffer[BufferQueries.Resize] ||
    queryParamsBuffer[BufferQueries.Resize] === ResizeMode.RESIZE_AUTO
  ) {
    queryParamsBuffer[BufferQueries.Resize] = getResizeModeAuto();
  }

  // mock Ifc if necessary
  if (
    queryParamsBuffer[BufferQueries.Mock] === 'true' ||
    getPublicConfig()?.mock
  ) {
    // Run the Ifc patch file if we're in mock configuration
    await patchIfcForMocks();
  }

  try {
    const [profile, system, context] = await Promise.all([
      getProfile(),
      getSystem(),
      getContext(),
    ]);

    // We need to set OneDiscovery version for OneCore (visible in hidden menu)
    setExternalFrameVersion({
      version: $_BUILD_APP_LONG_VERSION,
      service: 'onediscovery_version',
    });

    const redirectUrl = getOneDiscoveryBufferUrl({
      profile,
      system,
      context,
      query: queryParamsBuffer,
    });

    if (replace) {
      window.history.replaceState(window.history.state, '', redirectUrl);
    } else {
      console.log(
        `[OneDiscovery] You are redirected to this url: ${redirectUrl}`
      );
      window.location.replace(redirectUrl);
    }

    done?.({ profile, system, context });
  } catch (onecoreError) {
    console.error(onecoreError);
  }
}

export function formatOneCoreProfileData(
  data: OneCoreProfile
): PassUserDataEnriched {
  // OneCore getProfile response is not formatted exactly like the createToken Pass response.
  // OneCore must send us the same dataset as that of the Pass createToken.
  return {
    ...data,
    accountId:
      typeof data.accountId === 'string' ? parseInt(data.accountId, 10) : 0,
    subscriberId:
      typeof data.subscriberId === 'string'
        ? parseInt(data.subscriberId, 10)
        : 0,
    epgid: Object.entries(data.epgid || {})
      .filter(([_epg, enabled]) => !!enabled)
      .map(([epg]) => epg)
      .join(','),
    epgid_cat5: data.macroEligibility.join(','),
    macroEligibility: data.macroEligibility.join(','),
    microEligibility: JSON.stringify(data.microEligibility).replace(
      /["\s{}]/g,
      ''
    ),
  } as any;
}

/**
 * Reload the app with the initial query params
 */
export const reloadAppWithInitialQueryParams = async (): Promise<void> => {
  return redirectToAppWithQueriesAndOneCoreData(
    getInitialQueryParams() || window.location.search
  );
};

/** Update rights with Ifc lib in tvDevice */
export const refreshProfileOneCore = async ({
  noCache,
}: Pick<CreateTokenParameters, 'noCache'>): Promise<PassUserDataEnriched> => {
  try {
    const oneCoreProfile = await refreshProfile(
      noCache ? { forceRefresh: true } : undefined
    );

    return formatOneCoreProfileData(oneCoreProfile);
  } catch (error) {
    throw new Error(`refreshProfile failed with error: ${error}`);
  }
};

/**
 * Update rights with Ifc lib in tvDevice and reload the app with new token
 * @returns Promise<void>
 */
export const refreshProfileOneCoreAndReload = async (
  noCache: Pick<CreateTokenParameters, 'noCache'>
): Promise<void> => {
  try {
    await refreshProfileOneCore(noCache);
    await reloadAppWithInitialQueryParams();
  } catch (e) {
    console.error(e);
  }
};

import type { IMeta } from '@canalplus/mycanal-sdk';
import type { ApiV2DetailLight } from '@dce-front/hodor-types';
import type { ApiV2DetailLightContent } from '@dce-front/hodor-types/api/v2/detail_light/definitions';

export const getDetailLightFormatted = (
  data: ApiV2DetailLight
): ApiV2DetailLight & { meta?: IMeta } => {
  // Retrieve meta from the default initial item
  let meta: IMeta | undefined;
  const defaultItem =
    data.contents?.find((content) => content.default) || data.contents?.[0];
  if (defaultItem) {
    const { URLImage, title, actionLayout } =
      defaultItem as ApiV2DetailLightContent;
    const path = actionLayout?.primaryActions?.[0]?.onClick?.path;

    meta = {
      title,
      urlImage: URLImage,
      canonical: path,
    };
  }

  return {
    ...data,
    ...(meta && { meta }),
  };
};

import { fillTemplate } from '@canalplus/mycanal-commons';
import { PassPlatform } from '@canalplus/sdk-core';
import { useCallback, useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { platformSelector } from '../../../store/slices/application-selectors';
import type { IState } from '../../../store/types/State-type';
import { isUrlContainsMedia } from '../../url/url-helper';

interface IUseIDPTokenAuthentication {
  authenticate(authenticationURLParam: string): void;
}

/**
 * Generic hook to authenticate to an external service with an IDP token.
 * @param idpToken
 */
export function useAuthFromExternalSiteWithIDPToken(
  idpToken: string
): IUseIDPTokenAuthentication {
  const store = useStore<IState>();
  const [formElement, setFormElement] = useState<HTMLFormElement | null>();

  /**
   * We use a good old <form> element because CORS policy is
   * preventing us from using window.fetch.
   */
  const authenticate = useCallback(
    (authenticationURLParam: string) => {
      let authenticationURL = authenticationURLParam;

      // if formElement already exist
      if (formElement) {
        formElement.remove();
      }

      const htmlFormElement = document.createElement('form');
      htmlFormElement.method = 'post';
      htmlFormElement.target = '_blank';

      const idpTokenField = document.createElement('input');
      idpTokenField.type = 'hidden';
      idpTokenField.name = 'idpToken';
      idpTokenField.value = idpToken;

      htmlFormElement.appendChild(idpTokenField);
      document.body.appendChild(htmlFormElement);

      setFormElement(htmlFormElement);

      if (isUrlContainsMedia(authenticationURL)) {
        const state = store.getState();
        const platform = platformSelector(state);
        const media = PassPlatform[platform];

        if (media) {
          authenticationURL = fillTemplate(authenticationURL, [
            ['media', media],
          ]);
        }
      }

      if (htmlFormElement) {
        // eslint-disable-next-line react-compiler/react-compiler
        htmlFormElement.action = authenticationURL;
        htmlFormElement?.submit();
      }
    },
    [formElement, store, idpToken]
  );

  useEffect(() => {
    return function disposeHook() {
      if (formElement) {
        formElement.remove();
      }
    };
  }, [formElement]);

  return {
    authenticate,
  };
}

import type { JSX } from 'react';
import { useEffect, useRef } from 'react';
import type { HorizontalListProps } from '../HorizontalList/HorizontalList';
import { HorizontalList } from '../HorizontalList/HorizontalList';

export type HorizontalListLazyLoaderProps = HorizontalListProps & {
  getNextContent?: (amountToFetch?: number) => void;
};

export function HorizontalListLazyLoader({
  children,
  getNextContent,
  ratio,
  imageSize,
  ...restHorizontalListProps
}: HorizontalListLazyLoaderProps): JSX.Element {
  const listRef = useRef<HTMLOListElement>(null);

  // This UseEffect trigger Pagination for standard strates and liveTV strates in landing.
  useEffect(() => {
    if (listRef.current !== null && getNextContent) {
      const node = listRef.current.querySelectorAll('li');
      // Sometimes Hodor send us only one or two contents so itemObserved can be undefined
      const itemObserved = node[node.length - 2];
      // Check if browser support Observer
      if ('IntersectionObserver' in window && itemObserved) {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              getNextContent();
            }
          },
          {
            threshold: [0.1],
          }
        );
        if (itemObserved) {
          observer.observe(itemObserved);
        }
        return () => {
          observer.unobserve(itemObserved);
        };
      } else {
        getNextContent(999);
      }
    }
    return;
  }, [getNextContent]);

  return (
    <HorizontalList
      ratio={ratio}
      imageSize={imageSize}
      ref={listRef}
      {...restHorizontalListProps}
    >
      {children}
    </HorizontalList>
  );
}

import type { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import type { ApiV2PageTracking } from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { requestTemplate } from './page';

export type SearchState = {
  active: boolean;
  query: string;
  tracking?: Tracking | ApiV2PageTracking;
};

export const initialState: SearchState = {
  active: false,
  query: '',
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    /** Action dispatched when you close the search */
    closeSearch() {
      return initialState;
    },

    /** Action dispatched when you update the search query */
    querySearch(state, action: PayloadAction<string>) {
      return {
        ...state,
        query: action.payload,
      };
    },

    /** Action dispatched when the search is active */
    setSearchActive(state) {
      return {
        ...state,
        active: true,
      };
    },

    /** Action dispatched when to update search tracking */
    updateSearchTracking(
      state,
      action: PayloadAction<Tracking | ApiV2PageTracking>
    ) {
      return {
        ...state,
        tracking: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      'page/requestTemplate' satisfies typeof requestTemplate.type,
      () => initialState
    );
  },
});

export const {
  closeSearch,
  querySearch,
  setSearchActive,
  updateSearchTracking,
} = searchSlice.actions;

export const searchReducer = searchSlice.reducer;

export type SearchActions = ReturnType<
  (typeof searchSlice.actions)[keyof typeof searchSlice.actions]
>;

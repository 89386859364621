import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { getDocumentHeaderMetasType } from '../../helpers/metaTags/metaTags-helper';
import {
  fullLocaleSelector,
  offerLocationSelector,
} from '../../store/slices/application-selectors';
import { CanonicalLinkTags } from './CanonicalLinkTags';

/**
 * Init react-helmet with default values from app config
 */
export function MetaTagsDefault() {
  const offerLocation = useSelector(offerLocationSelector);
  const documentHeader = getDocumentHeaderMetasType(offerLocation);
  const locale = useSelector(fullLocaleSelector);

  // link meta tag
  const link = [...(documentHeader?.app.head.link ?? [])];

  const meta = useMemo(
    () => [
      ...(documentHeader?.app.head.meta ?? []).map((meta) => {
        // Update og:locale meta tag according to the current locale (full locale)
        if (meta.property === 'og:locale') {
          return { ...meta, content: locale };
        }
        return meta;
      }),
      { name: 'description', content: documentHeader?.app.description },
    ],
    [documentHeader, locale]
  );

  return (
    <>
      <CanonicalLinkTags />
      <Helmet
        {...documentHeader?.app.head}
        meta={meta}
        link={link}
        title={documentHeader?.app.title}
      />
    </>
  );
}

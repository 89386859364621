import type { JSX } from 'react';
import { forwardRef } from 'react';
import styles from './TriggerVerticalScroll.css';

const TriggerVerticalScroll = forwardRef<HTMLDivElement>(
  (_, ref): JSX.Element => {
    return <div ref={ref} className={styles.triggerVerticalScroll} />;
  }
);

TriggerVerticalScroll.displayName = 'TriggerVerticalScroll';

export default TriggerVerticalScroll;

import type { IInitLiveTVData } from '@canalplus/oneplayer-types';
import type { AccessibleChannel } from '../../store/slices/user-type';

export function getLiveTVAccessibleChannels(
  initLiveTVData: IInitLiveTVData
): AccessibleChannel[] {
  // On some cases (orange) we may only retrieve group "0" and have to filter channels ourself
  const channels =
    initLiveTVData?.channels?.get('1') || initLiveTVData?.channels?.get('0');
  const allChannels = channels?.allChannels;
  if (allChannels) {
    const accessibleChannels: AccessibleChannel[] = allChannels
      // Exclude non authorized channels
      .filter((channel) => channel.isChannelAuthorized)
      .map((channel) => ({
        epgID: channel?.epgId, // Note we're changing the "d" from lower- to uppercase to match Hodor's casing
        isStartOverAuthorized: channel?.isStartOverAuthorized || false,
      }));
    return accessibleChannels;
  }
  return [];
}

import {
  getThumborSources,
  getThumborUrl,
  isDeviceToViewportToImageDimensions,
  isViewportToImageDimensions,
  THUMBOR_QUALITY_TO_DPR,
} from '@canalplus/dive';
import {
  getDefaultViewports,
  getDevicePixelRatio,
  getDimensionsFromResizeMode,
  THUMBOR,
} from '@canalplus/mycanal-commons';
import classNames from 'classnames';
import type { JSX } from 'react';
import styles from './ImageType.module.css';
import type { ImageTypeProps } from './types';

export function ImageType({
  altImage = '',
  className,
  dimensions,
  id,
  imageQualityPercentage: quality = THUMBOR.QUALITY_DEFAULT,
  isArtDirection = false,
  isFixedDimensions = false,
  isDesktopOnly = false,
  isMobileOnly = false,
  isTvDevice = false,
  loading = 'lazy',
  maxImageRatio,
  resizeMode,
  onLoad,
  URLImage,
  dataTestId,
}: ImageTypeProps): JSX.Element | null {
  // Return null when BO contribution is faulty
  if (
    !URLImage?.default ||
    (isViewportToImageDimensions(dimensions) && !dimensions.XLarge) ||
    (isDeviceToViewportToImageDimensions(dimensions) &&
      !dimensions.default?.XLarge)
  ) {
    return null;
  }

  const devicePixelRatio = Math.min(
    THUMBOR_QUALITY_TO_DPR[quality],
    maxImageRatio ?? getDevicePixelRatio()
  );

  const thumborUrl = getThumborUrl({
    url: URLImage?.default,
    includesAllowedSourceInUrl:
      THUMBOR.URLS_ALREADY_INCLUDING_ALLOWED_SOURCE.some((endpoint) =>
        URLImage?.default?.includes(endpoint)
      ),
    dimensions: getDimensionsFromResizeMode(dimensions, isTvDevice, resizeMode),
    quality,
    devicePixelRatio,
  });

  const showSingleImgElement =
    isTvDevice || isFixedDimensions || !isArtDirection;

  const img = (
    <img
      loading={loading}
      ref={(ref) => (ref?.complete ? onLoad?.() : undefined)}
      src={
        // Favour highest src resolution when rendering single <img> element
        showSingleImgElement && thumborUrl?.urlRetina
          ? thumborUrl?.urlRetina
          : thumborUrl.url
      }
      data-testid={dataTestId}
      alt={altImage}
      className={classNames(styles.ImageType, className)}
      onLoad={onLoad}
      id={id}
    />
  );

  if (showSingleImgElement) {
    return img;
  }

  const thumborSources = getThumborSources({
    dimensions,
    urlsWithAllowedSource: THUMBOR.URLS_ALREADY_INCLUDING_ALLOWED_SOURCE,
    urls: URLImage,
    quality,
    devicePixelRatio,
    viewports: getDefaultViewports(isDesktopOnly, isMobileOnly),
  });

  return (
    <picture id={id}>
      {thumborSources?.map(({ media, srcset }) => (
        <source key={media} srcSet={srcset?.join(', ')} media={media} />
      ))}
      {img}
    </picture>
  );
}

import { useEffect } from 'react';
import { FocusManager } from '../../oneNavigation/FocusManager';

/**
 * Calls the onFocusable callback when second parameter is passed to true
 * @param onFocusable
 * @param focusable
 */
export function useOnFocusable(
  focusManager?: FocusManager,
  focusable: boolean = true
): void {
  useEffect(() => {
    if (focusable && focusManager) {
      focusManager.onFocusable();
    }
  }, [focusable, focusManager]);
}

import { Media } from '@canalplus/dive';
import type { Ratio } from '@canalplus/mycanal-commons';
import type { JSX } from 'react';
import styles from './ContentRowTemplateItem.css';

export function ContentRowTemplateItemPlaceholder({
  ratio,
}: {
  ratio: Ratio;
}): JSX.Element {
  return (
    <li className={styles.contentRowTemplateItem}>
      <Media aspectRatio={ratio} data-tv-focusable />
    </li>
  );
}

import { useSelector } from 'react-redux';
import {
  buildSharedTitle,
  getThumborImageMetaTag,
  metaTagsTranslator,
} from '../../helpers/metaTags/buildMeta';
import {
  getDocumentHeaderMetasType,
  getTwitterMetasFromState,
} from '../../helpers/metaTags/metaTags-helper';
import type { IMetaTag } from '../../helpers/metaTags/types';
import { getUrlWithoutDefaultLang } from '../../helpers/url/url-helper';
import I18n from '../../lang';
import {
  hostnameSelector,
  offerLocationSelector,
} from '../../store/slices/application-selectors';
import { pageAdultSelector } from '../../store/slices/page-selectors';
import { pathnameSelector } from '../../store/slices/routing-selectors';

type UseMetasParams = {
  title?: string;
  description?: string;
  urlImage?: string;
  isLargeImageMetaTwitter?: boolean;
};

/**
 * Build meta tags for title, description, image and twitter
 * @returns IMetaTag[]
 */
export function useMetas({
  title,
  description,
  urlImage,
  isLargeImageMetaTwitter,
}: UseMetasParams): IMetaTag[] {
  const { t } = I18n.useTranslation();
  const offerLocation = useSelector(offerLocationSelector);
  const isAdult = useSelector(pageAdultSelector);
  const currentUrl = useSelector(hostnameSelector);
  const pathname = useSelector(pathnameSelector) || '';

  const documentHeader = getDocumentHeaderMetasType(offerLocation);
  const twitterAccount = documentHeader?.app?.head?.twitter?.twitterAccount;

  const metas: IMetaTag[] = [];

  // build meta tags with 'title' property
  const translatedTitle = metaTagsTranslator(t, title ?? '');
  if (title) {
    const sharedTitle = buildSharedTitle(translatedTitle, t);
    metas.push({ property: 'title', content: translatedTitle });
    metas.push({ property: 'og:title', content: sharedTitle });
  }

  // build meta tags with 'description' property
  if (description) {
    metas.push({ name: 'description', content: description });
    metas.push({ property: 'og:description', content: description });
  }

  // build image meta tag with 'image' property
  const thumbor = urlImage ? getThumborImageMetaTag(urlImage) : undefined;
  const imageMetaTagContent = !isAdult && thumbor ? thumbor.url : undefined;
  if (imageMetaTagContent) {
    metas.push({
      property: 'og:image',
      content: imageMetaTagContent,
    });
  }

  // build url meta tag with pathname
  const pathnameWithoutDefaultLang = getUrlWithoutDefaultLang(pathname);
  metas.push({
    property: 'og:url',
    content: `https://${currentUrl}${pathnameWithoutDefaultLang}`,
  });

  // build X (previously twitter) meta tags
  const metasTwitter = getTwitterMetasFromState({
    title: translatedTitle,
    description: description ?? '',
    urlImage: imageMetaTagContent,
    twitterAccount,
    isLargeImage: isLargeImageMetaTwitter,
  });
  metas.push(...metasTwitter);

  return metas;
}

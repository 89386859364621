import { transformToPromise } from './helpers/transformToPromise';
import type {
  RPC,
  RPCConfig,
  RPCOptions,
  RPCRequest,
  RPCResponse,
  RPCResponseError,
} from './types';

/**
 * To manage rpc calls.
 * It returns rpc, rpcWithCallback and onMessage receivers
 */
export const getRPC = (
  config: RPCConfig,
  sendPostMessage: <TParams = unknown>(
    uid: number,
    method: RPCRequest['method'],
    params?: TParams
  ) => void,
  getUID: () => number
): RPC => {
  // Dictionary of callbacks stored
  const callbacks: Record<
    number,
    (error: RPCResponseError | undefined, result?: any) => void
  > = {};

  const rpc = <TParams = unknown, TResult = unknown, TError = unknown>(
    method: RPCRequest['method'],
    params?: TParams,
    options?: RPCOptions
  ): Promise<TResult> => {
    return transformToPromise<TParams, TResult, TError>(
      sendRpc,
      config,
      callbacks,
      method,
      params,
      options
    );
  };

  const sendRpc = <TParams = unknown, TResult = unknown, TError = unknown>(
    method: RPCRequest['method'],
    params?: TParams,
    callback?: (
      error: RPCResponseError<TError> | undefined,
      result: TResult
    ) => void
  ): number => {
    const uid = getUID();

    // store the callback callbacks to be called when receive response
    if (callback) {
      callbacks[uid] = callback;
    }

    sendPostMessage(uid, method, params);

    return uid;
  };

  const onMessage = (data: RPCResponse) => {
    const { id, result, error } = data;

    if (id) {
      // rpc
      const callback = callbacks[id];
      if (callback) {
        callback(error, result);
        delete callbacks[id];
      }
    }
  };

  return { rpc, sendRpc, onMessage };
};

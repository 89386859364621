import { UserMenuOption } from '@canalplus/mycanal-sharedcomponent';
import { Template } from '@canalplus/sdk-hodor';
import type { ApiV2OnClick } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import classNames from 'classnames';
import type { JSX, ReactElement, ReactNode } from 'react';
import IconRightArrow from '../../assets/svg/rightArrow.svg';
import { useInvariantSelector } from '../../helpers/hooks/useInvariantSelector';
import { refreshProfileOneCoreAndReload as updateRightsAndReloadTv } from '../../helpers/ifcOneCore/ifc-onecore-helpers';
import { imageLoadingSelector } from '../../store/slices/application-selectors';
import ButtonLinker, {
  ButtonLinkerVariant,
} from '../ButtonLinker/ButtonLinker';
import { FlipSwitch } from '../FlipSwitch/FlipSwitch';
import Linker from '../Linker/Linker';
import Slider from '../Slider/Slider';
import styles from './Setting.css';

type LabelAndLinkProps = {
  children: React.ReactNode;
  isMediumLabelFontSize: boolean;
  labelIcon?: ReactElement;
  label: string;
};

function LabelAndLink({
  isMediumLabelFontSize,
  labelIcon,
  label,
  children,
}: LabelAndLinkProps): JSX.Element {
  return (
    <>
      <div
        className={classNames(styles.setting__type, {
          [styles['setting__type--medium']!]: isMediumLabelFontSize,
        })}
      >
        {labelIcon || null}
        {label && <p>{label}</p>}
      </div>
      <div className={styles.setting__action}>{children}</div>
    </>
  );
}

export type SettingProps = {
  'aria-label'?: string;
  children?: ReactNode | null;
  currentValue?: number;
  footerText?: string;
  handleChange?: (event?: React.MouseEvent<HTMLElement>) => void;
  id?: string;
  isChecked?: boolean;
  isInputRange?: boolean;
  isMediumLabelFontSize?: boolean;
  isSubscription?: boolean;
  isTvDevice?: boolean;
  isTypeFlipSwitch?: boolean;
  label?: string;
  labelIcon?: ReactElement;
  labelIsAction?: string;
  maxValue?: number;
  minValue?: number;
  name?: string;
  onClick?: ApiV2OnClick;
  placeholder?: string;
  sticky?: boolean;
  withBorder?: boolean;
};

/**
 * @param isTypeFlipSwitch  whether if render type is FlipSwitch
 * @param label             content to render
 * @param id                flipSwitch ID
 * @param name              flipSwitch name
 * @param isChecked         whether if flipSwitch is checked
 * @param handleChange      action called when flipSwitch was changed
 * @param children          components to render
 * @param isSubscription    whether the subscription section should be displayed or not
 * @param linkFontSize      label link font size
 */
const Setting = ({
  'aria-label': ariaLabel = '',
  children = null,
  currentValue = 80,
  footerText = '',
  handleChange = () => null,
  id = '',
  isChecked = false,
  isInputRange = false,
  isMediumLabelFontSize = false,
  isSubscription = false,
  isTvDevice = false,
  isTypeFlipSwitch = false,
  label = '',
  labelIcon,
  maxValue = 100,
  minValue = 0,
  name = '',
  onClick,
  placeholder = '',
  sticky = false,
  withBorder = false,
}: SettingProps): JSX.Element => {
  const imageLoading = useInvariantSelector(imageLoadingSelector);

  const renderFlipSwitch = () => {
    return (
      <li className={styles.setting}>
        <div className={styles.setting__type}>{children}</div>
        <div className={styles.setting__action}>
          <FlipSwitch
            handleChange={handleChange}
            name={name}
            id={id}
            isChecked={isChecked}
            aria-label={ariaLabel}
          />
        </div>
      </li>
    );
  };

  const renderTvButton = () => {
    return (
      <li className={styles.setting_tv}>
        {onClick?.displayTemplate === Template.UpdateRights ? (
          <UserMenuOption
            ariaTitle={label}
            handleClick={() => updateRightsAndReloadTv({ noCache: true })}
            id={id}
            Linker={Linker}
            showChevron={<IconRightArrow />}
            subTitle={placeholder}
            title={label || ''}
            loading={imageLoading}
          />
        ) : (
          <ButtonLinker
            className={classNames(styles.setting_tv_button, 'settingTvButton')}
            id={id}
            linkerData={{ mainOnClick: onClick }}
            ariaLabel={label}
            text={label}
            hasActionIcon={!!children}
            icon={children}
            subtext={placeholder}
            variant={ButtonLinkerVariant.MenuOption}
          />
        )}
        {footerText && (
          <div className={styles.setting_tv_info}>{footerText}</div>
        )}
      </li>
    );
  };

  const renderLinks = () => {
    if (isSubscription) {
      return <li className={styles.setting__multilinks}>{children}</li>;
    }

    return (
      <li
        className={classNames(styles.setting, {
          [styles.setting__withBorder!]: withBorder,
          [styles.setting__sticky!]: sticky,
        })}
      >
        {onClick ? (
          <Linker
            data={{ mainOnClick: onClick }}
            className={styles.setting__button}
          >
            <LabelAndLink
              labelIcon={labelIcon}
              isMediumLabelFontSize={isMediumLabelFontSize}
              label={label}
            >
              {children}
            </LabelAndLink>
          </Linker>
        ) : (
          <LabelAndLink
            labelIcon={labelIcon}
            isMediumLabelFontSize={isMediumLabelFontSize}
            label={label}
          >
            {children}
          </LabelAndLink>
        )}
      </li>
    );
  };

  const renderInputRange = () => {
    return (
      <li className={styles.setting}>
        <div className={styles.setting__type}>{children}</div>
        <Slider
          minValue={minValue}
          maxValue={maxValue}
          currentValue={currentValue}
          handleChange={handleChange}
        />
      </li>
    );
  };

  if (isTypeFlipSwitch) {
    return renderFlipSwitch();
  }

  if (isInputRange) {
    return renderInputRange();
  }

  return isTvDevice ? renderTvButton() : renderLinks();
};

export default Setting;

import { useLayerId, useStore } from '@canalplus/one-navigation';
import { useIsFetching } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { FocusManager } from '../../helpers/oneNavigation/FocusManager';

export type FocusableFallbackProps = {
  focusManager?: FocusManager;
};

/**
 * Monitor react-query pending requests and fire onFocusable once it drops to 0.
 * This code is isolated here because `useIfFetching()` cause many updates and we don't want it to
 * trigger re-renders on big components such as landing
 */
export function FocusableFallback({
  focusManager,
}: FocusableFallbackProps): null {
  const isFetching = useIsFetching();
  const prevIsFetching = useRef(0);
  const store = useStore();
  const layerId = useLayerId();

  useEffect(() => {
    if (
      isFetching === 0 &&
      prevIsFetching.current > 0 &&
      focusManager &&
      !store.current &&
      store.activeLayer === layerId
    ) {
      focusManager.onFocusable();
    }
    prevIsFetching.current = isFetching;
  }, [isFetching, prevIsFetching, store, focusManager, layerId]);

  return null;
}

import type { ApiV2ChannelFavoriteSelectionPage } from '@dce-front/hodor-types/api/v2/channels/favorite_channels/definitions';
import type { MeListContentWriteBody } from '@dce-front/hodor-types/api/v2/me/lists/schema';
import type { ILiveGridFavoritesState } from './types';

export const getFormattedLiveTvFavorites = (
  data: ApiV2ChannelFavoriteSelectionPage
): ILiveGridFavoritesState => {
  const { channels = [] } = data || {};
  return {
    channels,
  };
};

export const formatBody = (epgIDs: number[]): MeListContentWriteBody => {
  return {
    contentIds: epgIDs.map(String),
  };
};

import type { ApiV2NavigationItem } from '@dce-front/hodor-types/api/v2/authenticate/definitions';
import { defaultSocialLinks } from './config';
import type { FormattedSocialLink } from './types';

export type SocialLinks =
  | ({ contentID?: string } & ApiV2NavigationItem)[]
  | {
      facebook: string;
      twitter: string | null;
      dailymotion: string | null;
      youtube: string | null;
    };

export const getSocialLinks = (
  isSocialLinksFromBO: boolean,
  socialLinks: SocialLinks
): FormattedSocialLink[] => {
  if (isSocialLinksFromBO && Array.isArray(socialLinks)) {
    // Get social links from props (from BO)
    return socialLinks.map((socialLink) => ({
      link: socialLink.onClick?.URLWebsite,
      name: socialLink.contentID || socialLink.displayName?.toLowerCase(),
      displayName: socialLink.displayName,
    }));
  }

  // Get social links whether from props if defined (from config) or headerfooter config by default
  return socialLinks
    ? Object.entries(socialLinks).reduce(
        (footerSocials, [socialLinkKey, socialLinkValue]) => {
          if (socialLinkValue) {
            return [
              ...footerSocials,
              {
                ...defaultSocialLinks.find(
                  ({ name }) => socialLinkKey === name
                ),
                ...(socialLinkValue ? { link: socialLinkValue } : {}),
              },
            ];
          }
          return footerSocials;
        },
        [] as FormattedSocialLink[]
      )
    : defaultSocialLinks;
};

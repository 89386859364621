import { Media, MediaOverlay, Sticker } from '@canalplus/dive';
import type { ImageSize, Ratio } from '@canalplus/mycanal-commons';
import { DIMENSIONS } from '@canalplus/mycanal-commons';
import { TitleDisplayMode } from '@canalplus/sdk-hodor';
import type { ApiV2BroadcastChannel } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/live_grid/definitions';
import classNames from 'classnames/bind';
import type { JSX } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { REFRESH_PROGRESS_BAR_MS } from '../../../../constants/limits';
import { StrateMode } from '../../../../constants/strates';
import { getChannelCurrentLiveContent } from '../../../../helpers/contents/contents-helper';
import { useInvariantSelector } from '../../../../helpers/hooks/useInvariantSelector';
import { useIsTvDevice } from '../../../../helpers/hooks/useIsTvDevice';
import { blackListedChannelsSelector } from '../../../../store/slices/application-selectors';
import Linker from '../../../Linker/Linker';
import DiveProgressBar from '../../../ProgressBar/DiveProgressBar';
import StartOverButton from '../../../StartOverButton/StartOverButton';
import ThumborMediaImage from '../../../ThumborMediaImage/ThumborMediaImage';
import styles from '../ContentRowTemplateItem.css';
import { HeadlineLiveTv } from './HeadlineLiveTv';

const cx = classNames.bind(styles);

export type ContentRowTemplateItemLiveTvProps = {
  channel: ApiV2BroadcastChannel;
  isHeadline?: boolean;
  imageSize?: ImageSize;
  ratio: Ratio;
  titleDisplayMode?: TitleDisplayMode;
};

export function ContentRowTemplateItemLiveTv({
  channel,
  titleDisplayMode = TitleDisplayMode.All,
  isHeadline = false,
  ratio,
  imageSize = 'normal',
}: ContentRowTemplateItemLiveTvProps): JSX.Element | null {
  const isTvDevice = useIsTvDevice();
  const blackListedChannels = useInvariantSelector(blackListedChannelsSelector);
  const updateLiveContentIntervalId = useRef<NodeJS.Timeout>(undefined);
  const [liveContent, setLiveContent] = useState(
    getChannelCurrentLiveContent(channel)
  );

  const updateLiveContent = useCallback(() => {
    const newLiveContent = getChannelCurrentLiveContent(channel);
    setLiveContent(newLiveContent);
  }, [channel]);

  useEffect(() => {
    updateLiveContentIntervalId.current = setInterval(() => {
      updateLiveContent();
    }, REFRESH_PROGRESS_BAR_MS);

    return () => {
      if (updateLiveContentIntervalId.current) {
        clearInterval(updateLiveContentIntervalId.current);
      }
    };
  }, [updateLiveContent]);

  // Check if channel is banned or content is not on air to delete obsolete card
  if (
    !liveContent ||
    (channel.epgID && blackListedChannels?.includes(channel.epgID))
  ) {
    return null;
  }

  const {
    URLImage,
    URLImageForDarkMode = URLImage,
    URLLogoChannel,
    startTime,
    endTime,
    altImage,
    altLogoChannel,
  } = liveContent;

  const isTitleVisible = titleDisplayMode !== TitleDisplayMode.None;
  const title = isTitleVisible ? liveContent.title : '';
  const subtitle =
    isTitleVisible && titleDisplayMode !== TitleDisplayMode.TitleOnly
      ? liveContent.subtitle
      : '';
  const image = {
    default: URLImageForDarkMode,
  };

  const onGoing = startTime ? { startTime, endTime } : undefined;

  const dimensions = (DIMENSIONS as any)[ratio][imageSize];

  const mediaCard = (
    <Media
      aspectRatio={ratio}
      aria-label={liveContent.title}
      image={
        URLImage ? (
          <ThumborMediaImage
            dimensions={dimensions}
            url={image.default}
            alt={altImage}
          />
        ) : undefined
      }
      overlay={
        <MediaOverlay
          title={title}
          subtitle={subtitle}
          sticker={<Sticker label="LIVE" variant="live" />}
          actionableElementBottom={
            <StartOverButton
              context={liveContent.onClick?.trackingContext}
              content={liveContent}
              title={liveContent?.title}
            />
          }
          logo={
            URLLogoChannel ? (
              <ThumborMediaImage
                dimensions={DIMENSIONS.LOGO_CHANNEL.large}
                url={URLLogoChannel}
                alt={altLogoChannel}
              />
            ) : undefined
          }
          progressBar={
            onGoing ? (
              <DiveProgressBar startTime={startTime} endTime={endTime} isLive />
            ) : undefined
          }
          data-tv-focusable={true}
        />
      }
    />
  );

  const itemClassnames = cx('contentRowTemplateItem', {
    'contentRowTemplateItem--logoTyped':
      titleDisplayMode === TitleDisplayMode.LogoTyped,
    'contentRowTemplateItem--liveTv': onGoing && isTvDevice,
  });

  const className = isHeadline
    ? styles.contentRowTemplateItem__fullWidth
    : itemClassnames;

  return (
    <li className={className}>
      <Linker
        className={styles.contentRowTemplateItem__itemLink}
        data={{
          mainOnClick: liveContent.onClick,
          ...liveContent,
        }}
        title={title}
        aria-label={title}
        objKey={StrateMode.LiveTv}
      >
        {isHeadline && !isTvDevice ? (
          <HeadlineLiveTv
            title={title}
            subtitle={subtitle}
            summary={liveContent.summary}
          >
            {mediaCard}
          </HeadlineLiveTv>
        ) : (
          mediaCard
        )}
      </Linker>
    </li>
  );
}

import type { ThumborQuality } from '@canalplus/dive';
import type { IAPICredentialsRaw } from '@canalplus/oneplayer-types';
import type { OfferLocation, OfferZone } from '@canalplus/sdk-core';
import type {
  ApiV2ArticleFragment,
  ApiV2ArticleFragmentContent,
} from '@dce-front/hodor-types/api/v2/article/definitions';
import { AuthorFragment } from '../Fragments/AuthorFragment/AuthorFragment';
import { MainTitleFragment } from '../Fragments/MainTitleFragment/MainTitleFragment';
import { SocialFragment } from '../Fragments/SocialFragment/SocialFragment';
import { getComponentByType } from '../helpers/component/component-helper';

import { FragmentContentType } from '@canalplus/sdk-hodor';
import type { JSX } from 'react';
import styles from './ArticleContent.module.css';

type ArticleContentProps = {
  playerCredentials?: IAPICredentialsRaw;
  fragments: ApiV2ArticleFragment[];
  langKey: string;
  offerLocation: OfferLocation;
  offerZone: OfferZone;
  qualityUserSettings: ThumborQuality;
  sharingURL: string;
  articleTitle: string;
};

export function ArticleContent({
  fragments,
  playerCredentials,
  langKey,
  offerLocation,
  offerZone,
  qualityUserSettings,
  sharingURL,
  articleTitle,
}: ArticleContentProps): JSX.Element {
  const {
    date,
    socialFragmentData,
    authorFragmentData,
    mainTitleFragmentData,
  } = fragments.reduce<{
    date?: ApiV2ArticleFragmentContent['date'];
    socialFragmentData?: ApiV2ArticleFragmentContent;
    authorFragmentData?: ApiV2ArticleFragmentContent;
    mainTitleFragmentData?: ApiV2ArticleFragmentContent;
  }>((acc, fragment) => {
    const { type } = fragment;

    if (type === FragmentContentType.PublicationDate && !acc.date) {
      return { ...acc, date: fragment.content?.date };
    }

    if (type === FragmentContentType.Sharing && !acc.socialFragmentData) {
      return { ...acc, socialFragmentData: fragment.content };
    }

    if (type === FragmentContentType.Author && !acc.authorFragmentData) {
      return { ...acc, authorFragmentData: fragment.content };
    }

    if (type === FragmentContentType.MainTitle && !acc.mainTitleFragmentData) {
      return { ...acc, mainTitleFragmentData: fragment.content };
    }

    return acc;
  }, {});

  const reducer = (
    acc: JSX.Element[],
    fragment: ApiV2ArticleFragment,
    key: React.Key
  ) => {
    const component = getComponentByType(
      fragment,
      key,
      offerLocation,
      offerZone,
      qualityUserSettings,
      playerCredentials
    );
    if (component) {
      acc.push(component);
    }
    return acc;
  };

  const content = fragments.reduce(reducer, []);
  return (
    <article className={styles.article}>
      <div className={styles.article__content}>
        <div className={styles.article__header}>
          {mainTitleFragmentData?.text && (
            <MainTitleFragment title={mainTitleFragmentData?.text} />
          )}
          {(authorFragmentData?.name || date) && (
            <div className={styles.article__author_share}>
              <AuthorFragment
                author={authorFragmentData?.name}
                date={date}
                langKey={langKey}
              />
              {socialFragmentData && (
                <SocialFragment
                  data={socialFragmentData}
                  sharingURL={sharingURL}
                  title={articleTitle}
                />
              )}
            </div>
          )}
        </div>
        {content}
      </div>
    </article>
  );
}

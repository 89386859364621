import { MediaFooter } from '@canalplus/dive';
import type { MediaFooterProps } from '@canalplus/dive/dist/components/Media/MediaFooter/MediaFooter.types';
import { TitleDisplayMode } from '@canalplus/sdk-hodor';
import classNames from 'classnames/bind';
import type { JSX } from 'react';
import styles from './MediaFooterWrapper.css';

const cx = classNames.bind(styles);

type MediaFooterWrapperProps = MediaFooterProps & {
  titleDisplayMode?: TitleDisplayMode;
};

export function MediaFooterWrapper({
  titleDisplayMode,
  ...mediaFooterProps
}: MediaFooterWrapperProps): JSX.Element {
  return (
    <div
      className={cx('mediaFooterWrapper', {
        'mediaFooterWrapper--twolines':
          titleDisplayMode === TitleDisplayMode.TitleOnly &&
          !mediaFooterProps.description,
        'mediaFooterWrapper--threelines':
          titleDisplayMode === TitleDisplayMode.All &&
          !mediaFooterProps.description,
      })}
    >
      <MediaFooter {...mediaFooterProps} />
    </div>
  );
}

import type { IStoreBinder, Middleware } from '@canalplus/one-navigation';
import { debounce } from 'es-toolkit';
import { isPointerVisible } from '../binder/pointer';
import { POINTER_HORIZONTAL_SCROLL_DELAY } from './constants';
import {
  LAYER_IMMERSIVE,
  LAYER_IMMERSIVE_MOREINFOS,
  LAYER_PAGE,
} from './layers';

/** In rem (120px) */
const SECURITY_MARGIN = 7.5;

/** In rem (90px) */
const SCROLLER_MARGIN = 5.625;

/** In rem (160px) */
export const HEADING_HEIGHT = 10;

export type FocusHandler = (binder: IStoreBinder, focused: HTMLElement) => void;

let viewportRatioRem: number;
let securityMarginCached: number;
let scrollerMarginCached: number;
let headingHeightCached: number;

const getViewportRatioRem = (): number => {
  if (viewportRatioRem !== undefined) {
    return viewportRatioRem;
  }

  // calculate ratio rem unit from fontSize html
  const htmlElem = document.getElementsByTagName('html')[0];
  if (htmlElem) {
    viewportRatioRem =
      parseFloat(window.getComputedStyle(htmlElem).fontSize) || 1;
  }
  return viewportRatioRem;
};

const getSecurityMargin = (): number => {
  if (securityMarginCached !== undefined) {
    return securityMarginCached;
  }

  const ratioRem = getViewportRatioRem();
  securityMarginCached = SECURITY_MARGIN * ratioRem;

  return securityMarginCached;
};

const getScrollerMargin = (): number => {
  if (scrollerMarginCached !== undefined) {
    return scrollerMarginCached;
  }

  const ratioRem = getViewportRatioRem();
  scrollerMarginCached = SCROLLER_MARGIN * ratioRem;

  return scrollerMarginCached;
};

const getHeadingHeight = (): number => {
  if (headingHeightCached !== undefined) {
    return headingHeightCached;
  }

  const ratioRem = getViewportRatioRem();
  headingHeightCached = HEADING_HEIGHT * ratioRem;

  return headingHeightCached;
};

const multiplyWithViewportRatioRem = (value: number): number => {
  const ratioRem = getViewportRatioRem();
  return value * ratioRem;
};

/**
 * A type that maps window properties to HTMLElement interface property so we can use same
 * scrolling code on both window and HTMLElement
 */
export type WindowEnhanced = {
  scrollTop: number;
  scrollTo: (x: number, y: number) => void;
  scrollBy: (x: number, y: number) => void;
  getBoundingClientRect: () => DOMRect;
};

/**
 * Creates a window enhanced object
 * @returns A window enhanced type on client, a stub on server
 */
function getWindowEnhanced(): WindowEnhanced {
  if (typeof window === 'undefined') {
    return {
      scrollTop: 0,
      scrollTo() {},
      scrollBy() {},
      getBoundingClientRect() {
        return new DOMRect(0, 0, 0, 0);
      },
    };
  }

  return {
    get scrollTop() {
      return window.scrollY;
    },
    scrollTo(x, y) {
      window.scrollTo(x, y);
    },
    scrollBy(x, y) {
      window.scrollBy(x, y);
    },
    getBoundingClientRect() {
      return new DOMRect(0, 0, window.innerWidth, window.innerHeight);
    },
  };
}

/**
 * Retrieve the immersive element
 * @returns Immersive element
 */
function getImmersive(): HTMLElement | WindowEnhanced {
  const immersiveEl: HTMLElement | null = document.getElementById('immersive');

  if (!immersiveEl) {
    throw new Error(`Cannot find Immersive element`);
  }

  if (immersiveEl.style.display === 'none') {
    return getWindowEnhanced();
  }

  return immersiveEl;
}

function getModal(): HTMLElement | WindowEnhanced {
  const modalEl = document.getElementById('modal-scroll-container');

  if (!modalEl) {
    throw new Error('Cannot find Modal element');
  }

  return modalEl;
}

/**
 * Gets the vertical scrolling container for a given layer
 * @param layer Layer to scroll into
 * @returns Scrolling container
 */
function getLayerScrollContainer(
  layer: number
): WindowEnhanced | HTMLElement | null {
  switch (layer) {
    case LAYER_PAGE:
      return getWindowEnhanced();
    case LAYER_IMMERSIVE:
      return getImmersive();
    case LAYER_IMMERSIVE_MOREINFOS:
      return document.getElementById('modal_moreinfos')?.parentElement || null;
    default:
      return null;
  }
}

/**
 * Scrolls immersive container to top
 */
function immersiveScrollTop(): void {
  getImmersive().scrollTop = 0;
}

/**
 * Simply scrolls window to top
 */
export function pageScrollTop(): void {
  if (isPointerVisible()) {
    return;
  }

  window.scrollTo(0, 0);
}

export function scrollTop(binder: IStoreBinder): void {
  if (isPointerVisible()) {
    return;
  }
  if (binder.el.closest('#immersive')) {
    immersiveScrollTop();
  } else {
    pageScrollTop();
  }
}

export function scrollModalTop(binder: IStoreBinder): void {
  if (binder.el.closest('#modal-scroll-container')) {
    getModal().scrollTop = 0;
  }
}

/**
 * Center a binder on screen
 * @param binder Binder to center
 */
export function centerBinder(binder: IStoreBinder | HTMLElement): void {
  if (isPointerVisible()) {
    return;
  }
  const bounds = binder.getBoundingClientRect();
  const center = bounds.top + bounds.height / 2;
  const targetCenter = window.innerHeight / 2;

  window.scrollBy(0, center - targetCenter);
}

/**
 * Scroll to a binder to bounds
 * @param binder Binder to align on
 */
export function alignBinderToTop(binder: IStoreBinder): void {
  if (isPointerVisible()) {
    return;
  }
  const scrollContainer = getLayerScrollContainer(binder.layerId);

  if (!scrollContainer) {
    return;
  }

  const bounds = binder.getBoundingClientRect();
  const targetY = bounds.top + scrollContainer.scrollTop;

  if (scrollContainer.scrollTop < targetY) {
    scrollContainer.scrollTo(0, targetY);
  }
}

/**
 * Handle vertical window scroll to ensure focused element is in security bounds
 * @param _binder Unused
 * @param focused Focused element
 * @param options Options to customize scroll behavior
 */
function scrollFocusedIntoPageViewport(
  _binder: IStoreBinder,
  focused: HTMLElement,
  options?: ScrollOptions
): void {
  // TODO one-navigation: maybe find a way to cache gbdr for the duration of a focus call
  const bounds = focused.getBoundingClientRect();
  // TODO one-navigation: this may be cached
  const screen = document.body.getBoundingClientRect();

  // Security margins
  const securityMargin = getSecurityMargin();
  const { margins = {} } = options || {};
  const { top, bottom } = margins;
  const securityMarginTop = top
    ? multiplyWithViewportRatioRem(top)
    : securityMargin;
  const securityMarginBottom = bottom
    ? multiplyWithViewportRatioRem(bottom)
    : securityMargin;

  // TODO one-navigation: this logic may be shared with immersive container once we implement
  // layered binders and immersive navigation
  const topPoint = bounds.top - securityMarginTop;
  const bottomPoint = bounds.bottom + securityMarginBottom;

  // When pointer is visible it should not be able to scroll vertically but still pass through scrollFocusedIntoHorizontalList
  if (isPointerVisible()) {
    return;
  }
  if (topPoint < 0) {
    window.scrollBy(0, topPoint);
  } else if (bottomPoint > screen.height) {
    window.scrollBy(0, -(screen.height - bottomPoint));
  }
}

/**
 * Handle vertical window scroll to ensure focused element is in security bounds
 * @param _binder Unused
 * @param focused Focused element
 */
function scrollFocusedIntoImmersiveViewport(
  _binder: IStoreBinder,
  focused: HTMLElement
): void {
  const immersive = getImmersive();
  const minTopPoint =
    document.getElementById('tabs-anchor')?.getBoundingClientRect()?.top || 0;
  // When pointer is visible it should not be able to scroll vertically but still pass through scrollFocusedIntoHorizontalList
  if (isPointerVisible()) {
    return;
  }
  // Force scroll to tabs when we are above
  if (immersive.scrollTop < minTopPoint) {
    immersive.scrollTo(0, minTopPoint);
    return;
  }

  // TODO one-navigation: logic is pretty similar to window vertical scrolling, maybe find a way to abstract this
  const bounds = focused.getBoundingClientRect();
  // TODO one-navigation: this may be cached
  const screen = immersive.getBoundingClientRect();
  // TODO one-navigation: this logic may be shared with immersive container once we implement
  // layered binders and immersive navigation
  const securityMargin = getSecurityMargin();
  const topPoint = Math.max(bounds.top - securityMargin, minTopPoint);
  const bottomPoint = bounds.bottom + securityMargin;
  if (topPoint < 0) {
    immersive.scrollBy(0, topPoint);
  } else if (bottomPoint > screen.height) {
    immersive.scrollBy(0, -(screen.height - bottomPoint));
  }
}

function scrollFocusedIntoMoreInfosViewport(
  _binder: IStoreBinder,
  focused: HTMLElement
): void {
  const moreInfos = document.getElementById('modal_moreinfos');
  // When pointer is visible it should not be able to scroll vertically but still pass through scrollFocusedIntoHorizontalList
  if (isPointerVisible()) {
    return;
  }
  if (!moreInfos) {
    return;
  }

  // TODO one-navigation: logic is pretty similar to window vertical scrolling, maybe find a way to abstract this
  const bounds = focused.getBoundingClientRect();
  // TODO one-navigation: this may be cached
  const screen = document.body.getBoundingClientRect();
  // TODO one-navigation: this logic may be shared with immersive container once we implement
  // layered binders and immersive navigation

  const headingHeight = getHeadingHeight();
  const securityMargin = getSecurityMargin();
  const topPoint = bounds.top - securityMargin - headingHeight;
  const bottomPoint = bounds.bottom + securityMargin;
  if (topPoint < 0) {
    moreInfos.scrollBy(0, topPoint);
  } else if (bottomPoint > screen.height) {
    moreInfos.scrollBy(0, -(screen.height - bottomPoint));
  }
}

// @TODO one-navigation : use this scroll factory for all vertical containers
export function scrollFocusedIntoVerticalScrollContainer(
  selector: string,
  options?: ScrollOptions
) {
  return (_binder: IStoreBinder, focused: HTMLElement): void => {
    const scrollContainer = document.querySelector(selector);

    if (isPointerVisible()) {
      return;
    }

    if (!scrollContainer) {
      return;
    }

    // TODO one-navigation: logic is pretty similar to window vertical scrolling, maybe find a way to abstract this
    const bounds = focused.getBoundingClientRect();
    // TODO one-navigation: this may be cached
    const screen = document.body.getBoundingClientRect();

    // Security margins
    const securityMargin = getSecurityMargin();
    const { margins = {} } = options || {};
    const { top, bottom } = margins;
    const securityMarginTop = top
      ? multiplyWithViewportRatioRem(top)
      : securityMargin;
    const securityMarginBottom = bottom
      ? multiplyWithViewportRatioRem(bottom)
      : securityMargin;

    // TODO one-navigation: this logic may be shared with immersive container once we implement
    // layered binders and immersive navigation
    const topPoint = bounds.top - securityMarginTop;
    const bottomPoint = bounds.bottom + securityMarginBottom;
    if (topPoint < 0) {
      scrollContainer.scrollBy(0, topPoint);
    } else if (bottomPoint > screen.height) {
      scrollContainer.scrollBy(0, -(screen.height - bottomPoint));
    }
  };
}

export type ScrollOptions = {
  /**
   * Security margins to keep when scrolling `(in rem)`
   * @example { top: 2, bottom: 2.5, left: 1, right: 0 }
   */
  margins?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
};

function getVerticalScrollContainer(element: HTMLElement): Element | undefined {
  return element.closest('#immersive, #modal_moreinfos') || undefined;
}

export function snapToTop(binder: IStoreBinder): undefined {
  if (isPointerVisible()) {
    return;
  }
  const bounds = binder.el.getBoundingClientRect();
  const scrollContainer = getVerticalScrollContainer(binder.el);

  if (scrollContainer) {
    scrollContainer.scrollTo({
      top: scrollContainer.scrollTop + bounds.top - 24,
    });
  } else {
    window.scrollTo({ top: Math.floor(window.scrollY + bounds.top - 24) });
  }
}

/**
 * Scroll focused element into vertical viewport
 * @param options Options to customize scroll behavior
 * @returns A scroll handler
 */
export function scrollFocusedIntoVerticalViewport(options?: ScrollOptions) {
  /**
   * Scroll an element into vertical viewport if needed. Operate a switch on the current layer to scroll the appropriate container
   * @param binder current binder
   * @param focused current focused element
   */
  return (binder: IStoreBinder, focused: HTMLElement): void => {
    switch (binder.layerId) {
      case LAYER_PAGE:
        scrollFocusedIntoPageViewport(binder, focused, options);
        break;
      case LAYER_IMMERSIVE:
        scrollFocusedIntoImmersiveViewport(binder, focused);
        break;
      case LAYER_IMMERSIVE_MOREINFOS:
        scrollFocusedIntoMoreInfosViewport(binder, focused);
        break;
      default:
        break;
    }
  };
}

/**
 * Creates a scroll handler with given margin
 * @param selector Selector to find horizontal list
 * @param marginLeft Left margin to keep when scrolling
 * @param marginRight Right margin to keep when scrolling
 * @returns A scroll handler
 */
export function scrollFocusedIntoHorizontalList(
  selector = '.HorizontalList',
  marginLeft?: number,
  marginRight?: number
) {
  /**
   * Handles horizontal scroll for scrolling templates in row (based on the presence of HorizontalList component)
   * @param binder Focused binder
   * @param focused Focused element
   */

  // TODO one-navigation: maybe find a way to cache gbdr for the duration of a focus call
  const handleScroll = (
    binder: IStoreBinder,
    focused: HTMLElement,
    marginLeftFinal: number,
    marginRightFinal: number
  ) => {
    const { left: focusedLeft, width: focusedWidth } =
      focused.getBoundingClientRect();

    // TODO one-navigation: this may be cached
    const { left: parentLeft, width: parentWidth } =
      binder.el.getBoundingClientRect();

    const horizontalList = binder.el.querySelector(selector);

    if (horizontalList) {
      const rightPoint = focusedWidth + focusedLeft + marginRightFinal;
      const leftPoint = focusedLeft - marginLeftFinal;

      if (rightPoint > parentWidth) {
        horizontalList.scrollBy(rightPoint - parentWidth, 0);
      } else if (leftPoint < parentLeft) {
        horizontalList.scrollBy(leftPoint - parentLeft, 0);
      }
    }
  };

  // Debounce the handleScroll function with dynamic delay
  const debouncedScroll = debounce(
    handleScroll,
    POINTER_HORIZONTAL_SCROLL_DELAY
  );
  return (binder: IStoreBinder, focused: HTMLElement): void => {
    const marginLeftFinal =
      marginLeft === undefined
        ? getScrollerMargin()
        : multiplyWithViewportRatioRem(marginLeft);
    const marginRightFinal =
      marginRight === undefined
        ? getScrollerMargin()
        : multiplyWithViewportRatioRem(marginRight);

    if (isPointerVisible()) {
      debouncedScroll(binder, focused, marginLeftFinal, marginRightFinal);
    } else {
      handleScroll(binder, focused, marginLeftFinal, marginRightFinal);
    }
  };
}

/**
 * A scroll handler to manage the scroll to top for ContentGrid (page context only)
 */
export const scrollToTopOnFirstLineOfContentGrid = () => {
  /**
   * @param binder Focused binder
   * @param focused Focused element
   * It will check if the element is on the top of ContentGrid.
   * We check if top property of focused element is equal to top property of the first element of binder
   * If they're equal, that means that we are on first line of ContentGrid so we can call pageScrollTop function
   */
  return (binder: IStoreBinder, focused: HTMLElement): void => {
    const topFirstBinderElement = binder
      .getElements()?.[0]
      ?.getBoundingClientRect().top;

    const topFocusedElement = focused.getBoundingClientRect().top;

    if (topFirstBinderElement === topFocusedElement) {
      pageScrollTop();
    }
  };
};

/**
 * One-navigation middleware that handles scroll to focused element
 * @param handlers Array of functions that will be called with focused binder & elements
 */
export const scroll: Middleware<FocusHandler[]> = (handlers) => (binder) => ({
  focused(element) {
    // TODO one-navigation: maybe better types for hooks to allow HTMLElement by default
    if (!(element instanceof HTMLElement)) {
      return;
    }

    handlers.forEach((handler) => handler(binder, element));
  },
});

import type { DirectionKey } from '../constants/keys';
import { spatial } from '../middleware/spatial';
import type {
  IElement,
  IStore,
  IStoreBinder,
  IStoreLayer,
  MiddlewareDestroyedHook,
  MiddlewareFactory,
  MiddlewareFocusedHook,
  MiddlewareFocusHook,
  OriginElement,
} from './types';

export type StoreBinderInit = {
  el: HTMLElement;
  selector?: string;
  enabled?: boolean;
  middleware?: MiddlewareFactory[];
  layer?: number;
  forceFocusOnMount?: boolean;
  binderId?: string;
};

export const DEFAULT_MIDDLEWARE = [spatial()];

export class StoreBinder implements IElement, IStoreBinder {
  el: HTMLElement;

  /**
   * id of this binder
   *
   * @public
   * @readonly
   */
  id?: string;

  selector: string;

  enabled: boolean;

  /**
   * Layer of this binder
   *
   * @public
   * @readonly
   */
  layerId: number;

  /**
   * Focusable elements within this binder. Used as a cache when calling `findNextFocusable()`
   *
   * @private
   */
  elements: HTMLElement[] = [];

  /**
   * Dirty flag let the binder know if it should query DOM on next call to `getElements()`
   *
   * @private
   */
  dirty: boolean = true;

  /**
   * Reference to current middleware, mainly used for caching purpose
   *
   * @private
   */
  middleware: MiddlewareFactory[] | null = null;

  /**
   * Hooks to be called when trying to focus from outside this binder
   *
   * @private
   */
  focusEnter: MiddlewareFocusHook[] = [];

  /**
   * Hooks to be called when trying to focus from within this binder
   *
   * @private
   */
  focusWithin: MiddlewareFocusHook[] = [];

  /**
   * Hooks to be called when a focusable element within this binder is found
   *
   * @private
   */
  focused: MiddlewareFocusedHook[] = [];

  /**
   * Hooks to be called when binder is destroyed
   *
   * @private
   */
  destroyed: MiddlewareDestroyedHook[] = [];

  /**
   * This prop force the focus on mount for this binder
   *
   * @private
   */
  forceFocusOnMount = false;

  /**
   * Store attached to this binder
   *
   * @public
   * @readonly
   */
  store: IStore;

  /**
   * Layer attached to this binder
   *
   * @public
   * @readonly
   */
  layer: IStoreLayer;

  constructor(store: IStore, layer: IStoreLayer, options: StoreBinderInit) {
    this.store = store;
    this.layer = layer;
    this.el = options.el;
    this.selector = options.selector || store.selector;
    this.enabled = options.enabled !== false;
    this.layerId = options.layer || 0;
    this.forceFocusOnMount = options.forceFocusOnMount || false;
    this.id = options.binderId;

    this.setMiddleware(options.middleware || DEFAULT_MIDDLEWARE);
  }

  /**
   * Sets middleware for this binder. Override any existing middleware
   *
   * @package
   *
   * @param middleware Middleware factories to apply
   */
  setMiddleware(middleware: MiddlewareFactory[]): void {
    // Do not reinitialize middleware if reference is the same
    if (middleware === this.middleware) {
      return;
    }

    this.middleware = middleware;

    // Empty existing hooks
    this.focusEnter = [];
    this.focusWithin = [];
    this.focused = [];

    // Instantiate each middleware and push hooks to array
    for (let i = 0; i < this.middleware.length; i += 1) {
      const { focusEnter, focusWithin, focused, destroyed } =
        this.middleware[i]?.(this) || {};

      if (focusEnter) {
        this.focusEnter.push(focusEnter);
      }

      if (focusWithin) {
        this.focusWithin.push(focusWithin);
      }

      if (focused) {
        this.focused.push(focused);
      }

      if (destroyed) {
        this.destroyed.push(destroyed);
      }
    }
  }

  getElements(): HTMLElement[] {
    if (this.dirty) {
      this.elements = Array.from(this.el.querySelectorAll(this.selector));
      this.dirty = false;
    }

    return this.elements;
  }

  getFirstElement(): HTMLElement | undefined {
    return this.getElements()[0];
  }

  getBoundingClientRect(): DOMRect {
    return this.el.getBoundingClientRect();
  }

  /**
   * Try to find next focusable element from direction & origin
   *
   * @package
   *
   * @param direction A direction key
   * @param origin Element we navigate from
   * @param isWithin Should be `false` if we enter the binder from another binder, `true` if navigating within
   *    the binder.
   * @returns A focusable `HTMLElement` if found, undefined otherwise
   */
  findNextFocusable(
    direction: DirectionKey,
    origin: OriginElement,
    isWithin?: boolean
  ): HTMLElement | undefined {
    // Use focusWithin or focusEnter depending on what was passed
    const focusHooks = isWithin ? this.focusWithin : this.focusEnter;

    let element: HTMLElement | undefined;

    for (let i = 0; i < focusHooks.length; i += 1) {
      element = focusHooks[i]?.(direction, origin);

      // If element was found exit main loop
      if (element) {
        break;
      }
    }

    // Set dirty flag to true so that next call to this method returns fresh elements
    this.dirty = true;

    return element;
  }

  /**
   * Invoke focused hooks of this binder
   *
   * @param element Focused element
   */
  callFocusedHook(element: HTMLElement): void {
    // Execute all focused hooks
    for (let j = 0; j < this.focused.length; j += 1) {
      this.focused[j]?.(element);
    }

    this.dirty = true;
  }

  /**
   * Invoke destroyed hooks of this binder
   */
  callDestroyHook(): void {
    for (let i = 0; i < this.destroyed.length; i += 1) {
      this.destroyed[i]?.();
    }
  }

  /**
   * Focus an element at a specific index
   * @param index Element index to be focused
   */
  focusByIndex(index: number): void {
    const elementToBeFocused = this.getElements()[index];
    if (elementToBeFocused) {
      this.layer.focus(this, elementToBeFocused);
    }
    this.dirty = true;
  }

  /**
   * Focus an element matching a specific predicate
   * @param predicate Element predicate
   */
  focusBy(predicate: (element: HTMLElement) => boolean): void {
    const elementToBeFocused = this.getElements().find(predicate);
    if (elementToBeFocused) {
      this.layer.focus(this, elementToBeFocused);
    }
    this.dirty = true;
  }
}

import { SafetyCodeContext } from '@canalplus/types-acm';
import type { OneDiscoveryTranslations } from './types';

const LOCALE_PL_PL: OneDiscoveryTranslations = {
  A11y: {
    disableAnimationsAutoplay: 'Wyłącz animacje',
    enableAnimationsAutoplay: 'Włącz animacje',
    lastCategory: 'Kontynuuj nawigację, aby przeglądać ostatnią kategorię',
    nextCategory: 'Następna kategoria',
    nextCategoryAriaLabel:
      'Znajdujesz się w kategorii "%{category}". Aby przejść do następnej kategorii, kliknij Enter',
    skipToMainContent: 'Przejdź do głównej zawartości',
    skipToMenu: 'Przejdź do menu',
    skipToSearch: 'przejdź do wyszukiwania',
    headerLogo: '%{appName} - strona domowa',
    navigationMenu: 'Główne menu nawigacji',
  },
  ActionBar: {
    twitterShare: 'Obejrzyj %{movie} na #CANAL',
    twitterShareSite: 'nowycanalplus',
  },
  Alert: {
    attention: 'Uwaga',
    error: 'Błąd',
    information: 'Informacja',
    success: 'Sukces',
    ignore: 'Ignorować',
  },
  AudioLanguage: {
    Icon: {
      vf: {
        title: 'Wersja polska',
        description: 'Wersja francuska',
      },
      vost_vf: {
        title: 'Ścieżki dźwiękowe i napisy',
        description: 'Wersja oryginalna z polskimi napisami',
      },
      vost: {
        title: 'Wersja oryginalna z polskimi napisami',
        description: 'Wersja oryginalna z napisami',
      },
    },
  },
  Authentication: {
    login: 'Zaloguj się',
    logout: 'Wyloguj się',
  },
  AvailabilityLabel: {
    until: 'Do',
    promotion: 'Promocja',
    minimumPrice: 'Już od',
    free: 'W ofercie',
  },
  Card: {
    withSeason: 'Z sezonem',
    notInterested: 'Nie interesuje mnie to',
    removeFromList: 'Usuń z listy',
    season: 'Sezon',
    subscribeLabel: 'Subskrybuj',
  },
  Common: {
    validate: 'Zatwierdź',
    modify: 'Zmień',
    delete: 'Usuń',
    yes: 'Tak',
    no: 'Nie',
  },
  Contact: {
    title: 'Weryfikacja',
    subtitle: 'Wybierz sposób otrzymania kodu weryfikacyjnego',
    type: {
      EMAIL: 'E-mail',
      PHONE: 'SMS',
    },
  },
  CreativeMedia: {
    ActionBar: {
      twitterShare: 'Oglądaj %{movie} na #CANAL+',
    },
    MediaAuthor: {
      published: 'Opublikowany ',
      author: 'przez %{author} ',
      date: '%{date} ',
    },
    Sharing: {
      title: 'Udostępnij na',
    },
    Card: {
      article: 'Artykuł',
    },
    Icon: {
      externalLink: 'Zewnętrzny link',
    },
    RelatedArticles: {
      heading: 'Powiązane artykuły',
    },
  },
  ParentalRating: {
    rating1: 'Bez ograniczeń wiekowych',
    rating2: 'Powyżej 7 lat',
    rating3: 'Powyżej 12 lat',
    rating4: 'Powyżej 16 lat',
    rating5: 'Powyżej 18 lat',
  },
  Accessibility: {
    closedCaptioning: 'Napisy dla niesłyszących i niedosłyszących',
    audioDescription: 'Audiodeskrypcja',
  },
  Date: {
    at: 'o',
    timeSeparator: ':',
    broadcastToday: 'dzisiaj o %{hours}:%{minutes}',
    broadcastTodayLabel: '%{hours}:%{minutes}',
    broadcastTomorrow: 'jutro o %{hours}:%{minutes}',
    broadcastTomorrowLabel: 'Jutro %{hours}:%{minutes}',
    broadcastYesterday: 'wczoraj o %{hours}:%{minutes}',
    broadcastFuture: '%{date} o %{hours}:%{minutes}',
    broadcastFutureLabel: '%{date} %{hours}:%{minutes}',
    TVODduration: 'Dostępny do %{date}',
    availabilityText: 'Dostępne do %{date}',
  },
  DaySelector: {
    error: 'Wystąpił błąd serwera. Prosimy spróbować później',
  },
  DeleteAccount: {
    title: 'Supprimer mon compte',
  },
  Detail: {
    season: 'Sezon',
  },
  DownloadToGo: {
    available:
      'Po zakupie lub wypożyczeniu tego materiału wideo będziesz mógł go pobrać. Dzięki tej funkcjonalności będziesz mógł oglądać dany materiał wideo w trybie offline oraz będzie on miał dobrą jakość nawet przy słabym transferze',
    downloads: 'Ściągniete pliki wideo',
    noContent: 'Brak ściągniętych plików wideo',
    notAvailable:
      'Na żądanie producenta pobieranie tego programu jest zablokowane',
    sizeConsumption: 'Użyto %{used} z %{total}',
  },
  DownloadToGoMacOsModal: {
    title: 'Zainstaluj aplikację CANAL+, aby pobrać tę treść',
    appNotAvailable:
      'Oglądaj swoje treści offline w aplikacji myCANAL, dostępnej na MacOS, iOS, Android i Windows.',
    downloadAppInfos:
      'Aby pobrać swoje treści, skorzystaj z aplikacji myCANAL macOS. Aplikacja dostępna w Mac App Store od macOS Catalina 10.15.4',
    upgradeOs:
      'Aby korzystać z aplikacji myCANAL macOS, należy zaktualizować wersję systemu operacyjnego',
    closeBtn: 'Zamknij',
    downloadAppBtn: 'Przejdź do App Store',
    appStoreAlt: 'Pobierz z Appstore',
    microsoftStoreAlt: 'Pobierz z Microsoft',
    playStoreAlt: 'Pobierz z Google Play',
  },
  EpisodesContainer: {
    switchEpisodesLabel: 'Streszczenie odcinków',
    seeMoreButton: 'Zobacz więcej',
  },
  ErrorMessages: {
    technicalIssue: 'Wystąpił problem techniczny. Prosimy o odświeżenie strony',
    orderMessage: 'Wystąpił problem z realizacją Twojego zamówienia',
    saleOrderMessage:
      'Wystąpił problem techniczny. Twoje zamówienie nie zostało zarejestrowane, prosimy o usunięcie tego zamówienia i spróbowanie ponownie później.',
    mandatoryField:
      'Twoje zamówienie nie zostało zarejestrowane. Pola miasto, kod pocztowy i adres należy obowiązkowo wypełnić.',
    invalidCivility:
      'Twoje zamówienie nie zostało zarejestrowane. Pole Zwrot grzecznościowy zostało wypełnione niepoprawnie.',
    hibernateWrite:
      'Twoje zamówienie nie zostało zarejestrowane. Wartości w polach gmina i kod pocztowy nie istnieją w naszych bazach.',
    noPaymentMeansInfo:
      'Twój plik ma anomalię. Popraw swoją sytuację, kontaktując się z obsługą klienta.',
    noPaymentMeans: 'Brak środków na karcie płatniczej. Wejdź na',
    unpaid: 'Twoje zamówienie zawiera błąd. Wejdź na',
    thirdParty: 'Twoje zamówienie dotyczy osoby trzeciej. Wejdź na',
    warnBizBlockingThirdPayers: `Twój abonament jest opłacany przez stronę trzecią. W celu dokonania zakupu skontaktuj się z tą stroną trzecią.`,
    warnBizBlockingCaped: `Osiągnięto maksymalną liczbę zleceń autoryzowanych dla metody płatności za abonament CANAL.`,
    warnBizBlockingUnpaid: `Wystąpił problem z Twoją metodą płatności. Przed kontynuowaniem transakcji sprawdź swój folder w dziale obsługi klienta lub w strefie klienta.`,
    warnBizNoBill: `Płatność kartą kredytową nie jest dostępna. Aby zarejestrować inny rodzaj płatności, przejdź do swojej strefy klienta lub skontaktuj się z działem obsługi klienta.`,
    errSystemError: `Usługa jest obecnie niedostępna. Spróbuj ponownie później.`,
    errTokenIdentity: `Twoje zamówienie nie mogło zostać zrealizowane. Przed dokonaniem ponownej próby zaktualizuj warunki. Jeśli problem będzie się utrzymywał, skontaktuj się z działem obsługi klienta.`,
    errTokenIdentityPayment: `Twoje zamówienie nie mogło zostać zrealizowane. Przed dokonaniem ponownej próby zaktualizuj warunki. Jeśli problem będzie się utrzymywał, skontaktuj się z działem obsługi klienta.`,
    errOrderPaid: `Due to a technical problem, your order could not be completed. We're sorry for the inconvenience.
    You can make a complaint from your customer account or contact customer service.`,
    warnBizNoAlternatePaymentMode: `Płatność jest obecnie niedostępna z poziomu Twojej aplikacji. Przejdź do canalplus.com`,
    errNoSubscriber: `Twoje zamówienie nie mogło zostać zrealizowane. Ponów prośbę.`,
    errBizBillConfirmation: `Twoje zamówienie nie mogło zostać zrealizowane. Ponów prośbę.`,
    errBizBlockingVod: `Otrzymaliśmy Twoje żądanie zablokowania zleceń dotyczących treści CANAL VOD. Aby uzyskać dostęp do usługi, skontaktuj się z działem obsługi klienta.`,
    errOrderPaidPayment: `Due to a technical problem, your order could not be completed. We're sorry for the inconvenience.
    You can make a complaint from your customer account or contact customer service.`,
    errSystemErrorPayment: `Usługa jest obecnie niedostępna. Spróbuj ponownie później.`,
    errBizBlockingCaped: `Osiągnięto maksymalną liczbę zleceń autoryzowanych dla metody płatności za abonament CANAL.`,
    errBizBlockingNoBill: `Twoje zamówienie nie mogło zostać zrealizowane. Ponów prośbę.`,
    errInconsistentOrder: `Twoje zamówienie nie mogło zostać zrealizowane. Ponów prośbę.`,
    errOrderNotFound: `Twoje zamówienie nie mogło zostać zrealizowane. Ponów prośbę.`,
    errJwtToken: `Z powodu problemu technicznego płatność nie została przetworzona i Twoje zamówienie nie mogło zostać zrealizowane. Ponów prośbę.`,
    warnBizBlockingUnpaidPayment: `Wystąpił problem z Twoją metodą płatności. Przed kontynuowaniem transakcji sprawdź swój folder w dziale obsługi klienta lub w strefie klienta.`,
    errBillError: `Twoje zamówienie nie mogło zostać zrealizowane. Ponów prośbę.`,
    errStopVod: `Otrzymaliśmy Twoje żądanie zablokowania zleceń dotyczących treści CANAL VOD. Aby uzyskać dostęp do usługi, skontaktuj się z działem obsługi klienta.`,
    wrongCB:
      'Wprowadzone przez Ciebie dane są niepoprawne. Prosimy ponownie wpisać dane dotyczące płatności lub wybrać inną opcję zapłaty',
    wrongCBData: 'Niepoprawny numer karty. Spróbuj ponownie',
    wrongCBValidation: 'Nieprawidłowe dane karty',
    paymentMessageSecondPart: 'Aby zamówić program, użyj karty płatniczej.',
    genericErrorTitle: 'Wystąpił błąd',
    genericErrorTxt: 'Pracujemy nad rozwiązaniem tego problemu',
    invalidAddress: 'Niepoprawny adres',
    noContent: 'Żadne treści nie są dostępne w tej chwili',
    noVod: 'Wypożyczenia i zakupy nie są dozwolone dla tego konta klienta',
    retry: 'Spróbuj ponownie',
  },
  ErrorTemplate: {
    backToHome: 'Powrót na stronę główną',
    moreInfo: 'Więcej informacji',
  },
  ExternalServiceModal: {
    title: 'Aktywacja',
    streamInfos:
      'Aktywuj swoje konto %{channelName} aby odtworzyć tę treść w serwisie %{channelName}.',
    downloadInfos:
      'Aktywuj swoje konto %{channelName} aby pobrać ten plik wideo w serwisie %{channelName}.',
    btn: 'Aktywuj swoje konto %{channelName}',
    partnerActivation: {
      title: 'Aktywacja',
      streamInfos: `Aby grać w tę zawartość w aplikacji %{channelName}, należy aktywować konto %{channelName}. Wiadomość e-mail od CANAL+ zawierająca link aktywacyjny została wysłana do użytkownika. Możesz również przejść do sekcji "Subskrypcja" w swoim Espace Client web CANAL+, aby dokonać aktywacji.`,
      downloadInfos: `Aby pobrać w tę zawartość w aplikacji %{channelName}, należy aktywować konto %{channelName}. Wiadomość e-mail od CANAL+ zawierająca link aktywacyjny została wysłana do użytkownika. Możesz również przejść do sekcji "Subskrypcja" w swoim Espace Client web CANAL+, aby dokonać aktywacji.`,
      closeBtn: 'Zamknij',
    },
  },
  FieldErrorMessage: {
    cgu: 'Aby kontynuować, musisz zaakceptować Warunki użytkowania',
    email_login: 'Sprawdź format swojego maila',
    email_phone_login: 'Sprawdź format swojego maila i numeru telefonu',
    msd_number: 'Sprawdź numer karty lub dekodera',
    num_abo: 'Numer subskrypcji musi mieć długość 14 znaków alfanumerycznych',
    new_password: 'Twoje nowe hasło musi być inne niż obecne',
    new_password_too_recent: 'Twoje nowe hasło zostało użyte zbyt wcześnie',
    old_password: 'Proszę wpisać swoje aktualne hasło',
    password: 'Proszę sprawdzić swoje hasło',
    phone_email_login: 'Sprawdź format swojego maila i numeru telefonu',
    phone_login: 'Sprawdź format swojego numeru telefonu',
    resubscription_number:
      "Veuillez vérifier le pays d'abonnement et le numéro de réabonnement",
    subscription_number:
      "Veuillez vérifier le pays d'abonnement et le numéro de réabonnement",
    zip_code: 'Votre code postal doit être composé de 5 chiffres',
  },
  FunnelTvod: {
    addPromotionalCode: 'Usuń kod promocyjny',
    agree: 'Akceptuję',
    agreeTV: 'Akceptuję Warunki Ogólne Sprzedaży',
    CBDisabledAlert:
      'Aby sfinalizować zakup kartą kredytową, przejdź do witryny myCANAL. Następnie możesz znaleźć wszystkie swoje zakupy i wypożyczenia w sekcji "Moje filmy".',
    CBDisabledTitle: 'Płatność kartą kredytową niedostępna w telewizji',
    cgvAlert: 'Musisz zaakceptować ogólne warunki sprzedaży',
    cgvLabel: 'Ogólne warunki sprzedaży',
    cgvName: 'Ogólne warunki sprzedaży',
    close: 'Zamknij',
    deleteCBModal: {
      cancelBtn: 'Anuluj',
      confirmBtn: 'Potwierdzenie',
      label:
        'Czy na pewno chcesz usunąć rekord karty bankowej o numerze %{cardNumber}?',
      subLabel: '(usunięcie nie będzie miało wpływu na subskrypcję)',
    },
    deleteCBRegistered: 'Usuwanie zarejestrowanej karty bankowej',
    deleteCBRegisteredBtn: 'Usuń',
    insteadOf: 'zamiast',
    libCB1Shot: 'Płatność kartą bankową',
    promotionalCode: 'Kod promocyjny',
    reload: 'Odśwież',
    terms: 'Warunki Ogólne Sprzedaży',
    validate: 'Zatwierdź',
    withdrawal: 'Rezygnacja z abonamentu CANAL+',
    withdrawalAlert:
      'Aby uzyskać dostęp do usługi, musisz zrzec się prawa do odstąpienia od umowy',
    withdrawalName: 'Prawo do odstąpienia od umowy',
  },
  PrivacyManager: {
    acceptAll: 'Akceptuj wszystko',
    adsTitle: 'Spersonalizowana reklama',
    anonymous: 'Anonimowy',
    authenticatedCase:
      'Twój wybór będzie ważny na wszystkich mediach cyfrowych Groupe Canal+ podłączonych do Twojego konta CANAL+',
    confidentialityCGU:
      'Ustawienia plików cookies i Zasady i warunki użytkowania',
    refuseAll: 'Odrzuć wszystko',
    disabled: 'Nieaktywny',
    enabled: 'Aktywowany',
    generalInfo:
      'GROUPE CANAL+ przywiązuje największą wagę do zarządzania Państwa danymi osobowymi i umożliwia Państwu kontrolę ich wykorzystania poprzez poniższe ustawienia. \nTwoje dane są gromadzone w celu zagwarantowania optymalnego funkcjonowania aplikacji i spersonalizowania Twoich doświadczeń lub reklam',
    modal: {
      goBack: 'Powrót',
      saveAndClose: 'Zapisz i zamknij',
      title: 'Czy chcesz opuścić tę stronę?',
    },
    performanceTitle: 'Analiza wydajności',
    required: 'Wymagane',
    saveAndClose: 'Zapisz i zamknij',
    serviceTitle: 'Działanie usługi',
    title: 'Dotyczy Twoich danych',
  },

  GDPRConsent: {
    title: 'Dotyczy Twoich danych',
    generalInfo:
      'Grupa CANAL+, jej spółki zależne (w tym CANAL+ Polska S.A. i członkowie grupy Vivendi) oraz nasi partnerzy (listę znajdziesz poniżej) używają plików cookies i podobnych technologii do zarządzania usługami i ich ulepszania oraz dostosowywania reklam do Twoich preferencji.',
    updateChoices:
      'Możesz skonfigurować wybrane opcje według celu i zaktualizować je w dowolnym momencie w zakładce Ustawienia plików cookies.',
    authenticatedCase:
      'Jeśli jesteś zalogowany do swojego konta CANAL+, Twój wybór będzie dotyczył użytkowania i nawigacji aplikacji CANAL+ na każdym urządzeniu, z którego korzystasz.',
    unloggedCase:
      'Twój wybór będzie ważny tylko na aktualnie używanym nośniku cyfrowym. Jeśli połączysz się z kontem CANAL+, wybory dokonane w trybie połączenia będą miały pierwszeństwo.',
    learnMore:
      'Dowiedz się więcej i skonfiguruj wybrane ustawienia plików cookies. Możesz je aktualizować w dowolnym momencie w zakładce Ustawienia.',
    serviceTitle: 'Działanie usługi',
    serviceText:
      'Identyfikatory te zapewniają działanie usługi, umożliwiając uwierzytelnianie użytkowników, bezpieczeństwo oraz (anonimowy) nadzór techniczny nad usługą. Nie można wyłączać tego ustawienia.',
    performanceTitle: 'Analiza wydajności',
    performanceText:
      'Identyfikatory te są wykorzystywane do pomiaru wykorzystania i wydajności naszych usług, aby lepiej dostosować je do potrzeb użytkownika. Po wybraniu trybu "Anonimowy" identyfikatory te pozwalają nam mierzyć wykorzystanie i wydajność naszych usług w sposób całkowicie anonimowy, bez śledzenia.',
    adsTitle: 'Spersonalizowana reklama',
    adsText:
      'Identyfikatory te pozwalają nam, wraz z naszymi partnerami, oferować Państwu reklamy dostosowane do Państwa zainteresowań. W szczególności będziemy mogli: dostosować treści reklamowe do nawigacji Twojego terminala lub podanych przez Ciebie danych osobowych, w szczególności poprzez wykorzystanie funkcji targetowania (retargeting) w celu zaprezentowania Ci reklam lub kontaktów e-mailowych po skorzystaniu przez Ciebie z naszych usług; śledzić kolejną nawigację prowadzoną przez Twój terminal; dostosować prezentację usługi, do której prowadzi treść reklamowa; ograniczyć liczbę wyświetleń reklamy; obliczyć kwoty należne podmiotom z łańcucha dystrybucji reklam; zmierzyć skuteczność i wpływ kampanii reklamowej. Dezaktywując te identyfikatory, nie będziesz narażony na mniejszą liczbę reklam, ale na reklamy, które niekoniecznie odpowiadają Twoim zainteresowaniom.',
    enabled: 'Aktywna',
    anonymous: 'Anonimowy',
    disabled: 'Nieaktywna',
    required: 'Wymagane',
    refuseAll: 'Odrzuć wszystko',
    acceptAll: 'Akceptuj wszystko',
    saveAndClose: 'Zapisz i zamknij',
    confidentiality: 'Ustawienia plików cookies',
    makeYourChoice: 'Dokonaj wyboru:',
    noneTitle: 'Poufność',
    noneText:
      'Grupa Canal+ wykorzystuje identyfikatory pomiaru oglądalności, niezbędne do zarządzania jej usługami, do pomiaru wydajności jej usług i do analizy sekcji, stron i/lub treści, z którymi się zapoznała, w sposób całkowicie anonimowy i zagregowany oraz w wyłącznym celu sporządzenia, na jej wyłączne zlecenie, statystyk nie zawierających danych osobowych. Możesz sprzeciwić się używaniu tych anonimowych identyfikatorów pomiarowych i dezaktywować je poniżej.',
  },
  HDPicto: {
    Icon: {
      hd: {
        description: 'HD',
      },
      uhd: {
        description: 'UHD',
      },
    },
  },
  HorizontalPaging: {
    next: 'Następny',
    previous: 'Poprzedni',
  },
  Icon: {
    check: 'Potwierdzony',
    close: 'Zamknij',
    back: 'Wróć',
    playlistOn: 'Dodaj do swojej playlisty',
    playlistOff: 'Usuń z listy odtwarzania',
    download: 'Pobierz',
    live: 'Na żywo',
    loading: 'Ładowanie',
    play: 'Czytanie',
  },
  List: {
    selected: 'wybrany',
  },
  LiveGrid: {
    ariaLabel: 'Kanały na żywo',
    cards: {
      genericTitle: 'Informacja niedostępna',
      genericSubtitle: 'Inny',
    },
    favorites: {
      emptyStateHeading: 'Brak dodanych kanałów do Ulubionych',
      emptyStateText:
        'Wybierz swoje ulubione kanały aby móc je szybko znaleźć w „Ulubionych”',
      editButton: 'Zarządzaj kanałami',
      editButtonEmptyState: 'Dodaj',
    },
    editFavorites: {
      heading: 'Wybierz swoje ulubione kanały',
      headingMobile: 'Ulubione kanały',
      saveButton: 'Zatwierdź',
      loadErrorMessage: 'Ooops, coś poszło nie tak',
      loadErrorButton: 'Ponów',
      saveErrorMessage:
        'Wykryliśmy bład podczas zapisywania. Prosimy spróbować później.',
      closeErrorButton: 'Zamknij',
    },
    multiLive: {
      cardMultiLiveCallToAction: 'Włącz tryb multi-live',
      buttonLaunchMultiLive: 'Włącz moizaikę',
      fetchErrorMessage: 'Ups! Coś poszło nie tak',
      fetchErrorButton: 'Spróbuj ponownie',
    },
  },
  LiveTV: {
    allChannels: 'Wszystkie kanały',
  },
  LogoAvailabilities: {
    text: 'Dostępny',
  },
  MediaCardBody: {
    broadcastInfoLabel: 'Emisja: %{date} w %{channelName}',
    onGoingBroadcastInfoLabel: 'Oglądaj teraz na %{channelName}',
  },
  MediaCardHeader: {
    error: 'Wystąpił błąd serwera. Prosimy spróbować później.',
    prospect: 'Chcesz obejrzeć',
    broadcastingDate: 'Emisja: ',
    noMoreDiffusion: 'Ten program nie jest już nadawany',
    unavailableContent: 'Ta treść jest niedostępna',
    inAppMessageApple:
      'Aby obejrzeć ten program, prosimy o zmianę swojej oferty w AppStore',
    inAppMessageGoogle:
      'Aby obejrzeć ten program, prosimy o zmianę swojej oferty w Google Play Store',
    scrollerEpisodeText: 'Zobacz odcinki',
  },
  MetaTags: {
    title: ' | Oglądaj na żywo i na życzenie w CANAL+',
    tvodTitle: ' na życzenie i do pobrania CANAL+',
  },
  Mood: {
    disclaimer: 'Podziel się swoją opinią',
    dislike: 'To mi się nie podoba',
    neutral: 'Nie mam zdania',
    like: 'To mi się podoba',
    noopinion: 'Opinia',
  },
  MoodButton: {
    notAvailableRecoOff:
      'Jeśli chcesz mieć dostęp do tej funkcjonalności, aktywuj rekomendacje i personalizację w Ustawieniach.',
  },
  Sharing: {
    title: 'Udostępnij',
  },
  MyAccountTemplate: {
    cancel: 'Zrezygnuj',
    myAccount: 'Moje konto',
    myCanalAccount: 'Moje CANAL + konto',
    accessAccount: 'Uzyskaj dostęp do mojego konta klienta',
    modifyEmail: 'Zmień e-mail',
    modifyLabel: 'Zmień',
    modifyPwd: 'Zmień hasło',
    name: 'Nazwa',
    offer: 'Twoja oferta bez zobowiazań',
    personalInformation: 'Moje dane osobowe',
    subscription: 'Mój abonament',
    reactivateRightsInfo:
      'Jeśli Twoja lista kanałów jest nieaktualna, możesz odnowić swoje uprawnienia',
    subscriptionLabel: 'Abonament',
    updateRightsAction: 'Odnowić',
    updateRightsLabel: 'Aktualizacja uprawnień',
  },
  Navigation: {
    dropdownLabel: 'Więcej',
  },
  OfflineTemplate: {
    title: 'Problem z połączeniem',
    offlineDescription: 'Brak połączenia z internetem.',
    onlineDescription:
      'Wygląda na to, że znów jesteś online. Kliknij przycisk, aby odświeżyć stronę.',
    retry: 'Spróbuj ponownie',
    reload: 'Odśwież stronę',
  },
  PartnerSelection: {
    backIconTitle: 'Ikona powrotu',
  },
  PasswordInput: {
    numberCharacter: 'Minimum 8 znaków',
    lowerCaseUpperCaseCharacter:
      'Przynajmniej jedna wielka litera, jedna mała litera i jedna liczba',
    noMandatoryCharacter: "Nie zawiera następujących znaków: ‹ › ' “ ",
  },
  PlaylistButton: {
    added: 'Dodano do Twojej playlisty',
    alreadyAdded: 'Już dodane do playlista',
    buttonLabel: 'Playlista',
    removed: 'Usunięto z Twojej playlisty',
    error: 'Coś poszło nie tak, spróbuj ponownie',
    notAvailableRecoOff:
      'Jeśli chcesz mieć dostęp do tej funkcjonalności, aktywuj rekomendacje i personalizację w Ustawieniach.',
  },
  ProfileManager: {
    addProfile: 'Dodaj profil',
    add: 'Dodaj',
    avatarPrefix: 'Avatar %{displayName}',
    chooseAvatar: 'Wybierz avatar',
    closeModal: 'Zamknij',
    deleteProfile: 'Usuń profil',
    deleteProfileCancelButton: 'Anuluj',
    deleteProfileConfirmButton: 'Ok',
    deleteProfileConfirmText:
      'Czy na pewno chcesz usunąć profil %{displayName}?',
    deleteProfileConfirmTitle: 'Potwierdź usunięcie',
    deleteToCreate: 'Musisz usunąć profil, aby utworzyć nowy.',
    edit: 'Edytuj profil',
    editAriaLabel: 'Edytuj profil %{displayName}',
    editAvatarAriaLabel: 'Edytuj avatar',
    inputPlaceholder: 'Nazwa',
    kidsProfileSubText:
      'Ten profil jest przeznaczony dla dzieci, umożliwiającym bezpośredni dostęp do spersonalizowanej, bezpiecznej i odpowiedniej przestrzeni dla wieku Twojego dziecka.',
    kidsProfileTitle: 'Profil dziecięcy',
    kidsProfileSettings: 'Profil dziecięcy',
    manage: 'Zarządzaj profilami',
    maxAmount: 'Osiągnąłeś maksymalną liczbę profili dla tego konta',
    newWindowAriaLabel: '(nowe okno)',
    profileIconAriaLabel: 'Menu, konto i logowanie',
    submitCreation: 'Zatwierdź',
    submitEdition: 'Zapisz zmiany',
    title: 'Zmień profil',
    userReachedMaxNumberProfilesTitle: 'Maksymalna liczba profili',
    userReachedMaxNumberProfilesText:
      'Osiągnąłeś maksymalną liczbę 10 profili na tym koncie. Aby utworzyć nowy, musisz usunąć profil.',
  },
  ProspectLoginMessage: {
    message: 'Wszystkie Twoje kanały na żywo oraz na życzenie',
    commitment: 'Bez zobowiązań',
    offer: 'Wybierz ofertę dla siebie',
  },
  SafetyDisclaimer: {
    error: 'Wystąpił błąd serwera. Spróbuj ponownie później.',
    wrongCode: 'Wpisałeś błędny kod. Spróbuj ponownie.',
    close: 'Zamknij',
    [SafetyCodeContext.Parental]: {
      title: 'Program dla dorosłych',
      tooltip:
        'Jeśli nie pamiętasz Twojego kodu rodzicielskiego, możesz go zmienić w Moim koncie.',
      forgotten: 'Zapomniałeś kodu rodzica?',
      safetyCodeTitle: 'Kod rodzicielski',
      safetyCodeInstruction: 'Wprowadź 4-cyfrowy kod rodzica',
      resetSafetyCode: 'Zresetuj kod rodzicielski',
      forgottenTitle: 'Zapomniałem hasła',
      forgottenText:
        'Użyj telefonu komórkowego, aby zmienić kod rodzica lub odwiedzić obszar klienta:',
      lockedTitle: 'Blokada wejścia kodu rodzicielskiego',
      lockedText:
        'Po wykonaniu zbyt wielu błędnych prób, dostęp do treści objętych kodem rodzicielskim zostaje zablokowany na 2 godziny. Możesz spróbować ponownie później lub zresetować swój kod rodzicielski.',
      createTitle: 'Zarządzaj kodem rodzicielskim',
      createText:
        'Kod rodzicielski ogranicza dostęp do wrażliwych treści (programy dla dorosłych). Aby zarządzać kodem rodzicielskim, przejdź do ',
      subCreateText: 'lub zeskanuj kod QR.',
      inOffer:
        'Aby uzyskać dostęp do tego programu, konieczne jest utworzenie kodu rodzicielskiego.',
      notInOffer:
        'Dostęp do programów dla dorosłych jest niedostępny, należy aktywować je w subskrypcji lub zmodyfikować ofertę.',
      createCode: 'Utwórz kod rodzicielski',
      enterCode: 'Wprowadź kod rodzicielski',
      conditions: 'Kontynuując, akceptację powyższych warunków.',
      warningDescription:
        'Ta usługa jest niedostępna dla osób poniżej 18 roku życia. Dostęp do niej mogą mieć tylko osoby dorosłe ze względu na treści pornograficzne mogące niekorzystnie wpływać na rozwój fizyczny lub psychiczny nieletnich.',
    },
    [SafetyCodeContext.Purchase]: {
      title: 'Kod zakupu',
      tooltip:
        'Jeśli nie pamiętasz Twojego kodu rodzicielskiego, możesz go zmienić w Moim koncie.',
      forgotten: 'Zapomniałeś kodu zakupu?',
      safetyCodeTitle: 'Kod zakupu',
      safetyCodeInstruction: 'Wprowadź 4-cyfrowy kod zakupu',
      resetSafetyCode: 'Zresetuj swój kod zakupu',
      forgottenTitle: 'Zapomniałem hasła',
      forgottenText:
        'Użyj telefonu komórkowego, aby zmienić kod zakupu lub przejdź do strefy klienta:',
      lockedTitle: 'Wpis kodu zakupu zablokowany',
      lockedText:
        'Po zbyt wielu błędnych próbach dostęp do treści objętych kodem zakupu zostaje zablokowany na 2 godziny. Możesz spróbować ponownie później lub zresetować swój kod zakupu.',
      createTitle: 'Zarządzaj kodem zakupu',
      createText:
        'Aby odczytać ten program, należy stworzyć kod zakupu. Użyj swojego telefonu komórkowego, aby stworzyć kod zakupu lub przejdź do strefy klienta:',
      subCreateText: '',
      needPurchaseCode:
        'Aby uniknąć przypadkowego zakupu, utwórz swój kod zakupu. Możesz nim zarządzać w każdej chwili z poziomu sekcji Moje konto',
      createCode: 'Utwórz swój kod zakupu',
      enterCode: 'Wpisz swój kod zakupu',
      conditions: 'Kontynuując, akceptację powyższych warunków.',
      warningDescription: '',
    },
  },
  Search: {
    searchInputLabel: 'Szukaj',
    activateSearchTriggerLabel: 'Szukaj',
    closeSearchTriggerLabel: 'Zamknij wyszukiwanie',
    noResultTitle: 'Brak wyników wyszukiwania',
  },
  ServerError: {
    title: 'Pojawił się problem!',
    text: 'Dokładamy naszych starań, by naprawić serwis i usunąć wszystkie błędy.',
  },
  SettingsTemplate: {
    disabled: 'Nieaktywna',
    enabled: 'Aktywna',
    errorServer: 'Wystąpił błąd serwera. Spróbuj ponownie później.',
    preferences: {
      autoplay: {
        title: 'Automatyczne odtwarzanie',
        trailer: {
          explanation: 'Automatyczne odtwarzanie zwiastuna',
          soundExplanation:
            'Włącz dźwięk podczas automatycznego odtwarzania zwiastuna',
          title: 'Zwiastun',
        },
        videoPreviews: {
          explanation: 'Automatyczne odtwarzanie podczas nawigacji',
          soundExplanation:
            'Zezwalaj na dźwięk podczas automatycznego odtwarzania podglądu',
          title: 'Podgląd wideo',
        },
      },
      imageQuality: {
        explanation:
          'Czy wiesz, że...? Wybór odpowiedniej jakości przyczynia się do zmniejszenia śladu węglowego.',
        high: 'Maksimum',
        low: 'Przeskalowane',
        medium: 'Średni',
        title: 'Jakość obrazu',
      },
      personalization: {
        explanation:
          'Funkcje te wymagają gromadzenia danych użytkowych. Poprzez sprzeciw wobec gromadzenia tych danych, wszystkie te funkcje zostaną wyłączone, a wcześniej zgromadzone dane zostaną usunięte.',
        list: {
          item1:
            'wznowienie odtwarzania programu w momencie jego zatrzymania, w tym na innym urządzeniu',
          item2: 'spersonalizowane zalecenia dotyczące programu',
          item3: 'stan odtwarzania każdego odcinka serialu',
          item4: 'zarządzanie swoją listą odtwarzania',
          item5: 'zarządzanie swoją historią oglądania',
          item6: 'stwierdzenia "Lubię" / "Nie lubię"',
        },
        subtitle:
          'CANAL+ personalizuje aplikację zgodnie z Twoimi zachowaniami:',
        modal: {
          cancel: 'Zrezygnuj',
          confirm: 'Zatwierdź',
          explanation:
            'Twój profil rekomendacji, historia przeglądania, recenzje i playlisty zostaną utracone, a funkcje personalizacji zostaną wyłączone.',
          title:
            'Czy na pewno chcesz dezaktywować usługę rekomendacji i personalizacji?',
        },
        title: 'Personalizacja',
      },
      playNextEpisode: {
        explanation:
          'Kolejne odcinki danego sezonu uruchamiają się automatycznie',
        title: 'Sekwencja odcinków',
      },
      title: 'Preferencje',
      whoIsWatching: {
        explanation:
          'To ustawienie służy do wyświetlania listy profili podczas uruchamiania aplikacji.',
        title: 'Wybór profilu przy starcie',
      },
    },
    title: 'Ustawienia',
    videoQuality: {
      title: 'Jakość wideo',
      realTimeStreaming: {
        title: 'Strumieniowanie w czasie rzeczywistym',
        explanation:
          'Strumieniowanie w czasie rzeczywistym znacznie zmniejsza opóźnienie między nagraniem obrazu telewizyjnego a wyświetleniem go na urządzeniu, maksymalnie zbliżając przekaz do transmisji na żywo. Ta funkcjonalność jest dostępna tylko z niektórych kanałów LIVE (patrz: lista kanałów w CANAL+ Assistance). Ostrzeżenie: to ustawienie może spowodować, że obraz wideo będzie ponownie ładowany w określonych momentach, w zależności od połączenia. To ustawienie będzie stopniowo ulepszane i wprowadzane na innych kanałach.',
      },
      streaming: {
        explanation:
          'To ustawienie pozwala zmniejszyć zużycie planu taryfowego sieci komórkowej, ograniczając jakość wideo, gdy nie masz połączenia z Wi-Fi.',
        information:
          'Czy wiesz, że...? Wybór odpowiedniej jakości przyczynia się do zmniejszenia śladu węglowego.',
        maximum: {
          explanation:
            'Aż do 1080p - 4K, w zależności od rozdzielczości Twojego sprzętu. Zużywa około %1$@.',
          title: 'Maksimum',
        },
        medium: {
          explanation:
            'Aż do 720p: zalecane dla komputerów lub Mac. Zużywa około %1$@.',
          title: 'Średni',
        },
        minimum: {
          explanation:
            'Aż do 480p: zalecane dla smartfonów w użyciu. Zużywa około %1$@.',
          title: 'Minimum',
        },
        title: 'Streaming',
      },
    },
    videoSettings: {
      hdr: {
        auto: 'Automatyczny',
        explanation:
          'Po włączeniu tej opcji opcja HDR będzie dostępna na niektórych kanałach (listę kanałów można znaleźć na stronie wsparcia CANAL+). Jeśli włączysz tę funkcję, na kanałach emitowanych w HDR niekompatybilne urządzenia mogą wyświetlać czarny ekran zamiast obrazu wideo. Mogą również występować zniekształcenia kolorów.',
        title: 'HDR na niektórych kanałach',
      },
      title: 'Ustawienia wideo',
    },
  },
  ShowcaseTemplate: {
    OfferHighlight: {
      pauseBtn: 'Odtwórz zwiastun programu (dźwięk wyłączony)',
      playBtn: 'Wstrzymanie zwiastuna programu (wyłączenie dźwięku)',
    },
  },
  Slideshow: { skip: 'Pomiń' },
  Sport: {
    now: 'Teraz',
    lessThanOneHourAgo:
      '%{smart_count} minuta temu |||| %{smart_count} minut temu',
    betweenOneAndTwoHoursAgo: '1 godzina %{smart_count} minut temu',
    someHoursAgo: '%{smart_count} godzina temu |||| %{smart_count} godzin temu',
    someDaysAgo: '%{smart_count} dzień temu |||| %{smart_count} dni temu',
  },
  StartOver: {
    seeLive: 'Oglądaj na żywo',
    seeFromBeginning: 'Oglądaj od początku',
  },
  StoreRedirection: {
    title:
      'Pobierz aplikację na telefon komórkowy lub tablet i korzystaj z najlepszej jakości wideo',
    buttonText: 'Pobierz aplikację',
  },
  Streaming: {
    realtime: 'Strumieniowanie w czasie rzeczywistym',
    explanation:
      'Strumieniowanie w czasie rzeczywistym znacznie skraca czas między nagraniem obrazu telewizyjnego a wyświetleniem go na urządzeniu, maksymalnie zbliżając przekaz do transmisji na żywo. Ta funkcjonalność jest dostępna tylko z niektórych kanałów LIVE (patrz: lista kanałów w CANAL+ Assistance).<br/>Uwaga: to ustawienie może być związane z generowaniem okresów ponownego ładowania wideo, w zależności od wykorzystywanego aktualnie połączenia. Ustawienie to będzie stopniowo ulepszane i wprowadzane na inne kanały.',
    whoWatching: 'Kto ogląda?',
    dontShow: 'Nie pokazywać ponownie tego ekranu?',
    showOnLaunch: 'Wyświetlać ten ekran przy każdym uruchomieniu?',
    editInSettings:
      'Możesz zmienić tę opcję w aplikacji, w sekcji „Ustawienia”.',
    editInMore: 'Możesz zmienić tę opcję w aplikacji, w zakładce „Dodatkowe”.',
    options: 'Tak / Nie',
  },
  Video: {
    fullscreen: 'Zobacz pełny ekran',
    watch: 'Obejrzyj wideo',
    play: 'Odtwórz wideo',
    pause: 'Wstrzymaj wideo',
    mute: 'Wycisz wideo',
    unmute: 'Aktywacja dźwięku wideo',
  },
  TvGuide: {
    search: {
      placeholder: 'Szybki dostęp: wprowadź nazwę kanału',
      zapNumber: 'Kanał',
    },
  },
  ParentalCode: {
    title: 'Kod rodzicielski',
  },
  PurchaseCode: {
    title: 'Kod zakupy',
  },
  PurchaseCodeDeletion: {
    title: 'Dezaktywacja',
  },
  ViolentContent: {
    title: 'Zarządzanie ochroną treści zawierających przemoc',
  },
  VoucherFeedback: {
    success: 'Użyto kodu promocyjnego',
    invalid: 'Kod promocyjny nie działa',
  },
  VoucherInput: {
    promotionalCode: 'kod promocyjny',
  },
  WhoIsWatching: {
    title: 'Kto ogląda?',
    toggleNotDisplay: 'Nie pokazywać ponownie tego ekranu?',
    toggleNotDisplayDesc:
      'Możesz zmienić tę opcję w aplikacji, w sekcji „Ustawienia”.',
  },
  Zone: {
    title: 'Wybierz swój region',
    subtitle_france: 'Francja i terytoria zamorskie',
    subtitle_europe: 'Europa',
    subtitle_africa: 'Afryka',
    subtitle_others: 'Pozostałe kraje',
  },
};

export default LOCALE_PL_PL;

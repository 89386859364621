import { createSelector } from '@reduxjs/toolkit';
import type { IState } from '../types/State-type';
import type { AdultState } from './adult';

const adultSelector = (state: IState): AdultState => state?.adult;

export const adultStatusSelector = createSelector(
  adultSelector,
  (adult) => adult?.adultStatus
);

export const adultStatusEnabledSelector = createSelector(
  adultStatusSelector,
  (adultStatus) => adultStatus?.enabled || false
);

export const adultDisclaimerReadSelector = createSelector(
  adultSelector,
  (adult) => adult?.disclaimerRead
);

export const adultErrorSelector = createSelector(
  adultSelector,
  (adult) => adult?.error
);

export const adultInitializedSelector = createSelector(
  adultSelector,
  (adult) => adult?.initialized || false
);

export const adultBrowsingSelector = createSelector(
  adultSelector,
  (adult) => adult?.adultBrowsing || false
);

export const activeAdultSelector = createSelector(
  adultSelector,
  (adult) => adult?.active
);

/** @public */
export const adultSaltSelector = createSelector(
  adultStatusSelector,
  (adultStatus) => adultStatus?.salt
);

/** @public */
export const adultEncryptionMethodSelector = createSelector(
  adultStatusSelector,
  (adultStatus) => adultStatus?.encryptionMethod
);

export const isAdultSelector = createSelector(
  [adultBrowsingSelector, activeAdultSelector],
  (browsing, active) => browsing && active
);

import { createSelector } from '@reduxjs/toolkit';
import type { PurchaseCodeState } from '../../store/slices/purchaseCode';
import type { IState } from '../../store/types/State-type';

const purchaseCodeSelector = (state: IState): PurchaseCodeState =>
  state?.purchaseCode;

export const purchaseCodeErrorSelector = createSelector(
  purchaseCodeSelector,
  (purchaseCode) => purchaseCode?.error
);

export const isPurchaseBrowsingSelector = createSelector(
  purchaseCodeSelector,
  (purchaseCode) => purchaseCode?.purchaseCodeBrowsing || false
);

export const purchaseCodeStatusSelector = createSelector(
  purchaseCodeSelector,
  (purchaseCode) => purchaseCode?.purchaseCodeStatus
);

export const purchaseInfoSelector = createSelector(
  purchaseCodeSelector,
  (purchaseCode) => purchaseCode?.purchaseInfo
);

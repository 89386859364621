import { useMemo } from 'react';
import type {
  FocusManager,
  FocusState,
} from '../../oneNavigation/FocusManager';

export function useFocusState(
  focusManager?: FocusManager
): FocusState | undefined {
  return useMemo(() => focusManager?.getFocusState(), [focusManager]);
}

import type { ApiV2WsFromPath } from '@dce-front/hodor-types';
import type { DtoError } from '@dce-front/hodor-types/api/v2/common/dto/error';
import type { HodorApiParameter, HodorSdkConfig } from '../config/types';
import { fetchHodor } from '../helpers/fetchHodor';
import { getBooleanToNumber } from '../helpers/getBooleanToNumber';
import { getUrlWithQueries } from '../helpers/getUrlWithQueries';

export const HODOR_API_PARAMETERS: HodorApiParameter[] = [
  {
    id: 'appKey',
    in: 'path',
  },
  {
    id: 'cmsToken',
    in: 'path',
  },
  {
    id: 'crmSegments',
    in: 'parameters',
  },
  {
    id: 'experiments',
    in: 'parameters',
  },
  {
    id: 'featureToggles',
    in: 'parameters',
  },
  {
    id: 'like',
    in: 'parameters',
  },
  {
    id: 'segments',
    in: 'parameters',
  },
];

const API = 'wsFromPath';

const getParametersForApi = ({
  searchQuery,
  shouldAddToPlaylist,
  shouldAutoDownload,
  shouldAutoPlay,
  shouldFollowRedirect,
  ...restWsFromPathParameters
}: Omit<WsFromPathParameters, 'path'>) => ({
  ...restWsFromPathParameters,
  ...(searchQuery !== undefined && { q: searchQuery }),
  ...(shouldAddToPlaylist !== undefined && {
    addtoplaylist: shouldAddToPlaylist,
  }),
  ...(shouldAutoDownload !== undefined && { autoDownload: shouldAutoDownload }),
  ...(shouldAutoPlay !== undefined && { autoPlay: shouldAutoPlay }),
  ...(shouldFollowRedirect && {
    followredirect: getBooleanToNumber(shouldFollowRedirect),
  }),
});

/** Not available on **hodor-types** */
export type ApiV2WsFromPathError = {
  errorStatusCode: 404;
  errorResponse: DtoError;
};

export type WsFromPathParametersBase = {
  path: string;
  'param[episodeId]'?: string;
  action?: string;
  broadcastVectorId?: string;
  channel?: number;
  context?: string;
  contextDetail?: string;
  contractId?: string;
  distributorId?: string;
  episodeId?: string;
  get?: string;
  hasAllCommitments?: boolean;
  isMinimalPrice?: boolean;
  ispId?: string;
  searchQuery?: string;
  shouldAddToPlaylist?: boolean;
  shouldAutoDownload?: boolean;
  shouldAutoPlay?: boolean;
  youthOffer?: string;
};

export type WsFromPathParametersWithoutFollowRedirect =
  WsFromPathParametersBase & {
    shouldFollowRedirect?: never;
  };

export type WsFromPathParametersWithFollowRedirect =
  WsFromPathParametersBase & {
    shouldFollowRedirect: true;
  };

export type WsFromPathParameters =
  | WsFromPathParametersWithoutFollowRedirect
  | WsFromPathParametersWithFollowRedirect;

export function wsFromPath(
  hodorSdkConfig: HodorSdkConfig,
  parameters: WsFromPathParametersWithFollowRedirect
): Promise<object | ApiV2WsFromPathError>;

export function wsFromPath(
  hodorSdkConfig: HodorSdkConfig,
  parameters: WsFromPathParametersWithoutFollowRedirect
): Promise<ApiV2WsFromPath | ApiV2WsFromPathError>;

/**
 * Fetch Hodor wsFromPath Api
 *
 * - return `ApiV2WsFromPath` if `shouldFollowRedirect` is falsy
 * - return `object` if `shouldFollowRedirect` is true
 */
export async function wsFromPath(
  hodorSdkConfig: HodorSdkConfig,
  wsFromPathParameters: WsFromPathParameters
): Promise<ApiV2WsFromPath | object | ApiV2WsFromPathError> {
  const { baseUrl, logger, paths } = hodorSdkConfig;
  const startAt = Date.now();

  const response = await fetchHodor({
    api: API,
    hodorSdkConfig,
    hodorSdkParameters: HODOR_API_PARAMETERS,
    startAt,
    url: getUrlWithQueries(
      `${baseUrl}${paths.wsFromPath}`,
      getParametersForApi(wsFromPathParameters)
    ),
  });

  const jsonResponse: ApiV2WsFromPath = await response.json();

  logger.debug(`HODOR ${API} Response:`, jsonResponse);

  return jsonResponse;
}

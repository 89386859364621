import { DisplayMode, isClientSide } from '@canalplus/mycanal-commons';
import { StickyContainer } from '@canalplus/mycanal-sharedcomponent';
import type { PassUserDataEnriched } from '@canalplus/sdk-pass';
import { DidomiSDK } from '@didomi/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import classNames from 'classnames';
import type { JSX } from 'react';
import { useSelector } from 'react-redux';
import { getPublicConfig } from '../../helpers/config/config-helper';
import { useInvariantSelector } from '../../helpers/hooks/useInvariantSelector';
import { useIsTvDevice } from '../../helpers/hooks/useIsTvDevice';
import { isDetailV5DisplayTemplateInSSRSelector } from '../../selectors/detailV5-selectors';
import {
  isCreativeMediaTemplateSelector,
  isHeaderActiveOnTVSelector,
} from '../../selectors/header-selectors';
import {
  didomiNoticeIdSelector,
  featDisplayHeaderMenuSelector,
  getFeatureToggleDidomi,
  hasNavigationSelector,
  isFooterHiddenSelector,
  isHeaderHiddenSelector,
  searchDeeplinkPageParametersSelector,
} from '../../store/slices/application-selectors';
import { isFeatImmersiveFullAllowedSelector } from '../../store/slices/contextFeature-selectors';
import { hasCoverSelector } from '../../store/slices/page-selectors';
import {
  isSearchActiveSelector,
  searchQuerySelector,
} from '../../store/slices/search-selectors';
import { displayModeSelector } from '../../store/slices/ui-selectors';
import { authenticatedSelector } from '../../store/slices/user-selectors';
import LoadableSearch from '../../templates/Search';
import SlideshowNotificationsContainer from '../../templates/Slideshow/components/SlideshowNotificationsContainer';
import AlertNotificationContainer from '../Alert/AlertNotificationContainer';
import {
  hasDidomiConsentChanged,
  hasDidomiLoaded,
  setDidomiConsent,
} from '../DidomiProvider/didomiActions';
import { useDidomiDispatch } from '../DidomiProvider/didomiHooks';
import { FooterContainerConnected } from '../HeaderFooter/FooterContainerConnected';
import HeaderOneDiscovery from '../HeaderOneDiscovery/HeaderOneDiscovery';
import { MetaTagsDefault } from '../MetaTags/MetaTagsDefault';
import { MetaTagsDefaultTemplate } from '../MetaTags/MetaTagsDefaultTemplate';
import { ModalContainerConnected } from '../Modal/ModalContainerConnected';
import { RoutingContextProvider } from '../Page/RoutingContext';
import Routes from '../Routes/Routes';
import { SKIP_LINK_ID } from '../SkipLink/constants';
import { StickyBarConnected } from '../StickyBar/StickyBarConnected';
import { MycanalVisualDebuggerWrapper } from '../VisualDebugger/MycanalVisualDebuggerWrapper';
import WhoIsWatching from '../WhoIsWatching/WhoIsWatching';
import styles from './Application.css';
import { ApplicationSkipLinks } from './ApplicationSkipLinks/ApplicationSkipLinks';
import { GdprModal } from './GdprModal';
import { useAbTestingCookiesHandler } from './hooks/useAbTestingCookiesHandler';
import { useAcmCinematic } from './hooks/useAcmCinematic';
import { useDidomi } from './hooks/useDidomi';
import { useHandleUnmountSearch } from './hooks/useHandleUnmountSearch';
import { useHasFocus } from './hooks/useHasFocus';
import { useIfcOneCoreLib } from './hooks/useIfcOneCoreLib';
import { useIframeBehavior } from './hooks/useIframeBehavior';
import { useInitProfiles } from './hooks/useInitProfiles';
import { useLoadTrackingScripts } from './hooks/useLoadTrackingScripts';
import { usePerformanceObserver } from './hooks/usePerformanceObserver';
import { usePlayerScript } from './hooks/usePlayerScript';
import { usePostRenderCinematic } from './hooks/usePostRenderCinematic';
import { useRenderSource } from './hooks/useRenderSource';
import { useSendNewrelicGlobalInfo } from './hooks/useSendNewrelicGlobalInfo';
import { useSetTargetedAds } from './hooks/useSetTargetedAds';
import { useUserChangeSettings } from './hooks/useUserChangeSettings';
import { useWhoIsWatching } from './hooks/useWhoIsWatching';
import { useWindowResizeListener } from './hooks/useWindowResizeListener';

export type ApplicationProps = {
  userInfos?: PassUserDataEnriched;
};

export function Application({ userInfos }: ApplicationProps): JSX.Element {
  const displayMode = useSelector(displayModeSelector);
  const featDisplayHeaderMenu = useInvariantSelector(
    featDisplayHeaderMenuSelector
  );
  const noticeId = useInvariantSelector(didomiNoticeIdSelector);
  const hasCover = useSelector(hasCoverSelector);
  const hasNavigation = useSelector(hasNavigationSelector);
  const isDetailV5DisplayTemplateInSSR = useSelector(
    isDetailV5DisplayTemplateInSSRSelector
  );
  const isCreativeMediaTemplate = useSelector(isCreativeMediaTemplateSelector);
  const isHeaderActiveOnTV = useSelector(isHeaderActiveOnTVSelector);
  const isTvDevice = useIsTvDevice();
  const searchQuery = useSelector(searchQuerySelector);
  const searchDeeplinkPageParameters = useSelector(
    searchDeeplinkPageParametersSelector
  );
  const isSearchActive =
    (useSelector(isSearchActiveSelector) && Boolean(searchQuery)) ||
    Boolean(searchDeeplinkPageParameters?.searchkey);
  const isFeatImmersiveFullAllowed = useSelector(
    isFeatImmersiveFullAllowedSelector
  );
  const isFooterHidden = useInvariantSelector(isFooterHiddenSelector);
  const isFeatDidomi = useInvariantSelector(getFeatureToggleDidomi);
  const { showWhoIsWatching } = useWhoIsWatching();
  const isAuthenticated = useInvariantSelector(authenticatedSelector);

  const isHeaderHidden =
    useSelector(isHeaderHiddenSelector) ||
    (displayMode === DisplayMode.FULLWINDOWED && !isTvDevice);

  const isHeaderNotActiveOnTV =
    (!featDisplayHeaderMenu || !isHeaderActiveOnTV) && isTvDevice;

  const { reactQueryDevtools } = getPublicConfig().debug;

  // Handle a11y focus on keydown events only (Web only, not TV)
  useHasFocus(isTvDevice);

  // Handle offset of application content wrapper
  // because of header has a changing style
  // or some pages is displaying above it (e.g. immersive or full-windowed player).
  const applicationClassNames = classNames(styles.application, {
    [styles['application--resetNav']!]: !hasNavigation,
    [styles['application--noHeader']!]:
      showWhoIsWatching || isHeaderHidden || isHeaderNotActiveOnTV,
    [styles['application--cover']!]:
      hasCover || isDetailV5DisplayTemplateInSSR || isCreativeMediaTemplate,
  });

  const handleUnmountSearch = useHandleUnmountSearch();
  const isTrackingScriptLoaded = useLoadTrackingScripts();
  const didomiDispatch = useDidomiDispatch();
  const { DIDOMI } = getPublicConfig();

  usePostRenderCinematic({ userInfos });
  usePlayerScript(!isAuthenticated);
  useAcmCinematic();
  useUserChangeSettings();
  useIframeBehavior();
  useSendNewrelicGlobalInfo();
  useWindowResizeListener();
  useAbTestingCookiesHandler();
  useDidomi(isTrackingScriptLoaded, isFeatDidomi);

  // TODO: delete this hook when didomi is fully integrated
  useSetTargetedAds(isTrackingScriptLoaded, !isFeatDidomi);

  useIfcOneCoreLib();
  useRenderSource();
  usePerformanceObserver();
  useInitProfiles();

  return (
    <>
      {!$_BUILD_RENDERMODE_CSR && (
        <>
          <MetaTagsDefault />
          <MetaTagsDefaultTemplate />
        </>
      )}
      <div className={applicationClassNames}>
        {isClientSide() && !isTvDevice && DIDOMI?.ACTIVE && isFeatDidomi ? (
          <DidomiSDK
            apiKey={DIDOMI.API_PUBLIC_KEY}
            noticeId={noticeId}
            onReady={() => didomiDispatch(hasDidomiLoaded(true))}
            onConsentChanged={() => {
              didomiDispatch(hasDidomiConsentChanged(true));
              didomiDispatch(
                setDidomiConsent(window.Didomi.getCurrentUserStatus())
              );
            }}
          />
        ) : (
          <GdprModal />
        )}

        {isTvDevice ? (
          <MycanalVisualDebuggerWrapper />
        ) : (
          !showWhoIsWatching && <ApplicationSkipLinks />
        )}

        {showWhoIsWatching ? (
          <WhoIsWatching />
        ) : (
          <>
            <StickyContainer isFullHeight={isSearchActive && !isTvDevice}>
              {!isHeaderHidden && (
                <>
                  <AlertNotificationContainer />
                  <div
                    className={classNames({
                      [styles['application--hideHeader']!]:
                        isHeaderNotActiveOnTV,
                    })}
                  >
                    <HeaderOneDiscovery />
                  </div>
                </>
              )}
              {isSearchActive && !isTvDevice && (
                <RoutingContextProvider routingContext="search">
                  <LoadableSearch handleUnmount={() => handleUnmountSearch} />
                </RoutingContextProvider>
              )}
              {!isSearchActive && !isTvDevice && <StickyBarConnected />}
            </StickyContainer>

            <div
              id={SKIP_LINK_ID.appMainContent}
              className={classNames(styles.application__mainContent, {
                [styles['application__mainContent--immersiveFull']!]:
                  isFeatImmersiveFullAllowed,
                [styles['application__mainContent--with-skip-link']!]:
                  !isTvDevice,
              })}
            >
              <Routes />
            </div>

            {!isFooterHidden && !$_BUILD_RENDERMODE_CSR && (
              <FooterContainerConnected />
            )}
          </>
        )}

        <RoutingContextProvider routingContext="slideshowNotifications">
          <SlideshowNotificationsContainer />
        </RoutingContextProvider>
        <ModalContainerConnected />

        {reactQueryDevtools && <ReactQueryDevtools initialIsOpen={false} />}
      </div>
    </>
  );
}

import { throttle } from '@canalplus/mycanal-commons';
import { useOnPageScroll } from '@canalplus/mycanal-util-react';
import type { JSX } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export type StickyBarProps = {
  getStickyBar: (() => JSX.Element) | null;
  getStickyBarOriginal: (() => HTMLElement) | null;
};

function StickyBar({
  getStickyBar,
  getStickyBarOriginal,
}: StickyBarProps): JSX.Element | null {
  const stickyContainerRef = useRef<HTMLDivElement>(null);
  const [showStickyBar, setShowStickyBar] = useState<boolean>(false);

  const stickyBar = useMemo(
    () => getStickyBar && getStickyBar(),
    [getStickyBar]
  );
  const stickyBarOriginal = useMemo(
    () => getStickyBarOriginal && getStickyBarOriginal(),
    [getStickyBarOriginal]
  );

  const isStickyBarActivated = !!stickyBarOriginal && !!stickyBar;

  const determineStickyShow = useCallback(() => {
    if (stickyContainerRef.current) {
      const originalBarTop =
        stickyBarOriginal?.getBoundingClientRect?.().top || 0;
      const stickyContainerTop =
        stickyContainerRef.current.getBoundingClientRect().top;
      const newShowStickyBar = originalBarTop <= stickyContainerTop;
      if (newShowStickyBar !== showStickyBar) {
        setShowStickyBar(newShowStickyBar);
      }
    }
  }, [stickyBarOriginal, showStickyBar]);

  useEffect(() => {
    if (isStickyBarActivated) {
      determineStickyShow();
    }
  }, [isStickyBarActivated]); // eslint-disable-line react-hooks/exhaustive-deps

  useOnPageScroll(determineStickyShow, isStickyBarActivated, 100);

  // TODO: make custom hook: useOnWindowResize
  useEffect(() => {
    if (isStickyBarActivated) {
      const onResize = throttle(determineStickyShow, 100);
      window.addEventListener('resize', onResize);
      return () => window.removeEventListener('resize', onResize);
    }
    return;
  }, [determineStickyShow, isStickyBarActivated]);

  if (!isStickyBarActivated) {
    return null;
  }

  return <div ref={stickyContainerRef}>{showStickyBar ? stickyBar : null}</div>;
}

export default StickyBar;

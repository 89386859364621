import type { ApiV2WsFromPathError } from '@canalplus/sdk-hodor';
import { isApiV2WsFromPathError } from '@canalplus/sdk-hodor';
import type { ApiV2WsFromPath } from '@dce-front/hodor-types';
import type { ApiV2OnClickParameters } from '@dce-front/hodor-types/api/v2/common/dto/definitions';

type GetDestructuredWsFromPathParameters = {
  displayTemplate: string;
  pathname: string;
  adult?: boolean;
  displayName?: string;
  parameters?: ApiV2OnClickParameters[];
  perso?: ApiV2WsFromPath['redirectTo']['perso'];
  uniqueId?: string;
  URLPage?: string;
  URLWebsite?: string;
};

export const getDestructuredWsFromPath = (
  wsFromPathResponse: ApiV2WsFromPath | ApiV2WsFromPathError
): GetDestructuredWsFromPathParameters => {
  if (isApiV2WsFromPathError(wsFromPathResponse)) {
    const { displayTemplate, displayName, path } =
      wsFromPathResponse.errorResponse.currentPage;

    return {
      displayTemplate,
      displayName,
      pathname: path,
    };
  }

  const { redirectTo } = wsFromPathResponse;
  const {
    displayTemplate = '',
    displayName = '',
    perso,
    adult,
    URLWebsite,
    parameters,
    path,
  } = redirectTo;

  return {
    adult,
    displayName,
    displayTemplate,
    parameters,
    pathname: path,
    perso,
    URLWebsite,
  };
};

import { createContext, useContext } from 'react';
import createConsumer from './createConsumer';
import createProvider from './createProvider';
import {
  interpolateJSX,
  type InterpolateJSXParameters,
} from './interpolateJSX';

const createI18n = () => {
  const store = {};
  const context = createContext(store);
  const { Provider, Consumer } = context;
  const provider = createProvider(Provider);
  const consumer = createConsumer(Consumer);
  const useTranslation = () => useContext(context);

  return {
    provider,
    consumer,
    context,
    useTranslation,
  };
};

export { createI18n, interpolateJSX, type InterpolateJSXParameters };

import classNames from 'classnames';
import type { JSX } from 'react';
import { useSelector } from 'react-redux';
import { isFullScreenSelector } from '../../store/slices/player-selectors';
import { DisplayModeManager } from '../DisplayModeManager/DisplayModeManager';
import { PlayerContainer } from '../Player/PlayerContainer';
import styles from './Player.css';

/**
 * Renders a player when is in fullscreen, otherwise an empty div
 */
function Player(): JSX.Element {
  const isFullScreen = useSelector(isFullScreenSelector);

  return (
    <div>
      {isFullScreen ? (
        <div
          className={classNames(
            styles.player__playerContainer,
            styles['player__playerContainer--hidden']
          )}
        >
          <DisplayModeManager fullscreenNoPaddings windowedPadded>
            <div className={styles.player__playerWrapper}>
              <div className={styles.player__player}>
                <PlayerContainer />
              </div>
            </div>
          </DisplayModeManager>
        </div>
      ) : null}
    </div>
  );
}

export default Player;

import { createSelector } from '@reduxjs/toolkit';
import {
  locationSearchSelector,
  pathnameSelector,
} from '../../store/slices/routing-selectors';
import type { IState } from '../../store/types/State-type';
import { activeAdultSelector, isAdultSelector } from './adult-selectors';
import { hostnameSelector } from './application-selectors';
import type { Page } from './page';

export const pageSelector = (state: IState): Page | undefined => state?.page;

const pageMainOnclickSelector = createSelector(
  pageSelector,
  (page) => page?.mainOnClick
);

export const pageDisplayNameSelector = createSelector(
  pageMainOnclickSelector,
  (mainOnClick) => mainOnClick?.displayName
);

/** @public */
export const pageUrlPageSelector = createSelector(
  pageMainOnclickSelector,
  (mainOnClick) => mainOnClick?.URLPage
);

export const pageDisplayTemplateSelector = createSelector(
  pageMainOnclickSelector,
  (mainOnClick) => mainOnClick?.displayTemplate
);

export const pagePathSelector = createSelector(
  pageMainOnclickSelector,
  (mainOnClick) => mainOnClick?.path || ''
);

export const pagePersoSelector = createSelector(
  pageMainOnclickSelector,
  (mainOnClick) => mainOnClick?.perso
);

export const pageAdultSelector = createSelector(
  pageMainOnclickSelector,
  (mainOnClick) => !!mainOnClick?.adult
);

export const isFetchingSelector = createSelector(
  pageSelector,
  (page) => !!page?.isFetching
);

export const pageTrackingContextSelector = createSelector(
  pageSelector,
  (page) => page?.trackingContext || page?.mainOnClick?.trackingContext
);

export const pageTrackingSelector = createSelector(
  pageSelector,
  (page) => page?.tracking || {}
);

/** @public */
export const pageParametersSelector = createSelector(
  pageMainOnclickSelector,
  (mainOnClick) => mainOnClick?.parameters
);

export const pageURLSelector = createSelector(
  hostnameSelector,
  pathnameSelector,
  locationSearchSelector,
  (hostname, pathname, locationSearch) =>
    hostname && pathname
      ? `https://${hostname}${pathname}${locationSearch || ''}`
      : undefined
);

export const refetchPersoSelector = createSelector(
  pageSelector,
  (page) => !!page?.refetchPerso
);

/** @public */
export const pageHasCoverSelector = createSelector(
  pageSelector,
  (page) => page?.hasCover
);

export const hasCoverSelector = createSelector(
  [pageHasCoverSelector, activeAdultSelector, isAdultSelector],
  (hasCover, active, isAdult) => hasCover && (!active || isAdult)
);

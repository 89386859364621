import type { ContentRowHeader } from '@canalplus/mycanal-sharedcomponent';
import type { MiddlewareFactory, StoreBinder } from '@canalplus/one-navigation';
import { Binder } from '@canalplus/one-navigation';
import { Template } from '@canalplus/sdk-hodor';
import type { JSX } from 'react';
import { memo, useCallback } from 'react';
import ContentRowTemplate from '../../../components/ContentRow/ContentRowTemplate/ContentRowTemplate';
import { MetaTagsTemplate } from '../../../components/MetaTags/MetaTagsTemplate';
import useInfiniteQueryTemplate from '../../../helpers/hooks/useInfiniteQueryTemplate/useInfiniteQueryTemplate';
import { useIsTvDevice } from '../../../helpers/hooks/useIsTvDevice';
import { FocusManager } from '../../../helpers/oneNavigation/FocusManager';
import { MIDDLEWARE_STRATE } from '../../../helpers/oneNavigation/middleware';
import type { FetchDetails } from '../../../services/types';
import { FetchRequestTypes } from '../../../services/types';
import type {
  ContentStrateV5,
  DisplayParameters,
} from '../../../templates/LandingV5/data/formatter';
import { getContentRowStandardNextPageUrl } from '../data/getContentRowStandardNextPageUrl';
import type { IContentRowStandardState } from '../data/types';

export type ContentRowStandardContainerProps = {
  contents: ContentStrateV5[];
  displayParameters?: DisplayParameters;
  header: ContentRowHeader;
  itemOffset?: number;
  middleware?: MiddlewareFactory[];
  onClickParameters?: FetchDetails['onClickParameters'];
  focusManager?: FocusManager;
  binderId?: StoreBinder['id'];
  URLNextPage?: string;
};

function ContentRowStandardContainer({
  contents,
  displayParameters,
  header,
  itemOffset,
  middleware,
  onClickParameters,
  focusManager,
  binderId,
  URLNextPage = undefined,
}: ContentRowStandardContainerProps): JSX.Element {
  const isCreativeMedia = contents[0]?.type === Template.CreativeMedia;
  const isTvDevice = useIsTvDevice();

  const [{ data, isFetchingNextPage, fetchNextPage, hasNextPage }] =
    useInfiniteQueryTemplate<IContentRowStandardState>(
      undefined,
      { template: FetchRequestTypes.ContentRowStandard, onClickParameters },
      {
        // We don't want to run the fetch when the component is initialized
        // because we are already receiving the first contents from props that we pass through the initialData
        enabled: false,
        initialData: {
          pages: [
            {
              contents,
              URLNextPage,
            },
          ],
          pageParams: [],
        },
        getNextPageParam: (lastPageData) =>
          getContentRowStandardNextPageUrl(lastPageData),
      }
    );

  const allContents = data?.pages.reduce<ContentStrateV5[]>(
    (acc, page) => [...acc, ...page.contents],
    []
  );

  const fetchNextContents = useCallback(async () => {
    if (!isFetchingNextPage && hasNextPage) {
      await fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  return (
    <Binder binderId={binderId} middleware={middleware || MIDDLEWARE_STRATE}>
      <MetaTagsTemplate data={data} />
      <ContentRowTemplate
        contents={allContents}
        displayParameters={displayParameters}
        fetchNextContents={fetchNextContents}
        header={header}
        isFromCreativeMedia={isCreativeMedia}
        isTvDevice={isTvDevice}
        itemOffset={itemOffset}
        focusManager={focusManager}
      />
    </Binder>
  );
}

export default memo(ContentRowStandardContainer);

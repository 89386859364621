import { List, ToggleSwitch } from '@canalplus/dive';
import { Binder, useStore } from '@canalplus/one-navigation';
import classNames from 'classnames/bind';
import type { JSX } from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../helpers/hooks/useAppDispatch';
import { useIsTvDevice } from '../../helpers/hooks/useIsTvDevice';
import { usePreloadChunks } from '../../helpers/hooks/usePreloadChunks/usePreloadChunks';
import { useTracking } from '../../helpers/hooks/useTracking';
import {
  LAYER_PAGE,
  LAYER_WHOISWATCHING,
} from '../../helpers/oneNavigation/layers';
import { MIDDLEWARE_PROFILES_SELECTOR } from '../../helpers/oneNavigation/middleware';
import {
  setCookieWhoIsWatchingDisabled,
  setCookieWhoIsWatchingSession,
} from '../../helpers/whoIsWatching/cookieHelper';
import I18n from '../../lang';
import {
  setForceWhoIsWatching,
  setWhoIsWatchingDisabled,
  setWhoIsWatchingUserSession,
} from '../../store/slices/application';
import { getWhoIsWatchingSettingsSelector } from '../../store/slices/application-selectors';
import { hasProfilesLengthSelector } from '../../store/slices/user-selectors';
import Spinner from '../Spinner/Spinner';
import ProfileSelector from './ProfileSelector/ProfileSelector';
import styles from './WhoIsWatching.css';
import { TRACKING_ARGS_PAGE_WHOISWATCHING } from './constants';

const cx = classNames.bind(styles);

function WhoIsWatching(): JSX.Element | null {
  const isTvDevice = useIsTvDevice();
  const isProfileSelectorRendered = useSelector(hasProfilesLengthSelector);
  const whoIsWatchingSettings = useSelector(getWhoIsWatchingSettingsSelector);
  const [isWhoIsWatchingDisabled, setIsWhoIsWatchingDisabled] = useState(
    whoIsWatchingSettings?.disabled
  );

  const storeOneNavigation = useStore();
  const { t } = I18n.useTranslation();
  const dispatch = useAppDispatch();
  const { sendTracking } = useTracking();

  // send tracking on page mount
  useEffect(() => {
    sendTracking(TRACKING_ARGS_PAGE_WHOISWATCHING);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // active layer whoIsWatching
  useEffect(() => {
    storeOneNavigation.setActiveLayer(LAYER_WHOISWATCHING);

    return () => {
      storeOneNavigation.setActiveLayer(LAYER_PAGE);
    };
  }, [storeOneNavigation]);

  // focus on first element of profiles list
  useEffect(() => {
    if (isProfileSelectorRendered) {
      storeOneNavigation.focusDefault();
    }
  }, [isProfileSelectorRendered, storeOneNavigation]);

  /**
   * Preload some chunks for CSR render mode
   * - LandingV5Container
   * - ContentRowStandard
   */
  usePreloadChunks([
    { type: 'landing', strateMode: 'standard' },
    { type: 'contentRow', strateMode: 'standard' },
  ]);

  const handleClickAvatar = () => {
    // set cookie session
    setCookieWhoIsWatchingSession(isTvDevice);

    // Reset forceWhoIsWatching state to 'auto' to have the normal bahavior after a force display
    // It's important for hide the screen after select profile using inSession state
    dispatch(setForceWhoIsWatching('auto'));

    // update the store to indicate whoIsWatching has been displayed
    // this is the action hide WhoIsWatching screen and display app route (see in Application.tsx)
    dispatch(setWhoIsWatchingUserSession(true));
    dispatch(setWhoIsWatchingDisabled(isWhoIsWatchingDisabled));
  };

  const onClickToggleDisabled = () => {
    const newStateDisabled = !isWhoIsWatchingDisabled;

    // update component state with the new value
    setIsWhoIsWatchingDisabled(newStateDisabled);

    // update cookie
    setCookieWhoIsWatchingDisabled(isTvDevice, newStateDisabled);
  };

  return (
    <div className={styles.whoIsWatching}>
      <div className={styles.whoIsWatching__logo}>
        {/* Wait hodor to get logos from init instead authenticate to display logo
          <HeaderLogoOneDiscoveryConnected />
        */}
      </div>

      <Binder
        layer={LAYER_WHOISWATCHING}
        enabled={isTvDevice}
        middleware={MIDDLEWARE_PROFILES_SELECTOR}
        className={cx({ 'whoIsWatching--hidden': !isProfileSelectorRendered })}
      >
        <h2
          className={cx('whoIsWatching__title', {
            'whoIsWatching__title--fadeIn': isProfileSelectorRendered,
          })}
        >
          {t('WhoIsWatching.title')}
        </h2>
        {isProfileSelectorRendered && (
          <ProfileSelector handleClickAvatar={handleClickAvatar} />
        )}
      </Binder>
      <Binder
        layer={LAYER_WHOISWATCHING}
        enabled={isTvDevice}
        className={cx('whoIsWatching__toggle', {
          'whoIsWatching__toggle--fadeIn': isProfileSelectorRendered,
          'whoIsWatching--hidden': !isProfileSelectorRendered,
        })}
        middleware={MIDDLEWARE_PROFILES_SELECTOR}
      >
        <List
          title={t('WhoIsWatching.toggleNotDisplay')}
          id="whoIsWatchingToggleLabelId"
          actionableElement={
            !isTvDevice ? (
              <ToggleSwitch
                aria-label={t('WhoIsWatching.toggleNotDisplay')}
                labelledById="whoIsWatchingToggleLabelId"
                isToggled={isWhoIsWatchingDisabled}
                onClick={onClickToggleDisabled}
              />
            ) : undefined
          }
          {...(isTvDevice
            ? {
                onClick: onClickToggleDisabled,
                label: isWhoIsWatchingDisabled
                  ? t('Common.yes')
                  : t('Common.no'),
              }
            : {})}
        />
        <p
          className={cx('whoIsWatching__toggleSubtitle', {
            'whoIsWatching--hidden': !isProfileSelectorRendered,
          })}
        >
          {t('WhoIsWatching.toggleNotDisplayDesc')}
        </p>
      </Binder>

      {!isProfileSelectorRendered && <Spinner size={5} />}
    </div>
  );
}

export default WhoIsWatching;

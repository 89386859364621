import { Template } from '@canalplus/sdk-hodor';
import { createSelector } from '@reduxjs/toolkit';
import { getPublicConfig } from '../helpers/config/config-helper';
import { getHeaderActiveIndex } from '../helpers/navigation/navigation-helper';
import {
  isClientRenderSourceSelector,
  isFirstLevelPageSelector,
  isMobileSelector,
  kidsHomeUrlSelector,
  navigationSelector,
  offerLocationSelector,
  userMenuSelector,
} from '../store/slices/application-selectors';
import { displayTVModeSelector } from '../store/slices/displayMode-selectors';
import {
  hasCoverSelector,
  pageDisplayTemplateSelector,
  pagePathSelector,
} from '../store/slices/page-selectors';
import { isPhoneResolutionSelector } from '../store/slices/ui-selectors';
import { isKidsProfileSelector } from '../store/slices/user-selectors';
import { isDetailV5DisplayTemplateInSSRSelector } from './detailV5-selectors';

const defaultLocale = getPublicConfig().defaultLocale;

export const isHeaderNavDropdownEnabledSelector = createSelector(
  isMobileSelector,
  isPhoneResolutionSelector,
  isClientRenderSourceSelector,
  (isMobileDevice, isPhoneResolution, isClientRenderSource) =>
    isClientRenderSource ? !(isMobileDevice && isPhoneResolution) : false
);

const isShowcaseTemplateSelector = createSelector(
  pageDisplayTemplateSelector,
  (pageDisplay) => pageDisplay === Template.Showcase
);

export const isCreativeMediaTemplateSelector = createSelector(
  pageDisplayTemplateSelector,
  (pageDisplay) => pageDisplay === Template.CreativeMedia
);

export const isHeaderTransparentSelector = createSelector(
  hasCoverSelector,
  isShowcaseTemplateSelector,
  isCreativeMediaTemplateSelector,
  isDetailV5DisplayTemplateInSSRSelector,
  (
    hasCover,
    isShowcaseTemplate,
    isCreativeMediaTemplate,
    isDetailV5DisplayTemplateInSSR
  ) =>
    hasCover ||
    isShowcaseTemplate ||
    isCreativeMediaTemplate ||
    isDetailV5DisplayTemplateInSSR
);

export const rootPathSelector = createSelector(
  offerLocationSelector,
  kidsHomeUrlSelector,
  isKidsProfileSelector,
  pagePathSelector,
  (offerLocation, kidsHomeUrl, isKidsProfile, pagePathname) => {
    let rootPath =
      offerLocation === defaultLocale.offerLocation ? '' : `/${offerLocation}`;

    // Temporary dev for german-speaking switzerland (waiting for the multi-language)
    if (pagePathname.includes('/ch/de')) {
      rootPath = '/ch/de';
    }

    // If the user is on a kids profile, we need to redirect to the kids home page without slash
    return kidsHomeUrl && isKidsProfile
      ? `${rootPath}${kidsHomeUrl}`
      : `${rootPath}/`;
  }
);

export const isHomePageSelector = createSelector(
  rootPathSelector,
  pagePathSelector,
  (rootUrl, pagePathname) => pagePathname === rootUrl
);

export const activeNavigationPathSelector = createSelector(
  navigationSelector,
  userMenuSelector,
  pagePathSelector,
  rootPathSelector,
  (navItems, userMenu, pagePathname, rootUrl) => {
    const navAndUserMenuItems = navItems.concat(userMenu);
    const decodedPathname = decodeURIComponent(pagePathname);
    const index = getHeaderActiveIndex(
      rootUrl,
      decodedPathname,
      navAndUserMenuItems
    );

    return navAndUserMenuItems[index]?.onClick?.path as string;
  }
);

export const isHeaderActiveOnTVSelector = createSelector(
  displayTVModeSelector,
  isFirstLevelPageSelector,
  (isTvDevice, isFirstLevelPage) => isTvDevice && isFirstLevelPage
);

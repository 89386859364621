import type { HodorSdkConfig } from '@canalplus/sdk-hodor';
import type {
  ApiV2Article,
  ApiV2DetailLight,
  ApiV2DetailV5,
  ApiV2Episodes,
  ApiV2ExpertModeHighlightPage,
  ApiV2FunnelComTypesPage,
  ApiV2LiveGridPage,
  ApiV2SpyroSearch,
  EpgGridPage,
} from '@dce-front/hodor-types';
import type {
  ApiV2Context,
  ApiV2CurrentPageAlternate,
  ApiV2OnClick,
} from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import type { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import type { ApiV2MoreInfos } from '@dce-front/hodor-types/api/v2/detail/spyro/definitions';
import type { ApiV2PageTracking } from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import type { ApiV2PageGabaritList } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/gabarit_list/definitions';
import type { ApiV2SearchPage } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/search';
import type { ApiV2PageSectionsList } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/sections_list';
import type { ProgramGuide } from '@dce-front/hodor-types/modules/page_container_content/electronic_program_guide/definitions';
import type { InfiniteData } from '@tanstack/react-query';
import type { ContentGridTemplateItemProps } from '../components/ContentGrid/ContentGridTemplateItem';
import type { Meta } from '../helpers/metaTags/types';
import type { FormatContentGridType } from '../templates/ContentGrid/data/formater';
import type { ContentGridState } from '../templates/ContentGrid/data/types';
import type { HighLightState } from '../templates/ContentGridHighlights/data/types';
import type {
  ContentGridPersoState,
  FormattedStubPage,
} from '../templates/ContentGridPerso/data/types';
import type { ContentRowLiveData } from '../templates/ContentRowLive/data/formatter';
import type { ContentRowPersoData } from '../templates/ContentRowPerso/data/formater';
import type { IContentRowStandardState } from '../templates/ContentRowStandard/data/types';
import type {
  FormattedEpisodes,
  InitialDataEpisodes,
} from '../templates/DetailV5/components/EpisodesList/EpisodesList.types';
import type { IMoreInfosState } from '../templates/DetailV5/components/MoreInfos/data/types';
import type {
  LandingV5State,
  StrateV5,
} from '../templates/LandingV5/data/formatter';
import type { ILiveGridState } from '../templates/LiveGrid/data/types';
import type { ILiveGridFavoritesState } from '../templates/LiveGridFavorites/data/types';
import type { IMosaicState } from '../templates/Mosaic/data/formatter';
import type { IMultiLiveSetupState } from '../templates/MultiLiveSetup/data/types';
import type { IMyAccountIdp } from '../templates/MyAccountIdp/data/types';
import type { ISearch } from '../templates/Search/data/types';
import type { ISearchStandaloneState } from '../templates/SearchStandalone/data/formatter';
import type { ISectionsListState } from '../templates/SectionsList/data/types';
import type { IShowcaseState } from '../templates/Showcase/data/formater';
import type { IStubState } from '../templates/Stub/data/formatter';
import type { LocationStateContext } from '../typings/routing';

export enum FetchRequestTypes {
  ActionLayout = 'ActionLayout',
  Avatar = 'Avatar',
  AvatarV2 = 'AvatarV2',
  ContentGrid = 'ContentGrid',
  ContentGridPerso = 'ContentGridPerso',
  ContentRowLive = 'ContentRowLive',
  ContentRowPerso = 'ContentRowPerso',
  ContentRowPersoContainer = 'ContentRowPersoContainer',
  ContentRowStandard = 'ContentRowStandard',
  CreativeMedia = 'CreativeMedia',
  ContextualOffer = 'ContextualOffer',
  DetailLight = 'DetailLight',
  DetailV5 = 'DetailV5',
  DownloadToGo = 'DownloadToGo',
  EPG = 'EPG',
  EPGGrid = 'EPGGrid',
  EpisodeContainer = 'EpisodeContainer',
  EpisodeListContainer = 'EpisodeListContainer',
  Error = 'Error',
  FunnelPaymentV5 = 'FunnelPaymentV5',
  GabaritList = 'GabaritList',
  Highlights = 'Highlights',
  LandingV5 = 'LandingV5',
  LiveGrid = 'LiveGrid',
  LiveGridFavorites = 'LiveGridFavorites',
  LiveGridFavoritesContainer = 'LiveGridFavoritesContainer',
  LiveTv = 'LiveTv',
  MediaUrl = 'MediaUrl',
  MediaUrlPerso = 'MediaUrlPerso',
  MoreInfos = 'MoreInfos',
  Mosaic = 'Mosaic',
  MultiLive = 'MultiLive',
  MyAccountIdp = 'MyAccountIdp',
  PaymentMeans = 'PaymentMeans',
  PrivacyManager = 'PrivacyManager',
  Search = 'Search',
  SearchContainer = 'SearchContainer',
  SearchStandalone = 'SearchStandalone',
  SectionsList = 'SectionsList',
  Settings = 'Settings',
  ShowcaseContainer = 'ShowcaseContainer',
  Slideshow = 'Slideshow',
  Stub = 'Stub',
  TextBrut = 'TextBrut',
  SportVitrine = 'SportVitrine',
  SportRanking = 'SportRanking',
}

export type FetchOptions = {
  requestId?: string;
  profileId?: number;
  isPerso?: boolean;
  queryKeyPrefix?: string | string[];
  debug?: boolean;
};

type WithHodorSdkConfig<T extends FetchDetails> = Omit<T, 'hodorSdkConfig'> & {
  hodorSdkConfig: HodorSdkConfig;
};

export type FetchDetails = {
  template: FetchRequestTypes;
  headers?: [string, string][] | Record<string, string> | Headers;
  noTracking?: boolean;
  onClickParameters?: ApiV2OnClick['parameters'];
  options?: FetchOptions;
  /**
   * Indicate if it should dispatch the generic errorTemplate action instead of standard error throw, when the query is on error.
   * In some cases, we don't want display the generic error template (example: The Slideshow in notification mode apps/mycanal/src/templates/Slideshow/components/SlideshowNotifications.tsx)
   * @default true
   */
  dispatchErrorTemplate?: boolean;
  fetchOptions?: HodorSdkConfig['fetchOptions'];
  isMainTemplate?: boolean;
};

export type FetchDetailsWithHodorSdkConfig = WithHodorSdkConfig<FetchDetails>;

export type QueryParams = {
  [key: string]: string;
};

export type FormatterProps = {
  activeRubricDisplayName?: string;
  blackListedChannels?: number[];
  dispatch?: Redux.Dispatch;
  featIdentityV5?: boolean;
  isFeatDetailTabHighlights?: boolean;
  isFeatDetailTabStatistics?: boolean;
  isFeatDetailTabRating?: boolean;
  isFeatDetailTabRanking?: boolean;
  isFeatDetailTabTimeline?: boolean;
  isFeatShortVideoList?: boolean;
  isIOS?: boolean;
  isLiveGridFavoritesEnabled?: boolean;
  trackingContext?: ApiV2Context;
};

export type UniversalServiceProps = {
  fetchDetails: FetchDetailsWithHodorSdkConfig;
  formatterProps?: FormatterProps;
  url: string | undefined;
  onError?: () => void;
  routingContext?: LocationStateContext;
};

export type UniversalFormatterProps = {
  formatterProps: FormatterProps;
  hodorResponse: HodorResponseType;
  template: FetchRequestTypes;
};

export type ErrorItem = {
  template: FetchRequestTypes | 'default';
  message: string;
};

export type ApiHeaders = HeadersInit & {
  tokenPass?: string;
};

export interface ApiOptions extends FetchOptions {
  headers: ApiHeaders;
}

export interface ITextBrutState {
  currentPage?: {
    alternate?: ApiV2CurrentPageAlternate;
    BOName?: string;
    displayName?: string;
    displayTemplate?: string;
    path?: string;
  };
  tracking?: Tracking;
  meta?: {
    title?: string;
  };
  title?: string;
  text?: string;
}

export interface IPrivacyManagerState {
  currentPage?: {
    BOLayoutName?: string;
    BOName?: string;
    displayName?: string;
    displayTemplate?: string;
    path?: string;
  };
  meta?: {
    title?: string;
  };
  pageParameters?: {
    URLLegalTerms?: string;
  };
  tracking?: Tracking;
}

// Generic query handler result used in useQueryTemplate and useInfinitQueryHandler
export type CommonQueryHandlerResult = {
  tracking?: Tracking | ApiV2PageTracking;
  meta?: Meta;
  currentPage?: {
    displayName?: string | null;
    displayTemplate?: string | null;
  };
  context?: ApiV2Context;
};

type AllQueryHandlerResult<T extends GenericOutput> = Partial<
  CommonQueryHandlerResult & InfiniteData<T>
>;

// Simplified version of the response from Hodor with include all types and added props needed for metas and tracking
export type FormatterResult<T extends GenericOutput> = T &
  AllQueryHandlerResult<T>;

type ProgramGuideTimeSlice = Pick<ProgramGuide, 'timeSlices' | 'rubriques'> & {
  type: string;
};

// This type group all possible responses from Hodor
export type GenericOutput =
  | ApiV2Article
  | ApiV2DetailV5
  | ApiV2DetailLight
  | ApiV2Episodes
  | ApiV2MoreInfos
  | ApiV2FunnelComTypesPage
  | ApiV2LiveGridPage
  | ApiV2PageGabaritList
  | ApiV2SearchPage
  | ApiV2PageSectionsList
  | ApiV2SpyroSearch
  | ContentGridPersoState
  | ContentGridState
  | ContentGridTemplateItemProps
  | ContentRowLiveData
  | EpgGridPage
  | FormatContentGridType
  | FormattedEpisodes
  | FormattedStubPage
  | ContentRowPersoData
  | IContentRowStandardState
  | HighLightState
  | ILiveGridFavoritesState
  | ILiveGridState
  | IMoreInfosState
  | IMosaicState
  | IMultiLiveSetupState
  | IMyAccountIdp
  | InitialDataEpisodes
  | IPrivacyManagerState
  | ISearch
  | ISearchStandaloneState
  | ISectionsListState
  | IShowcaseState
  | IStubState
  | ITextBrutState
  | LandingV5State
  | ProgramGuideTimeSlice
  | StrateV5;

// This type is exactly same as the one above
// Not really ergonomic but essential to have those exactly same
export type HodorResponseType = ApiV2DetailV5 &
  ApiV2Article &
  ApiV2Episodes &
  ApiV2FunnelComTypesPage &
  ApiV2LiveGridPage &
  ApiV2PageGabaritList &
  ApiV2SearchPage &
  ApiV2PageSectionsList &
  ApiV2SpyroSearch &
  ApiV2MoreInfos &
  ContentGridPersoState &
  ContentGridState &
  ContentGridTemplateItemProps &
  ContentRowLiveData &
  EpgGridPage &
  FormatContentGridType &
  FormattedEpisodes &
  FormattedStubPage &
  ApiV2ExpertModeHighlightPage &
  ContentRowPersoData &
  IContentRowStandardState &
  ILiveGridFavoritesState &
  ILiveGridState &
  IMoreInfosState &
  IMosaicState &
  IMultiLiveSetupState &
  IMyAccountIdp &
  InitialDataEpisodes &
  IPrivacyManagerState &
  ISearch &
  ISearchStandaloneState &
  ISectionsListState &
  IShowcaseState &
  IStubState &
  ITextBrutState &
  LandingV5State &
  ProgramGuideTimeSlice &
  StrateV5;

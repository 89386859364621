import type { ApiV2SpyroStrateContentSticker } from '@dce-front/hodor-types/api/v2/common/dto/stickers/definitions';

// TODO This constant will be removed when all types of Sticker will be handled by Hodor
const AVAILABLE_HODOR_STICKER_TYPES: ApiV2SpyroStrateContentSticker['type'][] =
  ['offered-content', 'free-to-air', 'last-days', 'get', 'subscribe'];

// These types of sticker will be soon available, so we only add the toggle for these ones
// They will be removed progressively
const UPCOMING_HODOR_STICKERS: ApiV2SpyroStrateContentSticker['type'][] = [
  'last-days',
];

/**
 * This function will extract the hodor sticker from the stickers object returned by hodor
 * It will also check if the type of sticker is available, and check the feat toggle for some
 * types not handle now by Hodor (see: https://canal-wiki.canal-plus.com/display/2M/Chantiers+stickers+centralisation)
 * @param stickers ApiV2SpyroStrateContentSticker[]
 * @param isFeatUpcomingHodorSticker boolean
 * @returns
 */
export const getHodorSticker = (
  stickers?: ApiV2SpyroStrateContentSticker[],
  isFeatUpcomingHodorSticker = false
): ApiV2SpyroStrateContentSticker | undefined => {
  if (
    !stickers?.[0] ||
    !AVAILABLE_HODOR_STICKER_TYPES.includes(stickers[0].type)
  ) {
    return undefined;
  }

  if (
    UPCOMING_HODOR_STICKERS.includes(stickers[0].type) &&
    !isFeatUpcomingHodorSticker
  ) {
    return undefined;
  }

  return stickers[0];
};

import { OfferLocation } from '@canalplus/sdk-core';
import type { BranSdkCurrentPageConfiguration } from '@dce-front/hodor-types/sdks/bran';
import type { MetasType } from '../../../config/application/types';
import type { ImmersiveMeta } from '../../components/Immersive/types';
import { myCanalTitlesMapping } from '../../constants/international';
import { getPublicConfig } from '../config/config-helper';
import type {
  IAlternateLink,
  IBuildUpdatedMetaParams,
  IMetaTag,
} from './types';

/** Get twitter metas from state */
export const getTwitterMetasFromState = (data: {
  description: string;
  urlImage?: string;
  title?: string;
  isLargeImage?: boolean;
  twitterAccount?: string;
}): IMetaTag[] => {
  const { title, description, urlImage, isLargeImage, twitterAccount } = data;

  const twitterMetaDatas = getTwitterMetaDatas({
    isLargeImage,
    site: twitterAccount,
    title,
    description,
    image: urlImage,
  });

  return twitterMetaDatas.map((metaData) => ({
    ...metaData,
    name: `twitter:${metaData.name}`,
  }));
};

/**
 * Helpers providing methods to work with metas tags
 */
/**
 * @param  {array}  metaTagsArray    Object source that contains the meta tags of the page
 * @param  {array}  newMetaTagsArray Object that contains the updated metas tags for each page
 * @returns {string}
 */
export const mergeMetaTags = (
  metaTagsArray: IMetaTag[],
  newMetaTagsArray: IMetaTag[]
): IMetaTag[] =>
  metaTagsArray.map((meta) => {
    let foundMeta;
    if (meta.name) {
      foundMeta = newMetaTagsArray.find(
        (obj: IMetaTag) => obj.name === meta.name
      );
    }
    if (meta.property) {
      foundMeta = newMetaTagsArray.find(
        (obj: IMetaTag) => obj.property === meta.property
      );
    }

    return foundMeta || meta;
  });

export const getAppleMediaExpiresContentMetaValue = (
  isAuthenticated: boolean,
  userStatus?: string
): 'free' | 'subscription' | 'prospect' => {
  const {
    user_status: { abonne, prospect },
  } = getPublicConfig();
  if (!isAuthenticated) {
    return 'free';
  }

  return userStatus === abonne ? 'subscription' : prospect;
};

export const getAppleMediaContentMetaTags = (
  microEligibility: string,
  isAuthenticated: boolean,
  userStatus?: string
): string => {
  /** 31 days */
  const expires = 2_678_400;

  const tiers = microEligibility
    .replace(/,?\w+:/g, '') // Remove the Macro
    .replace(/\]\[/g, ',') // Remove `][` left
    .replace(/\b/g, '\\"'); // Add `\"` at the end and begining of the micro

  const availabilityType = getAppleMediaExpiresContentMetaValue(
    isAuthenticated,
    userStatus
  );

  // availabilityType: subscription/free/account
  return `expires=${expires} type={"availabilityType": "${availabilityType}", "tiers": ${tiers}}`;
};

/**
 * Return an array of metas for Twitter simple card sharing
 */
/**
 * @param  {string} displayTemplate    template name
 * @returns {ITwitterMetaData[]}
 */
const renderCardMetas = (isLargeImage = false): IMetaTag[] => [
  {
    name: 'card',
    content: isLargeImage ? 'summary_large_image' : 'summary',
  },
];

export const getTwitterMetaDatas = (data: {
  description: string;
  isLargeImage?: boolean;
  image?: string;
  site?: string;
  title?: string;
}): IMetaTag[] | [] => {
  const { site, title, description, image, isLargeImage } = data;

  const twitterMetaDatas = [
    {
      name: 'site',
      content: site || '',
    },
    {
      name: 'title',
      content: title || '',
    },
    {
      name: 'description',
      content: description || '',
    },
    ...renderCardMetas(isLargeImage),
  ];

  if (image) {
    twitterMetaDatas.push({
      name: 'image',
      content: image || '',
    });
  }

  return twitterMetaDatas;
};

export const defaultAlternateLinks = (
  hostname: string,
  pathname: string
): { hrefLang: string; rel: string; href: string }[] => {
  const alternateLinks = {
    rel: 'alternate',
    href: `https://${hostname}${pathname}`,
  };

  return [
    { ...alternateLinks, hrefLang: 'x-default' },
    { ...alternateLinks, hrefLang: 'fr' },
  ];
};

export const formatPathname = (
  pathname: string,
  offerLocation: string
): string => {
  const splittedPathname = pathname.split(`/${offerLocation}/`);

  return (
    (getPublicConfig().defaultLocale.offerLocation === offerLocation
      ? splittedPathname[0]
      : `/${splittedPathname[1]}`) || ''
  );
};

export type AlternateRecord = BranSdkCurrentPageConfiguration['alternate'];

/**
 * @param  alternates  available **alternates** in the selected page
 * @param  hostname the **hostname** of the current url
 * @param  pathname the **pathname** of the current url
 */
export const getAlternateLinks = (
  alternates: AlternateRecord,
  hostname: string,
  pathname: string,
  offerLocation: OfferLocation
): IAlternateLink[] => {
  if (!alternates || Object.entries(alternates).length === 0) {
    return [];
  }

  const defaultOfferLocation = getPublicConfig().defaultLocale.offerLocation;

  const formattedPathname = formatPathname(pathname, offerLocation);

  const alternateLinks = defaultAlternateLinks(hostname, formattedPathname);
  // TODO : To be deleted when the currentOfferLocation alternate is supported by HODOR
  if (offerLocation !== defaultOfferLocation) {
    alternateLinks.push(
      getAlternateLink('fr', offerLocation, `https://${hostname}${pathname}`)
    );
  }

  Object.entries(alternates).forEach(([zone, value]) => {
    if (value !== null) {
      Object.keys(value).forEach((lang) => {
        if (zone !== defaultOfferLocation) {
          const path = value[lang]?.path;

          if (path) {
            // we can have relative path (ex: "/cn/") or absolute path (ex: "https://www.canalplus.nl/")
            const isRelativePath = path.startsWith('/');
            const href = isRelativePath
              ? `https://${hostname}/${zone}${formattedPathname}`
              : path;
            alternateLinks.push(getAlternateLink(lang, zone, href));
          }
        }
      });
    }
  });

  return alternateLinks;
};

export const buildUpdatedMeta = (
  params: IBuildUpdatedMetaParams
): ImmersiveMeta => {
  const { meta, offerLocation, appKey } = params;
  const myCanalTitle = myCanalTitlesMapping[offerLocation] || '';
  const ret = { ...meta };

  if (meta && meta.title && (meta.description || offerLocation)) {
    ret.title = `${meta.title} | ${myCanalTitle}`;

    // If we are on one of the overlays
    if (appKey === 'canalvod' || !myCanalTitle) {
      ret.title = meta.title;
    }
  }

  return ret;
};

export const getAlternateLink = (
  lang: string,
  zone: string,
  href: string
): IAlternateLink => ({
  rel: 'alternate',
  hrefLang: `${lang}-${zone.toUpperCase()}`,
  href,
});

export const getDocumentHeaderMetasType = (
  offerLocation: OfferLocation
): MetasType | undefined => {
  const publicConfig = getPublicConfig();

  if (!publicConfig.documentHeader) {
    return;
  }

  const documentHeader =
    offerLocation === OfferLocation.pl ||
    offerLocation === OfferLocation.en ||
    offerLocation === OfferLocation.it
      ? publicConfig.documentHeader[offerLocation]
      : publicConfig.documentHeader.default;

  return documentHeader;
};

export enum ModalTypes {
  EXTERNAL_SERVICE_DOWNLOAD_MODAL = 'EXTERNAL_SERVICE_DOWNLOAD_MODAL',
  EXTERNAL_SERVICE_STREAM_MODAL = 'EXTERNAL_SERVICE_STREAM_MODAL',
  GENERIC_MODAL = 'GENERIC_MODAL',
  MOBILE_APP_DOWNLOAD = 'MOBILE_APP_DOWNLOAD',
  STARTOVER_MODAL = 'STARTOVER_MODAL',
  USER_EXITS_SETTINGS_MODAL = 'USER_EXITS_SETTINGS_MODAL',
  USER_SETTINGS_MODAL = 'USER_SETTINGS_MODAL',
  ZONES_LIST_MODAL = 'ZONES_LIST_MODAL',
}

import { mapStaticKey } from '@canalplus/mycanal-util-react';
import type { ApiV2PageStrate } from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import { ShowcaseTypes } from '../../../constants/showcase';
import { getContentsWithContext } from '../../../helpers/contents/contents-helper';
import type { ApiPageShowcase } from './types';

const getFormattedStrates = (strates: ApiV2PageStrate[]) =>
  mapStaticKey(strates, 'type').map((strate) => {
    switch (strate.type) {
      case ShowcaseTypes.TEXT:
      case ShowcaseTypes.TILES:
        if ('contents' in strate) {
          // Coalition of type for this contents is so terrible we can't type it well lol
          // TODO find a way to remove this
          return {
            ...strate,
            contents: mapStaticKey(strate.contents as never[], 'title'),
          };
        }
        return;
      case ShowcaseTypes.CONTENTROW:
        if (!('contents' in strate)) {
          return;
        }
        return 'context' in strate
          ? {
              ...strate,
              contents: getContentsWithContext(strate.contents, strate.context),
            }
          : { ...strate, contents: getContentsWithContext(strate.contents) };

      default:
        return strate;
    }
  });

export type GetFormattedStratesType = ReturnType<typeof getFormattedStrates>;

export type IShowcaseState = Omit<ApiPageShowcase, 'strates' | 'tracking'> & {
  strates?: GetFormattedStratesType;
};

export const getShowcaseDataFormatted = (
  data: ApiPageShowcase
): IShowcaseState => {
  const { strates } = data;
  const formattedStrates = strates ? getFormattedStrates(strates) : undefined;

  return {
    ...data,
    strates: formattedStrates,
  };
};

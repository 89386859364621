/* eslint-disable prefer-const */
import {
  ERROR_CODE_RPC_SERVER,
  ERROR_RPC,
  ERROR_RPC_TIMEOUT,
} from '../constants';
import type { RPC, RPCConfig, RPCResponseError } from '../types';

/**
 * Helper to transform to promise a rpcWithCallback.
 * it call rpcWithCallback and use the callback to resolve or reject the promise.
 * A timeout error (value come from config) is managed by this promise, if the timeout is exceeded, the promise is rejected with an error server
 *
 * @param config Config global of the ifc
 * @param callbacks Dictionary of callbacks stored
 * @param rpcWithCallback Method to to make a rpc call using PostMessage. The callback parameter is called when receive the response
 * @param method The name of the method rpc to call
 * @param params (optional) Data for the call rpc
 */
export const transformToPromise = <TParams = any, TResult = any, TError = any>(
  sendRpc: RPC['sendRpc'],
  config: RPCConfig,
  callbacks: Record<
    number,
    (error: RPCResponseError | undefined, result?: TResult) => void
  >,
  method: string,
  params?: TParams
): Promise<TResult> => {
  return new Promise<TResult>((resolve, reject) => {
    let timeoutRef: NodeJS.Timeout;

    // callback to call when rpc response
    const callbackRPC = (
      error: RPCResponseError<TError> | undefined,
      result?: TResult
    ) => {
      clearTimeout(timeoutRef);

      if (!error) {
        resolve(result as TResult);
        return;
      }

      const { code, data, message } = error;

      reject(
        new Error(
          ERROR_RPC.replace('%1', method).replace('%2', message),
          code || data
            ? {
                cause: {
                  ...(code ? { code } : {}),
                  ...(data ? { data } : {}),
                },
              }
            : undefined
        )
      );
    };

    let uid: number | undefined;

    // call the callback 'callbackRPC' with rpc error if the timeout time is expired
    timeoutRef = setTimeout(() => {
      if (uid) {
        delete callbacks[uid];
      }

      callbackRPC({
        code: ERROR_CODE_RPC_SERVER,
        message: ERROR_RPC_TIMEOUT.replace('%1', config.timeout.toString()),
      });
    }, config.timeout);

    // send the rpc with callback method 'callbackRPC'
    uid = sendRpc(method, params, callbackRPC);
  });
};

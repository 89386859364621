import { Template } from '@canalplus/sdk-hodor';
import type { PassUserDataEnriched } from '@canalplus/sdk-pass';
import Cookies from 'js-cookie';
import { batch } from 'react-redux';
import { CookieValue } from '../../constants/trackingtool';
import Logger from '../../helpers/logger/logger-helper';
import { newrelicSendUserInfo } from '../../helpers/newrelic/newrelic-helper';
import { markPerformance } from '../../helpers/performance/markPerformance';
import { getClientSideUserInfos } from '../../helpers/user/getClientSideUserInfos';
import { shouldUsePassCache } from '../../helpers/user/user-helper';
import {
  getFeatureToggleNewrelic,
  getFeatureToggleUserCentricConsent,
  offerLocationSelector,
} from '../../store/slices/application-selectors';
import { pageDisplayTemplateSelector } from '../../store/slices/page-selectors';
import { findTrackingSelector } from '../../store/slices/tracking-selectors';
import { getPassToken, receiveUserInfos } from '../../store/slices/user';
import { authenticatedSelector } from '../../store/slices/user-selectors';

/**
 * Checking SSO Pass Service to avoid de-synchronization of tokenPass
 * INFO: subscriberId is the source of truth to certify that user is well connected
 * Expected behavior (last update 14/08/2017)
 * - authenticated server side and authenticated client side: call to getUserListProfile
 * - [NOT ANYMORE TO AVOID INFINITE LOOP] authenticated client side but not server side: clean TokenCMS and cache and reload the page
 * - not authenticated at all: do nothing (the user will be redirected to the default prospect page)
 * @param store Redux store
 * @param inputUserInfos Provided user info (eg. one-core call to getProfile)
 * @returns A promise which resolves one authentication is done
 */
export async function PassAuth(
  store: Redux.CustomStore,
  inputUserInfos?: PassUserDataEnriched
): Promise<void> {
  const state = store.getState();

  const offerLocation = offerLocationSelector(state);
  const isNewrelicEnabled = getFeatureToggleNewrelic(state);
  const authenticated = authenticatedSelector(state);
  const isUserCentricConsentMode = getFeatureToggleUserCentricConsent(state);

  const passCache = shouldUsePassCache();
  let userInfos: PassUserDataEnriched;

  if (isUserCentricConsentMode) {
    // If the user is a prospect and if they already gave their consent, make the first createToken call
    // with consent stored in cookies
    const settings = !authenticated
      ? {
          analytics:
            Cookies.get('statsAllowed') !== undefined
              ? Cookies.get('statsAllowed') === CookieValue.True
              : undefined,
          trackingPub:
            Cookies.get('adsAllowed') !== undefined
              ? Cookies.get('adsAllowed') === CookieValue.True
              : undefined,
          anonymousTracking:
            Cookies.get('anonymousAllowed') !== undefined
              ? Cookies.get('anonymousAllowed') === CookieValue.True
              : undefined,
        }
      : {};

    userInfos =
      inputUserInfos ||
      (await getClientSideUserInfos(state, {
        noCache: !passCache,
        ...settings,
      }));
  } else {
    userInfos =
      inputUserInfos ||
      (await getClientSideUserInfos(state, { noCache: !passCache }));
  }

  markPerformance('pass-auth-fetched', state);

  Logger.debug('Client::auth userinfos', userInfos);

  const userData = {
    ...userInfos,
    // client side, we receive '' when no subscriberId so we clean this up
    subscriberId: Number(userInfos.subscriberId),
    isPartner: false,
  };

  batch(() => {
    // persist the pass token into the store
    store.dispatch(getPassToken(userInfos.passToken));
    // Update user data in store with userInfos
    store.dispatch(receiveUserInfos(userData));
  });

  // Authenticated is from the server side
  if (!authenticated) {
    return;
  }

  if (isNewrelicEnabled) {
    const pageNameTracking = findTrackingSelector(store.getState()).dataLayer;
    const pageName = pageNameTracking?.page_name || '';

    newrelicSendUserInfo(
      userData,
      pageDisplayTemplateSelector(state) === Template.Error
        ? `${Template.Error}Page`
        : '',
      pageName,
      offerLocation
    );
  }
}

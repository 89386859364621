import type { HodorApiParameter, HodorSdkConfig } from '../config/types';
import { fetchHodor } from '../helpers/fetchHodor';
import type { FetchHodorParameters } from '../helpers/types';

export const HODOR_API_PARAMETERS: HodorApiParameter[] = [
  {
    id: 'cmsToken',
    in: 'path',
  },
];

const followRedirect = async ({
  api,
  body,
  hodorSdkConfig,
  hodorSdkParameters,
  startAt,
  url,
}: FetchHodorParameters): Promise<object> => {
  const { logger } = hodorSdkConfig;

  const response = await fetchHodor({
    api,
    body,
    hodorSdkConfig,
    hodorSdkParameters: [
      ...HODOR_API_PARAMETERS,
      ...(hodorSdkParameters || []),
    ],
    url,
    startAt,
  });

  const jsonResponse = await response.json();

  logger.debug(`HODOR ${api} Response:`, jsonResponse);

  const { URLPage } = jsonResponse?.redirectTo || {};

  if (URLPage) {
    return followRedirect({
      url: URLPage,
      api: 'followRedirect',
      hodorSdkConfig,
      startAt,
    });
  }

  return jsonResponse;
};

export type RequestPageParameters = {
  url: string;
  body?: BodyInit;
  parameters?: HodorApiParameter[];
};

/**
 * **requestPage** is used to fetch almost every Hodor APIs, except **init**, **authenticate** and **wsFromPath**,\
 * depending on the **url** provided.
 */
export const requestPage = async (
  hodorSdkConfig: HodorSdkConfig,
  { body, url, parameters: hodorSdkParameters }: RequestPageParameters
): Promise<object> => {
  const response = await followRedirect({
    api: 'requestPage',
    body,
    hodorSdkConfig,
    hodorSdkParameters,
    startAt: Date.now(),
    url,
  });

  return response;
};

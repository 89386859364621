import { rpc, type RPCResponse } from '@canalplus/ifc';
import type { OneCoreKeyName } from '@canalplus/onecore-types';
import { catchErrorTimeout } from './helpers/promiseErrors';
import type { GrabKeyResponse, KeysManager } from './types';

export const getKeysManager = (): KeysManager => {
  const keys: Record<string, ((result: GrabKeyResponse) => void)[]> = {};

  const grabKey = async (
    key: OneCoreKeyName,
    callback: (result: GrabKeyResponse) => void
  ) => {
    if (!keys[key]) {
      keys[key] = [];
    }
    keys[key].push(callback);

    // send addKeys only if is the first callback.
    // It's to not call addKeys multiple time
    if (keys[key].length === 1) {
      await catchErrorTimeout(rpc<string[], void>('addKeys', [key]));
    }
  };

  const releaseKey = async (
    key: OneCoreKeyName,
    callback: (result: GrabKeyResponse) => void
  ) => {
    const indexToDelete = keys[key]
      ? keys[key].findIndex((callbackArg) => {
          return callbackArg === callback;
        })
      : -1;

    if (indexToDelete !== -1) {
      keys[key]?.splice(indexToDelete, 1);
    }

    // send removeKeys only if no callbacks in keys[key]
    if (keys[key]?.length === 0) {
      await catchErrorTimeout(rpc<string[], void>('removeKeys', [key]));
    }
  };

  const onMessage = (data: RPCResponse) => {
    // keys event use property key
    if (!data.key) {
      return;
    }

    const key: string = data.key as string;

    /**
     * Special case OneCore Numeric key.
     * When we receive key with pattern 'Numeric[0-9]', we need call the callback corresponding the key 'Numeric'
     * and add the property number in the result callback
     */
    let newKey = key;
    let number: number | undefined;

    const res = key.match(/Numeric([0-9])/i);
    if (res && res[1] !== undefined) {
      newKey = 'Numeric';
      number = +res[1];
    }

    if (!keys[newKey]) {
      return;
    }

    keys[newKey]?.forEach((callbackArg) => {
      callbackArg({ ...data, key: newKey, ...(res ? { number } : {}) });
    });
  };

  return { grabKey, releaseKey, onMessage };
};

import { useOnFocusable } from '../../helpers/hooks/useOnFocusable';
import { FocusManager } from '../../helpers/oneNavigation/FocusManager';

export type OnFocusableProbeProps = {
  focusManager?: FocusManager;
  focusable?: boolean;
};
/**
 * A component that allows to declaratively call the onFocusable callback at the end of the chain of lazy loaded components
 * Sometimes, these lazy loaded components could be imported from external sources (sharedcomponent, dive, etc...)
 * And since passing the `onFocusable` callback as a prop of the component is not always possible in such case (due to, for example, separation of responsibilities or no access to component's codebase)
 * We provide this `probe` component, which can be invoked directly alongside the children of the lazy loaded component
 * @param param0 a callback that sets the focus and a boolean to control if the focus is ready to be set or not
 * @returns nothing
 * @example
 * <ExternalLoadableComponent>
 *    <OnFocusableProbe focusManager={focusManager} focusable={!!contents.length} />
 *    // ...some other children...
 * </ExternalLoadableComponent>
 */

export function OnFocusableProbe({
  focusManager,
  focusable,
}: OnFocusableProbeProps): null {
  useOnFocusable(focusManager, focusable);

  return null;
}

import { TitleDisplayMode } from '@canalplus/sdk-hodor';
import classNames from 'classnames/bind';
import type { JSX } from 'react';
import { Children, cloneElement, useMemo } from 'react';
import { HorizontalListPageButton } from '../HorizontalList/HorizontalListPageButton/HorizontalListPageButton';
import { HorizontalListLazyLoader } from '../HorizontalListLazyLoader/HorizontalListLazyLoader';
import styles from './ContentRowHorizontalScroller.module.css';
import type { ContentRowProps } from './types';

const cx = classNames.bind(styles);

export type ContentRowHorizontalScrollerProps = Pick<
  ContentRowProps,
  | 'defaultItemOffset'
  | 'getNextContent'
  | 'imageSize'
  | 'isFromDetail'
  | 'isFromImmersive'
  | 'labels'
  | 'ratio'
  | 'showControls'
  | 'titleDisplayMode'
  | 'scrollDuration'
> & {
  children: React.ReactElement[];
  isCarrousel?: boolean;
  isOrderedList?: boolean;
  arrowPositionAuto?: boolean;
};

function ContentRowHorizontalScroller({
  children,
  defaultItemOffset = -1,
  getNextContent,
  imageSize = 'normal',
  isCarrousel = false,
  isFromDetail = false,
  isFromImmersive = false,
  arrowPositionAuto = false,
  isOrderedList = false,
  labels,
  ratio,
  showControls = true,
  titleDisplayMode = TitleDisplayMode.All,
  scrollDuration = 200,
}: ContentRowHorizontalScrollerProps): JSX.Element {
  const buttonPrevious = useMemo(
    () => (
      <HorizontalListPageButton
        className={cx(
          'ContentRowHorizontalScroller__pageButton',
          'ContentRowHorizontalScroller__pageButton--previous',
          {
            'ContentRowHorizontalScroller__pageButton--arrowPositionAuto':
              arrowPositionAuto,
          }
        )}
        type="previous"
        title={labels.previous}
      />
    ),
    [labels.previous, arrowPositionAuto]
  );

  const buttonNext = useMemo(
    () => (
      <HorizontalListPageButton
        className={cx(
          'ContentRowHorizontalScroller__pageButton',
          'ContentRowHorizontalScroller__pageButton--next',
          {
            'ContentRowHorizontalScroller__pageButton--arrowPositionAuto':
              arrowPositionAuto,
          }
        )}
        type="next"
        title={labels.next}
      />
    ),
    [labels.next, arrowPositionAuto]
  );

  return (
    <div
      className={cx('ContentRowHorizontalScroller', {
        [`ContentRowHorizontalScroller--ratio${ratio}${imageSize}`]:
          !arrowPositionAuto,
        'ContentRowHorizontalScroller--detailV5': isFromDetail,
        'ContentRowHorizontalScroller--logoTyped':
          titleDisplayMode === TitleDisplayMode.LogoTyped,
        'ContentRowHorizontalScroller--immersive': isFromImmersive,
        'ContentRowHorizontalScroller--carrousel': isCarrousel,
      })}
    >
      <HorizontalListLazyLoader
        activeIndex={defaultItemOffset}
        buttonPrevious={buttonPrevious}
        buttonNext={buttonNext}
        getNextContent={getNextContent}
        showControls={showControls}
        scrollDuration={scrollDuration}
        isOrderedList={isOrderedList}
        ratio={ratio}
        imageSize={imageSize}
      >
        {Children.map(children, (child) => cloneElement(child))}
      </HorizontalListLazyLoader>
    </div>
  );
}

export default ContentRowHorizontalScroller;

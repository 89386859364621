import type { ApiV2NavigationNotification } from '@dce-front/hodor-types/api/v2/authenticate/definitions';
import type { JSX } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import {
  saveNotificationNextDisplayDate,
  shouldShowNotification,
  type NotificationParams,
} from '../../../helpers/notification/notification-helper';
import { setSlideShowNotificationOpen } from '../../../store/slices/application';
import { getStartupNotificationsSelector } from '../../../store/slices/application-selectors';
import { accountIdSelector } from '../../../store/slices/user-selectors';
import LoadableSlideshowNotifications from './LoadableSlideshowNotifications';

function SlideshowNotificationsContainer(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const startupNotifications = useSelector(getStartupNotificationsSelector);
  const [startupNotificationList, setStartupNotificationList] =
    useState(startupNotifications);
  const [currentSlideshow, setCurrentSlideshow] = useState<
    ApiV2NavigationNotification | undefined
  >(undefined);
  const accountId = useSelector(accountIdSelector);

  // update local state startupNotificationList if startupNotifications from store changes
  useEffect(() => {
    setStartupNotificationList(startupNotifications);
  }, [startupNotifications]);

  // Check if has startupNotifications slideshow to display
  useEffect(() => {
    if (
      // Not display slideshow if the popin RGPD is displayed.
      // Because the popin RGPD will reload the app after accept consent and we have to see slideshow again.
      !window.Didomi?.shouldUserStatusBeCollected() &&
      startupNotificationList &&
      !currentSlideshow &&
      accountId !== undefined
    ) {
      const newStartupSlideshowToDisplay = startupNotificationList.find(
        ({ contentID, onTrigger, triggers }) =>
          shouldShowNotification({
            accountId,
            appNextStartup: triggers?.appNextStartup,
            contentID: contentID,
            daysBetweenTriggers: (triggers as any)?.daysBetweenTriggers, // @todo remove 'as any' when hodor types 'triggers' will have property daysBetweenTriggers
            displayTemplate:
              onTrigger?.displayTemplate as NotificationParams['displayTemplate'],
          })
      );

      if (
        newStartupSlideshowToDisplay?.contentID &&
        newStartupSlideshowToDisplay.triggers
      ) {
        setCurrentSlideshow(newStartupSlideshowToDisplay);
        dispatch(setSlideShowNotificationOpen(true));

        saveNotificationNextDisplayDate({
          accountId,
          contentID: newStartupSlideshowToDisplay.contentID,
          displayTemplate: newStartupSlideshowToDisplay.onTrigger
            ?.displayTemplate as NotificationParams['displayTemplate'],
          daysBetweenTriggers: (newStartupSlideshowToDisplay.triggers as any)
            .daysBetweenTriggers, // @todo remove 'as any' when hodor types 'triggers' will have property daysBetweenTriggers
        });
      }
    }
  }, [
    startupNotificationList,
    currentSlideshow,
    setCurrentSlideshow,
    accountId,
    dispatch,
  ]);

  /**
   * In case the currentSlideshow is on error, check if a next slideshow can be displayed
   */
  const displayNextSlideshow = useCallback(() => {
    if (startupNotificationList && startupNotificationList.length > 1) {
      // Remove the slideshow on error from the list
      const newStartupNotifications: ApiV2NavigationNotification[] = [];
      startupNotificationList.forEach((item) => {
        if (item.contentID !== currentSlideshow?.contentID) {
          newStartupNotifications.push(item);
        }
      });

      setStartupNotificationList(newStartupNotifications);
      setCurrentSlideshow(undefined);
    }
  }, [
    startupNotificationList,
    currentSlideshow,
    setStartupNotificationList,
    setCurrentSlideshow,
  ]);

  const { onTrigger: { parameters, URLPage } = {}, contentID } =
    currentSlideshow || {};

  return URLPage && contentID ? (
    <LoadableSlideshowNotifications
      url={URLPage}
      onClickParameters={parameters}
      onError={displayNextSlideshow}
    />
  ) : null;
}

export default SlideshowNotificationsContainer;

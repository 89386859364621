import type { OneCoreParentalCodeExist } from '@canalplus/ifc-onecore';
import { navigate, parentalCodeExists } from '@canalplus/ifc-onecore';

/**
 * mustEnableAdult
 *
 * this function check that the user has read the disclaimer,
 * and either has adult and not set the parental code,
 * or doesn't have adult in subscription
 * @param  {bool} adultInOffer   whether the user has adult in his subscription
 * @param  {bool} adultEnabled   whether the user has initialized adult code
 * @param  {bool} isInitialized
 * @returns {bool}
 */
export const mustEnableAdult = (
  adultInOffer: boolean,
  adultEnabled: boolean,
  isInitialized: boolean
): boolean => isInitialized && !(adultInOffer && adultEnabled);

/**
 * Open the parental code popin OneCore.
 * Return a promise with a boolean indicate if the parental code enter process is success or not
 * @param  {boolean} noDisclaimer indicate if oneCore display the disclaimer or not
 * @returns Promise<boolean>
 */
export const openOneCoreParentalCode = (
  noDisclaimer: boolean = true
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    navigate({
      control: 'parental_auth',
      context: { noDisclaimer },
    })
      .then((result) => {
        resolve(result.success);
      })
      .catch((error) => {
        if (error) {
          return reject(error);
        }
      });
  });
};

/**
 * Get information from oneCore about code parental status (exist and locked)
 * Return a promise with a boolean indicate if the parental code enter process is success or not
 * @returns Promise<OneCoreParentalCodeExist> object OneCoreParentalCodeExist
 */
export const getOneCoreParentalCodeExist =
  (): Promise<OneCoreParentalCodeExist> => {
    return parentalCodeExists().catch((error: Error) => {
      if (error && error.message.toLowerCase().includes('method not found')) {
        // by default consider exist
        return {
          exists: true,
          locked: false,
          parentalCodeSettingForUserOverloadableContents: false,
          isParentalCodeActivatedForUserOverloadableContents: false,
        };
      }
      throw error;
    });
  };

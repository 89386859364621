import type { ApiV2PageTracking } from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import type {
  ApiV2SearchCurrentPage,
  ApiV2SearchPage,
} from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/search/definitions';
import type { SearchPageParameters } from '@dce-front/hodor-types/modules/page/definitions';

export interface ISearchStandaloneState {
  currentPage?: ApiV2SearchCurrentPage;
  pageParameters?: SearchPageParameters;
  tracking?: ApiV2PageTracking;
}

const getFormattedSearchStandalone = (
  data: ApiV2SearchPage
): ISearchStandaloneState => {
  const { currentPage, tracking, pageParameters } = data || {};

  return {
    currentPage,
    pageParameters,
    tracking,
  };
};

export default getFormattedSearchStandalone;

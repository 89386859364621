import { executeOnNextFrame } from '@canalplus/mycanal-commons';
import type { CSSProperties, JSX, ReactNode } from 'react';
import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  DELAY_TO_DISPLAY_SLIDEDOWN,
  DELAY_TO_HIDE_SLIDEDOWN,
} from '../../constants/slidedown';
import styles from './SlideDown.css';

export type SlideDownProps = {
  children: ReactNode | null;
  isOpen: boolean;
};

const SlideDown = forwardRef<HTMLDivElement, SlideDownProps>(function SlideDown(
  { children, isOpen },
  parentRef
): JSX.Element {
  const [delayedIsOpen, setDelayedIsOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(
    parentRef,
    () => containerRef?.current,
    []
  );
  useEffect(() => {
    if (isOpen == delayedIsOpen) {
      return;
    }

    executeOnNextFrame(
      () => {
        setDelayedIsOpen(isOpen);
      },
      isOpen ? DELAY_TO_DISPLAY_SLIDEDOWN : DELAY_TO_HIDE_SLIDEDOWN
    );
  }, [isOpen, delayedIsOpen]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }
    const handleTransitionEnd = () => {
      container.style.overflow = delayedIsOpen ? 'visible' : 'hidden';
    };
    container.addEventListener('transitionend', handleTransitionEnd);

    return () => {
      container.removeEventListener('transitionend', handleTransitionEnd);
    };
  }, [delayedIsOpen]);

  const style = {
    maxHeight: (delayedIsOpen && containerRef.current?.scrollHeight) || 0,
    visibility: (delayedIsOpen
      ? 'visible'
      : 'hidden') as CSSProperties['visibility'],
  };

  return (
    <div
      ref={containerRef}
      aria-hidden={!delayedIsOpen}
      className={styles.slideDown}
      style={style}
    >
      {children}
    </div>
  );
});

export default memo(SlideDown);

import { DisplayMode } from '@canalplus/mycanal-commons';
import { usePrevious } from '@canalplus/mycanal-util-react';
import classNames from 'classnames';
import type { JSX } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import screenfull from 'screenfull';
import { useAppDispatch } from '../../helpers/hooks/useAppDispatch';
import { useInvariantSelector } from '../../helpers/hooks/useInvariantSelector';
import { isMobileSelector } from '../../store/slices/application-selectors';
import { setDisplayMode } from '../../store/slices/ui';
import { displayModeSelector } from '../../store/slices/ui-selectors';
import { ModalContainerConnected } from '../Modal/ModalContainerConnected';
import styles from './DisplayModeManager.css';

export type DisplayModeManagerProps = {
  onExitFullscreen?: () => void;
  fullscreenNoPaddings?: boolean;
  windowedPadded?: boolean;
  children: JSX.Element;
};

/**
 * DisplayModeManager
 *
 * This container manages the display of its children.
 * It allows to pass to leave fullscreen and pass to fullwindowed mode.
 * In fullwindowed mode ESC key can be used to leave the mode and return to windowed mode.
 * In case of modal mode, the children is render in a ModalContainer.
 * If Os is android
 * 1 : On play we set DisplayMode to fullscreen.
 * 2 : Classname is windowed, we set paddings and we have to remove it on fullscreen. (only on liveTV)
 * if Os is not android
 * 1 : Classname is fullWindowed we set paddings to it. We don't need to remove it on fullscreen.
 *
 * @param {function}   onExitFullscreen       triggered when fullscreen is closing
 * @param {bool}   fullscreenNoPaddings   remove paddings to windowed on fullscreen
 * @param {bool}   windowedPadded         add paddings top and bottom to windowed
 * @param {node}   children               node to render
 *
 */
export function DisplayModeManager({
  onExitFullscreen = () => null,
  fullscreenNoPaddings = false,
  windowedPadded = false,
  children,
}: DisplayModeManagerProps): JSX.Element {
  const dispatch = useAppDispatch();
  const isMobile = useInvariantSelector(isMobileSelector);
  const displayMode = useSelector(displayModeSelector);
  const rootRef = useRef<HTMLDivElement>(null);
  const previousDisplayMode = usePrevious(displayMode);

  const onFullscreenError = (event: any) => {
    // NOTE Fallback to fullwindowed mode if requestFullscreen() is not authorized.
    // We have to check the source node of the event because `fullscreenerror` event is global,
    // so One Player script is able to trigger this callback.
    if (event.srcTarget === rootRef.current) {
      event.stopImmediatePropagation();
      dispatch(setDisplayMode(DisplayMode.FULLWINDOWED));
    }
  };

  const onFullscreenChange = () => {
    if (!(screenfull.isFullscreen && onExitFullscreen)) {
      onExitFullscreen();
    }
  };

  useEffect(() => {
    if (screenfull.isEnabled) {
      document.addEventListener(
        screenfull.raw.fullscreenchange,
        onFullscreenChange
      );
      document.addEventListener(
        screenfull.raw.fullscreenerror,
        onFullscreenError
      );
    }

    return () => {
      if (screenfull.isEnabled) {
        document.removeEventListener(
          screenfull.raw.fullscreenchange,
          onFullscreenChange
        );
        document.removeEventListener(
          screenfull.raw.fullscreenerror,
          onFullscreenError
        );
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      if (!screenfull.isEnabled) {
        return;
      }

      if (
        displayMode === DisplayMode.FULLSCREEN ||
        (displayMode === DisplayMode.FULLWINDOWED && isMobile)
      ) {
        await screenfull.request(rootRef.current as HTMLDivElement);
      } else if (
        previousDisplayMode === DisplayMode.FULLSCREEN ||
        (previousDisplayMode === DisplayMode.FULLWINDOWED &&
          displayMode === DisplayMode.WINDOWED)
      ) {
        await screenfull.exit();
      }
    })();
  }, [displayMode, isMobile, previousDisplayMode]);

  const isModalMode = useMemo(
    () => displayMode === DisplayMode.MODAL,
    [displayMode]
  );
  const isFullWindowed = useMemo(
    () => displayMode === DisplayMode.FULLWINDOWED,
    [displayMode]
  );

  const handleAfterClosing = useCallback(
    () => dispatch(setDisplayMode(DisplayMode.WINDOWED)),
    [dispatch]
  );

  return isModalMode ? (
    <ModalContainerConnected afterClosing={handleAfterClosing}>
      {children}
    </ModalContainerConnected>
  ) : (
    <div
      ref={rootRef}
      className={classNames({
        [styles.windowed!]: !isFullWindowed,
        [styles.fullWindowed!]: isFullWindowed,
        [styles['fullscreen--noPaddings']!]: fullscreenNoPaddings,
        [styles['windowed--padded']!]: windowedPadded,
      })}
    >
      {children}
    </div>
  );
}

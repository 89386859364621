import { createSelector } from '@reduxjs/toolkit';
import type { IState } from '../../store/types/State-type';

const stickyBarSelector = (state: IState) => state?.stickyBar;

export const stickyHeaderBarSelector = createSelector(
  stickyBarSelector,
  (stickyBar) => stickyBar?.headerBar || null
);

export const stickyOriginalBarSelector = createSelector(
  stickyBarSelector,
  (stickyBar) => stickyBar?.originalBar || null
);

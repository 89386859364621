import {
  KEY_LEFT,
  KEY_RIGHT,
  KEY_UP,
  useKeySequence,
  useStore,
} from '@canalplus/one-navigation';
import type { JSX } from 'react';
import { useCallback, useReducer, useState } from 'react';
import {
  LAYER_VISUAL_DEBUG,
  LAYER_VISUAL_DEBUG_LOGS,
} from '../../helpers/oneNavigation/layers';
import LoadableMycanalVisualDebugger from './LoadableMycanalVisualDebugger';

const SEQUENCE_SHOW_DEBUGGER = [
  KEY_UP,
  KEY_RIGHT,
  KEY_LEFT,
  KEY_LEFT,
  KEY_LEFT,
  KEY_RIGHT,
  KEY_RIGHT,
  KEY_UP,
];

export function MycanalVisualDebuggerWrapper(): JSX.Element | null {
  const [open, setOpen] = useReducer(
    (state: boolean, action: boolean) => action,
    false
  );
  const store = useStore();
  const [activeLayer, setActiveLayer] = useState(0);

  const handleSequence = useCallback(() => {
    if (
      store.activeLayer === LAYER_VISUAL_DEBUG ||
      store.activeLayer === LAYER_VISUAL_DEBUG_LOGS
    ) {
      return;
    }
    if (!open) {
      setOpen(true);
    }
  }, [open, store]);

  const handleMount = useCallback(() => {
    setActiveLayer(store.activeLayer);
    store.setActiveLayer(LAYER_VISUAL_DEBUG);
    if (!store.current) {
      store.focusDefault();
    }
  }, [store, setActiveLayer]);

  const handleClose = useCallback(() => {
    store.setActiveLayer(activeLayer);
    setOpen(false);
  }, [activeLayer, store]);

  useKeySequence(SEQUENCE_SHOW_DEBUGGER, handleSequence);

  return open ? (
    <LoadableMycanalVisualDebugger
      onClose={handleClose}
      onMount={handleMount}
    />
  ) : null;
}

/* eslint-disable react/require-default-props */
import {
  animateScroll,
  throttle,
  type ImageSize,
  type Ratio,
} from '@canalplus/mycanal-commons';
import classNames from 'classnames';
import type { ReactElement, ReactNode, SyntheticEvent } from 'react';
import {
  cloneElement,
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIsomorphicLayoutEffect } from 'usehooks-ts';
import styles from './HorizontalList.module.css';
import { HorizontalListPageButton } from './HorizontalListPageButton/HorizontalListPageButton';

export type Ref = HTMLOListElement;

export type HorizontalListProps = {
  /** Provide this index to scroll to it */
  activeIndex?: number;

  buttonTitles?: {
    previous: string;
    next: string;
  };

  /** Optional custom button (if it's a react component, make sure it accepts an onClick prop) */
  buttonPrevious?: ReactElement<{ onClick: (event: SyntheticEvent) => void }>;

  /** Optional custom button (if it's a react component, make sure it accepts an onClick prop) */
  buttonNext?: ReactElement<{ onClick: (event: SyntheticEvent) => void }>;

  /**
   * Things will break if your children elements are not semantically correct ;-) \
   * For an `ul/li` list, the `ul` is included here so your children must be your `li` only
   */
  children?: ReactNode[];

  /** Ability to always show/hide previous/next buttons */
  showControls?: boolean;

  /** How long does the scroll last when navigating the list via previous/next buttons (milliseconds) */
  scrollDuration?: number;

  wrapperId?: string;
  className?: string;
  onScroll?: () => void;
  isOrderedList?: boolean;
  ratio?: Ratio;
  imageSize?: ImageSize;
};

/**
 * HorizontalList: Scroll your list items horizontally
 *
 * NOTE: This component is dumb as nails, and I'd like to keep it that way.  It doesn't know anything about its children or where it's being used, aspect ratios, etc.
 *
 * Want to change the style/position of the previous/next buttons?
 * - Pass it whatever buttons you want as props.  The onClick prop will be replaced by HorizontalList's internal paging functions.
 *
 * Want to add some horizontal padding to the list?
 * - Declare the variable "--HZL-list-side-padding" in your parent component CSS (look in HorizontalList.css for more info).
 *
 * What about lazy loading children?
 * - Use HorizontalListLazyLoader instead, which extends this component.
 *
 * NOTE on rounding values:
 *
 * You'll see that sometimes we round up or down the values returned from element.getBoundingClientRect.
 * This is because getBoundingClientRect can return values that are off by a small fraction of a pixel.
 * It happens notably when the list children's widths are percentages (especially 33.33333%).
 * This tiny difference can make paging previous/next scroll to the incorrect child.
 *
 * @returns Reach component. Provides the necessary layout to support the horizontally scrolling list
 */
export const HorizontalList = forwardRef<
  HTMLUListElement | HTMLOListElement,
  HorizontalListProps
>(
  (
    {
      onScroll,
      activeIndex = 0,
      buttonTitles = {
        previous: 'Précédent',
        next: 'Suivant',
      },
      buttonPrevious = (
        <HorizontalListPageButton
          title={buttonTitles.previous}
          type="previous"
        />
      ),
      buttonNext = (
        <HorizontalListPageButton title={buttonTitles.next} type="next" />
      ),
      children,
      scrollDuration = 200,
      showControls = true,
      wrapperId,
      className = '',
      isOrderedList = false,
      ratio,
      imageSize,
    }: HorizontalListProps,
    ref
  ) => {
    const listRefDefault = useRef<Ref>(null);
    const listRef = (ref || listRefDefault) as React.MutableRefObject<Ref>;

    const getListElementPadding = useCallback(
      () =>
        listRef.current
          ? parseInt(window.getComputedStyle(listRef.current).paddingLeft, 10)
          : 0,
      [listRef]
    );

    const [isPreviousButtonHidden, setIsPreviousButtonHidden] = useState(true);
    const [isNextButtonHidden, setIsNextButtonHidden] = useState(true);

    const determineButtonVisibility = useCallback(() => {
      const listElement = listRef.current;
      if (listElement) {
        // Evaluate Previous Button: hidden when we're scrolled to the beginning of the list
        const newIsPreviousButtonHidden = listElement.scrollLeft === 0;
        if (isPreviousButtonHidden !== newIsPreviousButtonHidden) {
          setIsPreviousButtonHidden(newIsPreviousButtonHidden);
        }

        // Evaluate Next Button: hidden when we're scrolled to the end of the list (unless parent forces it to be shown)
        let newIsNextButtonHidden = isNextButtonHidden;
        const listChildren: Element[] = Array.from(listElement.children);
        const lastListChild = listChildren[listChildren.length - 1];
        if (lastListChild) {
          const listPadding = getListElementPadding();
          const { right: listRight } = listElement.getBoundingClientRect();
          const { right: lastChildRight } =
            lastListChild.getBoundingClientRect();
          /* Flooring the lastChildRight value here because occasionally, when it had a decimal value, it can calculate as farther to the right than it actually is (less than 1px).
           When this happened, it prevented the next button from being hidden when the list was completely scrolled to the end.
           The calculation error may have something to do with how fonts render/measure, because I could reproduce the error by adding text to an element, when the width of the child was determined by the length of the text.  Voila. */
          newIsNextButtonHidden =
            Math.floor(lastChildRight) + listPadding <= listRight;
        }
        if (newIsNextButtonHidden !== isNextButtonHidden) {
          setIsNextButtonHidden(newIsNextButtonHidden);
        }
      }
    }, [
      getListElementPadding,
      isPreviousButtonHidden,
      setIsPreviousButtonHidden,
      isNextButtonHidden,
      setIsNextButtonHidden,
      listRef,
    ]);

    // If the controls are shown, when scrolling we need to periodically determine which buttons should be visible.
    useIsomorphicLayoutEffect(() => {
      const listElement = listRef.current;
      if (showControls && listElement) {
        determineButtonVisibility();
        // Run once every 1/2 second when scrolling (no need to measure the DOM like crazy because button visibility only changes when we reach the beginning or end of the list)
        const determineButtonVisibilityThrottled = throttle(
          determineButtonVisibility,
          200
        );
        listElement.addEventListener(
          'scroll',
          determineButtonVisibilityThrottled
        );
        window.addEventListener('resize', determineButtonVisibilityThrottled);
        return () => {
          listElement.removeEventListener(
            'scroll',
            determineButtonVisibilityThrottled
          );
          window.removeEventListener(
            'resize',
            determineButtonVisibilityThrottled
          );
        };
      }
      return;
    }, [
      determineButtonVisibility,
      showControls,
      children?.length,
      listRef,
      listRef?.current?.children?.length,
    ]); // We depend on children.length here because of potentially lazy-loaded children

    const scrollToChild = useCallback(
      (index: number) => {
        const listElement = listRef.current;
        if (listElement) {
          const listPadding = getListElementPadding();
          const elementToScrollTo = listElement.children[index];
          if (elementToScrollTo) {
            const { offsetLeft } = elementToScrollTo as HTMLElement;
            // No need to animate when scrollDuration=0
            if (scrollDuration === 0) {
              listElement.scrollLeft = offsetLeft - listPadding;
            } else {
              animateScroll(
                listElement,
                offsetLeft - listPadding,
                scrollDuration
              );
            }
          }
        }
      },
      [getListElementPadding, listRef, scrollDuration]
    );

    // Scroll to activeIndex on mount or when activeIndex prop changes
    useEffect(() => {
      if (activeIndex === 0) {
        // go to start instantly without transition
        animateScroll(listRef.current, 0, 0);
        return;
      } else if (activeIndex > 0) {
        scrollToChild(activeIndex);
      }
    }, [activeIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (onScroll) {
        const listElement = listRef.current;
        listElement.addEventListener('scroll', onScroll);
        return () => listElement.removeEventListener('scroll', onScroll);
      }
      return;
    }, [onScroll, listRef]);

    const pageNext = useCallback(
      (event: SyntheticEvent) => {
        event.nativeEvent.stopImmediatePropagation();
        const listElement = listRef.current;
        if (listElement) {
          const { right: listRight } = listElement.getBoundingClientRect();
          const listRightMinusPadding = listRight - getListElementPadding();
          const listChildren: Element[] = Array.from(listElement.children);

          const indexToScrollTo = listChildren.findIndex((el) => {
            const { right: childRight } = el.getBoundingClientRect();
            // See note on rounding at the top of this file to understand why we use Math.floor below
            return Math.floor(childRight) > listRightMinusPadding; // The right edge of the child is past where the list's padding-right starts
          });
          scrollToChild(indexToScrollTo);
        }
      },
      [getListElementPadding, listRef, scrollToChild]
    );

    const pagePrevious = useCallback(
      (event: SyntheticEvent) => {
        event.nativeEvent.stopImmediatePropagation();
        const listElement = listRef.current;
        if (listElement) {
          const { left: listLeft, width: listWidth } =
            listElement.getBoundingClientRect();
          const listPadding = getListElementPadding();
          const listLeftPlusPadding = listLeft + listPadding;
          const listWidthMinusPadding = listWidth - listPadding * 2;
          const listChildren: Element[] = Array.from(listElement.children);

          const listChildrenData: { index: number; width: number }[] = [];
          for (let index = 0; index < listChildren.length; index += 1) {
            const { right, width } =
              listChildren[index].getBoundingClientRect();
            listChildrenData.push({ index, width });
            // See note on rounding at the top of this file to understand why we use Math.ceil below
            if (Math.ceil(right) >= listLeftPlusPadding) {
              // We've found the First Partially Visible Child (FPVC) when the right edge of the child is at or to the right of where the list padding-left ends.
              // When pagePrevious is complete, the FPVC should be the last child to be completely visible.
              // We break here because all children after the FPVC are irrelevant.
              break;
            }
          }
          listChildrenData.reverse(); // Now the FPVC is first item in the list

          let indexToScrollTo = -1;
          let combinedChildrenWidth = 0;
          // We work backwards from the FPVC to find the index of the child we should scroll to.
          for (let i = 0; i < listChildrenData.length; i += 1) {
            const elementData = listChildrenData[i];
            combinedChildrenWidth += elementData.width;
            if (combinedChildrenWidth > listWidthMinusPadding) {
              break;
            }
            indexToScrollTo = elementData.index;
          }
          scrollToChild(indexToScrollTo);
        }
      },
      [getListElementPadding, listRef, scrollToChild]
    );

    /*
    The previous/next buttons are wrapped in a span to control their visibility, which is done by adjusting the span's opacity via css.
    We apply the opacity change to the span instead of the button because custom buttons may have their own opacity styles that need to be respected (when visible).

    This is also where we override the onClick value for custom buttons, so they call pagePrevious/pageNext.
  */
    const controls = useMemo(
      () =>
        showControls && (
          <Fragment>
            <span
              className={classNames(styles.HorizontalList__buttonWrap, {
                [styles['HorizontalList__buttonWrap--hidden']]:
                  isPreviousButtonHidden,
              })}
            >
              {cloneElement(buttonPrevious, { onClick: pagePrevious })}
            </span>
            <span
              className={classNames(styles.HorizontalList__buttonWrap, {
                [styles['HorizontalList__buttonWrap--hidden']]:
                  isNextButtonHidden,
              })}
            >
              {cloneElement(buttonNext, { onClick: pageNext })}
            </span>
          </Fragment>
        ),
      [
        buttonPrevious,
        buttonNext,
        isPreviousButtonHidden,
        isNextButtonHidden,
        pageNext,
        pagePrevious,
        showControls,
      ]
    );

    const ListComponent = isOrderedList ? 'ol' : 'ul';

    return (
      <div className={styles.HorizontalList} id={wrapperId}>
        <ListComponent
          ref={listRef}
          className={classNames(
            styles.HorizontalList__list,
            'HorizontalList',
            className
          )}
          {...(ratio && imageSize && { 'data-ratio': `${ratio}${imageSize}` })}
        >
          {children}
        </ListComponent>
        {controls && (
          <div className={styles.HorizontalList__controlsWrap}>{controls}</div>
        )}
      </div>
    );
  }
);

HorizontalList.displayName = 'HorizontalList';

import type { ApiV2LiveGridTracking } from '@dce-front/hodor-types/api/v2/live_grid/definitions';
import type { ApiV2BroadcastChannel } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/live_grid/definitions';
import type { ApiV2MultiLiveSetupPage } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/multi_live_setup';

export interface IMultiLiveSetupState {
  buttonText: string;
  channels: ApiV2BroadcastChannel[];
  text?: string;
  title?: string;
  tracking?: ApiV2LiveGridTracking;
}

const getFormattedMultiLive = (
  data: ApiV2MultiLiveSetupPage
): IMultiLiveSetupState => {
  const {
    button: { title: buttonText = '' } = {},
    channels = [],
    text,
    title,
    tracking,
  } = data || {};
  return {
    buttonText,
    channels,
    text,
    title,
    tracking,
  };
};

export default getFormattedMultiLive;

import type { ApiV2OnClick } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import type { HodorApiParameter } from '../config/types';
import { isHodorApiParameter } from '../config/types';

/** Filter every entries from BranSdkOnClickParameters that not match HodorApiParameter type */
export const getHodorApiParametersFromBranSdkOnClickParameters = (
  branSdkOnClickParameters?: ApiV2OnClick['parameters']
): HodorApiParameter[] =>
  branSdkOnClickParameters
    ? (branSdkOnClickParameters.filter((branSdkOnClickParameter) =>
        isHodorApiParameter(branSdkOnClickParameter)
      ) as HodorApiParameter[])
    : [];

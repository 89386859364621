import { ProfileModal } from '@canalplus/mycanal-commons';
import { Binder } from '@canalplus/one-navigation';
import classNames from 'classnames';
import type { JSX, SyntheticEvent } from 'react';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { TemplateTypes } from '../../../constants/templateTypes';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import I18n from '../../../lang';
import { deleteProfile } from '../../../services/profiles/ProfileService';
import {
  setProfileView,
  setSelectedProfile,
} from '../../../store/slices/profilesModal';
import { selectedProfileSelector } from '../../../store/slices/profilesModal-selectors';
import Button from '../../Button/Button';
import { useUpdateListProfiles } from '../ProfileSelectorMyCanal/hooks/useUpdateListProfiles';
import styles from './ProfileDeleteConfirmation.css';

function ProfileDeleteConfirmation(): JSX.Element {
  const dispatch = useAppDispatch();
  const profile = useSelector(selectedProfileSelector);
  const { displayName = '' } = profile || {};

  const { t } = useContext(I18n.context);
  const { updateListProfiles } = useUpdateListProfiles();

  const handleDelete = async (event: SyntheticEvent) => {
    if (profile) {
      await dispatch(deleteProfile(profile, updateListProfiles, event));
      dispatch(setProfileView(ProfileModal.ProfileListView));
    }
  };

  const handleCancelDeleteConfirmation = () => {
    dispatch(setSelectedProfile(profile));
    dispatch(setProfileView(ProfileModal.ProfileEditionView));
  };

  return (
    <Binder>
      <div className={styles.ProfileDeleteConfirmation}>
        <div
          className={classNames(
            styles.ProfileDeleteConfirmation__wrapper,
            'ProfileDeleteConfirmation'
          )}
        >
          <div className={styles.ProfileDeleteConfirmation__title}>
            {t('ProfileManager.deleteProfileConfirmTitle')}
          </div>
          <div className={styles.ProfileDeleteConfirmation__text}>
            {t('ProfileManager.deleteProfileConfirmText', { displayName })}
          </div>
          <div className={styles.ProfileDeleteConfirmation__buttonWrapper}>
            <Button
              isSubmit
              handler={(e) => handleDelete(e)}
              className={styles.ProfileDeleteConfirmation__button}
              color={TemplateTypes.TV}
              text={t('ProfileManager.deleteProfileConfirmButton')}
            />
            <Button
              handler={handleCancelDeleteConfirmation}
              className={styles.ProfileDeleteConfirmation__button}
              color={TemplateTypes.SECONDARY}
              text={t('ProfileManager.deleteProfileCancelButton')}
            />
          </div>
        </div>
      </div>
    </Binder>
  );
}

export default ProfileDeleteConfirmation;

import type { ImageSize } from '@canalplus/mycanal-commons';
import { DisplayMode, Ratio } from '@canalplus/mycanal-commons';
import type { IContent } from '@canalplus/mycanal-sdk';
import type { ContentRowHeader } from '@canalplus/mycanal-sharedcomponent';
import type { MiddlewareFactory, StoreBinder } from '@canalplus/one-navigation';
import { Binder } from '@canalplus/one-navigation';
import type { PersoLists } from '@canalplus/sdk-hodor';
import { Template, TitleDisplayMode } from '@canalplus/sdk-hodor';
import type {
  ApiV2ExpertModeHighlightContent,
  ApiV2ExpertModeHighlightPage,
  ApiV2ExpertModeHighlightSpyroContent,
} from '@dce-front/hodor-types';
import type { InfiniteData } from '@tanstack/react-query';
import classNames from 'classnames/bind';
import type { JSX } from 'react';
import ContentGridTemplateItem from '../../../components/ContentGrid/ContentGridTemplateItem';
import HeadingIdentityV5 from '../../../components/HeadingIdentityV5/HeadingIdentityV5';
import {
  IdentityCover,
  isCoverV5,
} from '../../../components/IdentityCover/IdentityCover';
import LazyLoader from '../../../components/LazyLoader/LazyLoader';
import { useIsFrom } from '../../../components/Page/useIsFrom';
import Spinner from '../../../components/Spinner/Spinner';
import TemplateHeaderCanal from '../../../components/TemplateHeader/TemplateHeader';
import { getLazyLoaderContentGridOptions } from '../../../constants/strates';
import { useFocusState } from '../../../helpers/hooks/useFocusState/useFocusState';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import { useIsTvDevice } from '../../../helpers/hooks/useIsTvDevice';
import { useOnFocusable } from '../../../helpers/hooks/useOnFocusable';
import { useTracking } from '../../../helpers/hooks/useTracking';
import { FocusManager } from '../../../helpers/oneNavigation/FocusManager';
import { MIDDLEWARE_STRATE } from '../../../helpers/oneNavigation/middleware';
import { featUpcomingHodorStickerSelector } from '../../../store/slices/application-selectors';
import type { ContentGridPersoState } from '../../ContentGridPerso/data/types';
import getOverlappingClassnameWithCoverV5 from '../../helpers/IdentityV5Helpers/getOverlappingClassname';
import type { ISearch } from '../../Search/data/types';
import type { ContentGridState } from '../data/types';
import styles from './ContentGridTemplate.css';

const cx = classNames.bind(styles);

export type ContentGridTemplateProps = {
  data?:
    | InfiniteData<ContentGridPersoState>
    | InfiniteData<ContentGridState>
    | InfiniteData<ISearch>
    | Pick<
        InfiniteData<{
          tracking?: ApiV2ExpertModeHighlightPage['tracking'];
          contents?:
            | ApiV2ExpertModeHighlightSpyroContent[]
            | ApiV2ExpertModeHighlightContent[];
        }>,
        'pages'
      >;
  header?: ContentRowHeader;
  imageRatio?: Ratio;
  imageSize?: ImageSize;
  isFetchingNextPage?: boolean;
  isRemovableItem?: boolean;
  isSearch?: boolean;
  isSearchDeeplink?: boolean;
  listType?: PersoLists;
  middleware?: MiddlewareFactory[];
  onClickRemove?: (
    event: React.MouseEvent,
    contentID: string,
    listType: PersoLists
  ) => void;
  titleDisplayMode?: TitleDisplayMode;
  focusManager?: FocusManager;
  binderId?: StoreBinder['id'];
  classNameGridItem?: string;
};

function ContentGridTemplate({
  data,
  header,
  imageRatio = Ratio.Ratio169,
  imageSize = 'normal',
  isFetchingNextPage = false,
  isRemovableItem = false,
  isSearch = false,
  isSearchDeeplink = false,
  listType,
  middleware,
  onClickRemove,
  titleDisplayMode = TitleDisplayMode.All,
  focusManager,
  binderId,
  classNameGridItem,
}: ContentGridTemplateProps): JSX.Element {
  const isTvDevice = useIsTvDevice();

  const isFeatUpcomingHodorSticker = useInvariantSelector(
    featUpcomingHodorStickerSelector
  );
  const isFromDetail = useIsFrom(Template.DetailPage);

  const { title, subtitle, button } = header || {};

  const { sendTracking } = useTracking();

  const [firstPage] = data?.pages || [];
  const tracking = firstPage?.tracking;
  const resultsLength = firstPage?.contents?.length;

  const focusState = useFocusState(focusManager);

  useOnFocusable(
    focusManager,
    typeof resultsLength !== 'undefined' && resultsLength > 0
  );

  const renderGrid = () => {
    const onClickProp =
      isSearch && tracking
        ? {
            onClick: () => sendTracking({ tracking }),
          }
        : {};

    const { increment, initialDisplayCount } = getLazyLoaderContentGridOptions({
      isTvDevice,
      isSearch,
      isSearchDeeplink,
    });

    const initialDisplayValue = Math.max(
      initialDisplayCount,
      focusState ? focusState.elementIndex + 1 : 0
    );

    return (
      <LazyLoader
        initialDisplayCount={initialDisplayValue}
        loadMoreCount={increment}
      >
        {data?.pages?.map((page) => {
          return page.contents?.map((content) => {
            // DisplayMode.FULLSCREEN is without capital S -  waiting Hodor fix
            const isPlayBackIconShown =
              content.onClick &&
              'displayMode' in content.onClick &&
              content.onClick.displayMode?.toLowerCase() ===
                DisplayMode.FULLSCREEN;

            const isCreativeMedia = content.type === Template.CreativeMedia;

            return (
              <li
                className={cx(
                  'contentGrid__gridItem',
                  {
                    'contentGrid__gridItem--detailV5': isFromDetail,
                    'contentGrid__gridItem--creativeMedia': isCreativeMedia,
                  },
                  classNameGridItem
                )}
                key={'hash' in content ? content.hash : content.contentID}
                {...onClickProp}
              >
                <ContentGridTemplateItem
                  content={content as IContent}
                  imageSize={imageSize}
                  ratio={imageRatio}
                  titleDisplayMode={titleDisplayMode}
                  isRemovableItem={isRemovableItem}
                  onClickRemove={onClickRemove}
                  listType={listType}
                  isPlayBackIconShown={isPlayBackIconShown}
                  isSearch={isSearch}
                  isTvDevice={isTvDevice}
                  isFeatUpcomingHodorSticker={isFeatUpcomingHodorSticker}
                />
              </li>
            );
          });
        })}
      </LazyLoader>
    );
  };

  if (
    data?.pages?.[0] &&
    'cover' in data.pages[0] &&
    isCoverV5(data.pages[0].cover)
  ) {
    const { cover } = data.pages[0];

    return (
      <>
        <IdentityCover cover={cover} />
        <div
          className={cx(
            'contentGridTemplate',
            { 'contentGridTemplate--detailV5': isFromDetail },
            getOverlappingClassnameWithCoverV5(cover)
          )}
        >
          <Binder
            binderId={binderId}
            middleware={middleware || MIDDLEWARE_STRATE}
          >
            <div className={cx('contentGridTemplate__heading')}>
              <HeadingIdentityV5 cover={cover} title={title} />
            </div>
            <ul
              data-ratio={`${imageRatio}${imageSize}`}
              className={styles.contentGrid}
            >
              {renderGrid()}
            </ul>
            {isFetchingNextPage && (
              <div className={styles.spinner__content}>
                <Spinner size={5} />
              </div>
            )}
          </Binder>
        </div>
      </>
    );
  }

  // without cover v5
  return (
    <div
      className={cx('contentGridTemplate', {
        'contentGridTemplate--detailV5': isFromDetail,
      })}
    >
      <Binder binderId={binderId} middleware={middleware || MIDDLEWARE_STRATE}>
        {title && (
          <div
            className={cx('contentGrid__header', {
              'contentGrid__header--detailV5': isFromDetail,
            })}
          >
            <TemplateHeaderCanal
              button={button}
              subtitle={subtitle}
              title={title}
            />
          </div>
        )}
        <ul
          data-ratio={`${imageRatio}${imageSize}`}
          className={styles.contentGrid}
        >
          {renderGrid()}
        </ul>
        {isFetchingNextPage && (
          <div className={styles.spinner__content}>
            <Spinner size={5} />
          </div>
        )}
      </Binder>
    </div>
  );
}

export default ContentGridTemplate;

import { createContext, type JSX, type ReactNode, useContext } from 'react';
import useLinker from './hooks/useLinker';
import type { LinkerStateContext } from './types';

const LinkerContext = createContext<LinkerStateContext>({
  isInit: false,
  isTvDevice: false,
});

export function LinkerContextProvider({
  children,
  isTv,
}: {
  children: ReactNode;
  isTv: boolean;
}): JSX.Element {
  const { getLinkerSettings } = useLinker();

  return (
    <LinkerContext.Provider
      value={{ isInit: true, getLinkerSettings, isTvDevice: isTv }}
    >
      {children}
    </LinkerContext.Provider>
  );
}

export const useLinkerContext = (): LinkerStateContext => {
  const context = useContext(LinkerContext);
  if (!context.isInit) {
    console.error('Error useLinkerContext: missing LinkerContextProvider');
  }

  return context;
};

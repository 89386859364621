import type { DottedLanguageObjectStringPaths } from '@canalplus/mycanal-commons';
import type { I18nTranslationFunction } from '@canalplus/mycanal-i18n';
import { createI18n } from '@canalplus/mycanal-i18n';
import type { Locale } from '../constants/lang';
import { getLocalTranslations } from '../helpers/lang/lang-helper';
import type { OneDiscoveryTranslations } from './types';

export type OneDiscoveryTranslationsStringPaths =
  DottedLanguageObjectStringPaths<OneDiscoveryTranslations>;

export type I18nOneDiscoveryFunction =
  I18nTranslationFunction<OneDiscoveryTranslationsStringPaths>;

const { provider, consumer, context, useTranslation } =
  createI18n<OneDiscoveryTranslationsStringPaths>();

const Wrapper = ({
  children,
}: {
  locale: Locale;
  children: React.ReactElement;
}) => children;
const Provider = provider(getLocalTranslations)(Wrapper);

const I18n = { Provider, consumer, context, useTranslation };

export default I18n;

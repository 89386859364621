import { getObjectKeys } from '@canalplus/mycanal-commons';
import type { Zone, ZoneLocale } from './types';

/**
 * Get all locale zones from the zone list
 *
 * @param zones The `Zone` list to parse
 * @return Return all **ZoneLocale** from the list
 */
export const getAllLocaleZones = (zones: Zone): Partial<ZoneLocale> =>
  getObjectKeys(zones).reduce(
    (acc, currentZone) => ({
      ...acc,
      ...zones[currentZone],
    }),
    {}
  );

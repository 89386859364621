import type {
  IAPIConfigRaw,
  IAPICredentialsRaw,
  ILiveTvGroupType,
} from '@canalplus/oneplayer-types';
import type { OfferLocation } from '@canalplus/sdk-core';
import { isTOfferLocation, OfferZone } from '@canalplus/sdk-core';
import {
  useCallback,
  useEffect,
  useRef,
  type JSX,
  type RefObject,
} from 'react';
import { PlayerPlaybackState } from '../../constants/playerStates';
import { VideoType } from '../../constants/videoTypes';
import type { PlayerEvents } from '../../types';
import styles from './VideoFragment.module.css';

export type VideoPlayerProps = {
  id: string;
  offerLocation: OfferLocation;
  offerZone: OfferZone;
  playerCredentials?: IAPICredentialsRaw;
  type?: VideoType;
  DOM: RefObject<HTMLDivElement | null>;
};

export type PlayerInstance = {
  initialized: boolean;
  getAllProgramGenres: () => { id: number; name: string }[];
  getAvailableChannels: (activeThemeId?: number) => number[];
  zapTo: (id?: number, date?: Date) => void;
  setAdData: ({
    gdpr,
    targetedAds,
  }: {
    gdpr: number;
    targetedAds: string;
    consentString?: string;
  }) => void;
  loadVideo: (
    settings: Partial<IAPIConfigRaw>,
    playerCredentials: IAPICredentialsRaw,
    container?: Node | null
  ) => void;
  destroy: () => void;
  player: Record<string, never>;
  container: Node;
};

const isILiveTvGroupType = (
  offerZone: string
): offerZone is keyof ILiveTvGroupType =>
  offerZone === OfferZone.cpfra ||
  offerZone === OfferZone.cpche ||
  offerZone === OfferZone.cpreu ||
  offerZone === OfferZone.cpafr ||
  offerZone === OfferZone.cpant ||
  offerZone === OfferZone.cpmus ||
  offerZone === OfferZone.cpncl ||
  offerZone === OfferZone.cpmdg ||
  offerZone === OfferZone.cppyf ||
  offerZone === OfferZone.tiita;

export function VideoPlayer({
  playerCredentials = undefined,
  id,
  type,
  offerLocation,
  offerZone,
  DOM,
}: VideoPlayerProps): JSX.Element {
  const player = useRef<PlayerInstance | Record<string, never> | undefined>(
    undefined
  );

  const initializePlayer = useCallback(() => {
    const registeredPlayers: any[] = [];

    const playerEvents: PlayerEvents = {
      onReady: (_data, refPlayer) => registeredPlayers.push(refPlayer),
      onPlayerStateChange: ({ data }, refPlayer) => {
        if (data === PlayerPlaybackState.Playing) {
          registeredPlayers.forEach((p) => p !== refPlayer && p.pause());
        }
      },
    };

    if (type === VideoType.Pfv || type === VideoType.Hapi) {
      const baseUrl = `//player.canalplus.com/one/${process.env.BUILD_FLAG_IS_DEV ? 'preprod' : 'prod'}/v2/`;

      const optionsPlayer: IAPIConfigRaw = {
        content: id,
        platform: VideoType.Hapi,
        env: process.env.BUILD_FLAG_IS_DEV ? 'preprod' : 'prod',
        context: 'mycanal',
        params: {
          autoplay: false,
          exitButton: { showWindowed: false },
          enableCcast: false,
          isTabKeyNavigationCapturedByPlayer: false,
          offerLocation: isTOfferLocation(offerLocation)
            ? offerLocation
            : undefined,
          offerZone: isILiveTvGroupType(offerZone) ? offerZone : undefined,
        },
        events: playerEvents as any,
        base: baseUrl,
      };

      const wrapper = DOM.current?.querySelector(`.${styles.playerWrapper}`);

      if (!(window as any).OnePlayer) {
        const script = document.createElement('script');
        script.onload = () => {
          player.current = new (window as any).OnePlayer(
            wrapper,
            optionsPlayer,
            playerCredentials
          );
        };
        script.src = `${baseUrl}bundle-api.js`;
        document.head.appendChild(script);
      } else {
        player.current = new (window as any).OnePlayer(
          wrapper,
          optionsPlayer,
          playerCredentials
        );
      }
    }
  }, [type, id, offerLocation, offerZone, DOM, playerCredentials]);

  useEffect(() => {
    if (
      type === VideoType.Pfv ||
      (type === VideoType.Hapi && playerCredentials?.passToken)
    ) {
      (window as any).waitForPassToken(() => {
        initializePlayer();
      });
    }

    return () => {
      if (player.current) {
        player.current.destroy();
      }
    };
  }, [playerCredentials?.passToken, type, initializePlayer]);

  return <div className={styles.playerWrapper} />;
}

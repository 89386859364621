import {
  ButtonLogo,
  Media,
  MediaOverlay,
  Sticker,
  type ThumborSourcesDimensions,
} from '@canalplus/dive';
import { DIMENSIONS, type ImageSize, Ratio } from '@canalplus/mycanal-commons';
import { CreativeMediaCard } from '@canalplus/mycanal-creativemedia';
import type { PersoLists } from '@canalplus/sdk-hodor';
import { Template, TitleDisplayMode } from '@canalplus/sdk-hodor';
import classNames from 'classnames/bind';
import type { JSX } from 'react';
import { useSelector } from 'react-redux';
import type { IContentD2G } from '../../constants/DownloadToGo';
import { getPublicConfig } from '../../helpers/config/config-helper';
import { getHodorSticker } from '../../helpers/stickers/stickers-helper';
import I18n from '../../lang';
import { imageQualityPercentageBySettingsSelector } from '../../store/slices/user-selectors';
import { CardRemoveButton } from '../CardContents/CardRemoveButton/CardRemoveButton';
import Linker from '../Linker/Linker';
import { MediaFooterWrapper } from '../MediaFooterWrapper/MediaFooterWrapper';
import PlayBackIcon from '../PlayBackIcon/PlayBackIcon';
import DiveProgressBar from '../ProgressBar/DiveProgressBar';
import { useGetDiveStickerProps } from '../Sticker/Sticker';
import ThumborMediaImage from '../ThumborMediaImage/ThumborMediaImage';
import styles from './ContentGridTemplateItem.css';
import { getContentGridItemLinkerData } from './helper';

const cx = classNames.bind(styles);

/**
 * ContentGridTemplateItem
 *
 * Component used in <ContentGridTemplate /> to render its children
 *
 * @param {object}   content                data from TMS to render component
 * @param {number}   idItem                 id of items
 * @param {number}   profileId              current profile id
 * @param {string}   ratio                  ratio used for image sizes
 * @param {string}   listType               list type of contentGrid
 * @param {func}     onClickRemove          remove content on click
 * @param {func}     openOfflinePlayer      open player offline
 * @param {number}   profileId              profile ID
 * @param {number}   ratio                  ratio of content
 */

export type ContentGridTemplateItemProps = {
  content: IContentD2G | null;
  imageSize: ImageSize;
  isPlayBackIconShown?: boolean;
  isRemovableItem?: boolean;
  isFeatUpcomingHodorSticker?: boolean;
  isTvDevice: boolean;
  listType?: PersoLists;
  onClickRemove?: (
    event: React.MouseEvent,
    contentID: string,
    listType: PersoLists
  ) => void;
  ratio: Ratio;
  titleDisplayMode: TitleDisplayMode;
  isSearch?: boolean;
};

function ContentGridTemplateItem({
  content,
  imageSize,
  listType,
  onClickRemove,
  ratio,
  titleDisplayMode,
  isRemovableItem,
  isPlayBackIconShown,
  isFeatUpcomingHodorSticker,
  isSearch = false,
  isTvDevice,
}: ContentGridTemplateItemProps): JSX.Element | null {
  const {
    contentID,
    altImage,
    endTime,
    imageSpecificities,
    isLogoTyped = false,
    startTime,
    subtitle,
    title = '',
    type,
    URLImage,
    URLImageForDarkMode = URLImage,
    URLLogoChannel = '',
    altLogoChannel,
    userProgress,
    stickers,
    isTVoD,
  } = content || {};

  const { t } = I18n.useTranslation();

  const publicConfig = getPublicConfig();

  const isCreativeMedia = type === Template.CreativeMedia;
  // if personality, show the correct placeHolder (for search)
  const isPersonality = type === publicConfig.search_results.personality;

  const qualityUserSettings = useSelector(
    imageQualityPercentageBySettingsSelector
  );

  const displayedTitle =
    titleDisplayMode !== TitleDisplayMode.None ? title : '';
  const displayedSubtitle =
    titleDisplayMode !== TitleDisplayMode.None &&
    titleDisplayMode !== TitleDisplayMode.TitleOnly
      ? subtitle
      : '';

  const isLogoTypedModeAndTitleNotIncrusted =
    titleDisplayMode === TitleDisplayMode.LogoTyped && !isLogoTyped;

  const isLogoChannel = imageSpecificities === 'isLogo';

  // define the url mode when we are on dark or light mode
  const imageUrl = !isPersonality ? URLImageForDarkMode : '';

  const dimensions = (DIMENSIONS as any)[ratio][
    imageSize
  ] satisfies ThumborSourcesDimensions;

  const isBottomTitle = !(
    [TitleDisplayMode.LogoTyped, TitleDisplayMode.None] as string[]
  ).includes(titleDisplayMode);

  const stickerProps = useGetDiveStickerProps({
    sticker: getHodorSticker(stickers, isFeatUpcomingHodorSticker),
    startTime: startTime,
    endTime: endTime,
  });

  const ariaLabel = title;

  const getOverlayIcon = (): JSX.Element | undefined => {
    if (isPlayBackIconShown && !isTVoD) {
      return <PlayBackIcon key="playbackicon" />;
    }

    return undefined;
  };

  const overlayIcon = getOverlayIcon();

  const fallbackPersonalityImage =
    isPersonality && URLImage && !imageUrl ? URLImage : '';

  if (!content) {
    return null;
  }

  return (
    <Linker data={getContentGridItemLinkerData(content)} title={displayedTitle}>
      {isLogoChannel && !isSearch ? (
        <ButtonLogo
          as="div"
          logo={
            <ThumborMediaImage
              url={imageUrl}
              dimensions={
                isLogoChannel ? DIMENSIONS.LOGO_CHANNEL.extraLarge : dimensions
              }
              alt={altImage}
              height="auto"
            />
          }
        />
      ) : isCreativeMedia && URLImage ? (
        <CreativeMediaCard
          title={title}
          image={URLImage}
          qualityUserSettings={qualityUserSettings}
          t={t}
          isImmersive={false}
          description={subtitle}
        />
      ) : (
        <Media
          aspectRatio={ratio}
          aria-label={ariaLabel}
          image={
            imageUrl || fallbackPersonalityImage ? (
              <ThumborMediaImage
                dimensions={
                  isLogoChannel
                    ? DIMENSIONS.LOGO_CHANNEL.extraLarge
                    : dimensions
                }
                url={imageUrl || fallbackPersonalityImage}
                alt={altImage}
                className={cx('contentGridTemplateItem', {
                  'contentGridTemplateItem--is-logo-channel': isLogoChannel,
                })}
              />
            ) : undefined
          }
          overlay={
            <MediaOverlay
              actionableElementTop={
                isRemovableItem && onClickRemove && !isTvDevice ? (
                  <CardRemoveButton
                    contentID={contentID as string}
                    listType={listType}
                    onClickRemove={onClickRemove}
                    hasSticker={Boolean(stickerProps)}
                  />
                ) : undefined
              }
              progressBar={
                userProgress ? (
                  <DiveProgressBar progress={userProgress} showIconOnComplete />
                ) : undefined
              }
              logo={
                URLLogoChannel ? (
                  <ThumborMediaImage
                    dimensions={
                      isTvDevice
                        ? DIMENSIONS.LOGO_CHANNEL.extraLarge
                        : DIMENSIONS.LOGO_CHANNEL.normal
                    }
                    url={URLLogoChannel}
                    alt={altLogoChannel}
                  />
                ) : undefined
              }
              title={
                isLogoTypedModeAndTitleNotIncrusted ? displayedTitle : undefined
              }
              subtitle={displayedSubtitle}
              sticker={stickerProps ? <Sticker {...stickerProps} /> : undefined}
              buttonIcon={overlayIcon}
            />
          }
          footer={
            isBottomTitle ? (
              <MediaFooterWrapper
                title={displayedTitle}
                subtitle={displayedSubtitle}
                titleDisplayMode={titleDisplayMode}
              />
            ) : undefined
          }
        />
      )}
    </Linker>
  );
}

export default ContentGridTemplateItem;

import type { IInitLiveTVUserProfile } from '@canalplus/oneplayer-types';
import { fetchInitLiveTV } from '@canalplus/oneplayer-webservices';
import type { AccessibleChannel } from '../../store/slices/user-type';
import { getLiveTVAccessibleChannels } from './getLiveTVAccessibleChannels';

export type ConfigFetchInitLiveTV = Parameters<
  typeof fetchInitLiveTV
>[0]['config'];

export async function fetchLiveTVAccessibleChannelsService(
  config: ConfigFetchInitLiveTV,
  userProfile: IInitLiveTVUserProfile
): Promise<AccessibleChannel[]> {
  try {
    const initLiveTVData = await fetchInitLiveTV({
      config,
      userProfile,
    });
    return getLiveTVAccessibleChannels(initLiveTVData);
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : 'Unknown error';
    console.warn(`Error Message fetchInitiLiveTV, ${errorMessage}`);

    return [];
  }
}

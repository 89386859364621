import { Ratio } from '@canalplus/mycanal-commons';
import type { HashObjectReturn } from '@canalplus/mycanal-util-react';
import type { ApiV2Contents } from '@dce-front/hodor-types/api/v2/strate_content/definitions';

export interface IMosaicState {
  contents: HashObjectReturn<ApiV2Contents>[];
  ratio: Ratio;
  pagination: boolean;
}

const getFormattedMosaic = (data: Record<string, any>): IMosaicState => {
  return {
    contents: data.contents,
    ratio: Ratio.Ratio43,
    pagination: data.paging,
  };
};

export default getFormattedMosaic;

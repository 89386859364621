import { ContentType } from '@canalplus/sdk-hodor';
import type { ApiV2PageSectionsList } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/sections_list';

const getFormattedSectionsList = (
  data: ApiV2PageSectionsList
): ApiV2PageSectionsList => {
  return {
    currentPage: data.currentPage,
    strates: data?.strates?.filter(({ type }) => type !== ContentType.Profiles),
    tracking: data.tracking,
  };
};

export default getFormattedSectionsList;

import {
  addQueryParam,
  fillTemplate,
  getQsValueByKey,
  isClientSide,
} from '@canalplus/mycanal-commons';
import type {
  OfferLocation,
  PassPlatform,
  Platform,
} from '@canalplus/sdk-core';
import type { AppKey } from '../../../config/application/types';
import { portailId } from '../../constants/pass';
import { Placeholders } from '../../constants/placeholders';
import constants from '../../constants/router';
import { Queries } from '../../constants/url';
import { HOME_KIDS_PATHNAME } from '../../store/constants';
import { isTim } from '../application/application-helper';
import { getPublicConfig } from '../config/config-helper';
import { getEncodedPassId } from '../user/user-helper';

export const isUrlContainsPassId = (url: string): boolean =>
  [Placeholders.PassID, Placeholders.PassId].some((passId) =>
    url.includes(passId)
  );

/**
 * Fill **passId** placeholder in an url
 *
 * @param url The **url** to fill
 * @returns The filled **url**
 */
export const fillUrlWithPassId = (url: string): string => {
  // PassID is not present on server side
  if (!isClientSide()) {
    return url;
  }

  const urlTokenQueryValue = getQsValueByKey(url, Queries.Token);

  if (!urlTokenQueryValue) {
    return url;
  }

  const passId = getEncodedPassId();

  if (!passId) {
    return url;
  }

  if (
    urlTokenQueryValue === Placeholders.PassId ||
    urlTokenQueryValue === Placeholders.PassID
  ) {
    return fillTemplate(url, [
      ['passId', passId],
      ['passID', passId],
    ]);
  }

  return url;
};

export const isUrlContainsMedia = (url: string): boolean =>
  url.includes(Placeholders.Media);

export const isUrlContainsAppLocation = (url: string): boolean =>
  url.includes(Placeholders.AppLocation);

/**
 * Delete queryString from url
 * @param {string} url
 * @returns {string}
 */
export const deleteQueryString = (url: string): string =>
  url ? url.split('?')[0] || '' : url;

export const getAuthorizationRequestUrl = (
  distributorId?: string,
  saleDevice?: string
): string => {
  const publicConfig = getPublicConfig();
  const {
    bom_base_url: bomBaseUrl,
    distributor_id: configDistributorId,
    sale_device: configSaleDevice,
  } = publicConfig.api.paymentProvider;

  return `${bomBaseUrl}/api/self/v1/payment/systempay/creditcard/AuthorizationRequest?distributorId=${
    distributorId || configDistributorId
  }&saleDevice=${saleDevice || configSaleDevice}`;
};

/**
 * @returns {string}
 */
export const getD2GUrl = (): string => {
  const publicConfig = getPublicConfig();
  const baseUrl = publicConfig.PLAYER.DOWNLOAD_TO_GO.BASE_URL;
  const bundleName = publicConfig.PLAYER.DOWNLOAD_TO_GO.BUNDLE_NAME;

  return `${baseUrl}${bundleName}`;
};

/**
 * Create delete cb one shot url with payment provider's config (kiss or turbo) informations
 * @param    {string} contractId
 * @returns  {string}
 */
export const getDeleteCreditCardUrl = (contractId: string): string => {
  const publicConfig = getPublicConfig();
  const baseUrl = publicConfig.api.paymentProvider.base_url;
  const path = publicConfig.api.paymentProvider.path_delete_credit_card;

  return fillTemplate(`${baseUrl}${path}`, [['contractId', contractId]]);
};

export const getFetchLiveGridFavoritesUrl = (
  cmsToken: string,
  appKey: AppKey
): string => {
  const {
    baseUrl,
    paths: { getFavoritesLiveGrid },
  } = getPublicConfig().api.hodor;

  return fillTemplate(`${baseUrl}${getFavoritesLiveGrid}`, [
    ['appKey', appKey],
    ['cmsToken', cmsToken],
  ]);
};

export const getOnePlayerBaseUrl = (platform: Platform): string => {
  const publicConfig = getPublicConfig();

  return platform === 'LG' || platform === 'PlayStation4'
    ? publicConfig.PLAYER.ONE_PLAYER.BASE_URLS[platform]
    : publicConfig.PLAYER.ONE_PLAYER.BASE_URLS.default;
};

export const getOnePlayerUrl = (platform: Platform): string => {
  const bundleName = getPublicConfig().PLAYER.ONE_PLAYER.BUNDLE_NAME;
  const baseUrl = getOnePlayerBaseUrl(platform);

  return `${baseUrl}${bundleName}`;
};

export const getOnePlayerMinimalUrl = (platform: Platform): string => {
  const bundleName = getPublicConfig().PLAYER.ONE_PLAYER.MINIMAL_BUNDLE_NAME;
  const baseUrl = getOnePlayerBaseUrl(platform);

  return `${baseUrl}${bundleName}`;
};

type GetPaymentProviderUrlParams = {
  turboMedia?: string;
  purchaseId?: string;
  saleDevice?: string;
  userId?: string;
};
export const getPaymentProviderUrl = ({
  turboMedia,
  purchaseId,
  saleDevice,
  userId,
}: GetPaymentProviderUrlParams): string => {
  const publicConfig = getPublicConfig();
  const {
    base_url: baseUrl,
    user_id: defaultUserId,
    sale_device: defaultSaleDevice,
  } = publicConfig.api.paymentProvider;

  const mediaQuery = turboMedia ? `?media=${turboMedia}` : '';

  const path = publicConfig.api.paymentProvider.path_process_payment;
  const paymentProviderPath =
    purchaseId && purchaseId !== ''
      ? fillTemplate(path, [['purchaseId', purchaseId]])
      : path;
  return isTim()
    ? `${baseUrl}${paymentProviderPath}${mediaQuery}`
    : `${baseUrl}${path}?userId=${userId || defaultUserId}&saleDevice=${saleDevice || defaultSaleDevice}`;
};

type GetRedirectPaymentUrlParams = {
  tokenCMS: string;
  mode: string;
  offerLocation: string;
  purchaseId?: string;
};

/**
 * Create a URL from configuration for path_redirect_payment
 */
export const getRedirectPaymentUrl = ({
  tokenCMS,
  mode,
  offerLocation,
  purchaseId = 'NO_PURCHASEID',
}: GetRedirectPaymentUrlParams): string => {
  const publicConfig = getPublicConfig();
  const redirectHost = `${publicConfig.api.redirect_host}/${offerLocation}`;
  const { path_redirect_payment } = publicConfig.api.paymentProvider;
  const redirectPaymentPath = fillTemplate(path_redirect_payment, [
    ['purchaseId', purchaseId],
    ['tokenCMS', tokenCMS],
  ]);
  // Return redirect payment URL with mode(deported/redirect) and redirectHost as query parameters
  return `${redirectPaymentPath}?mode=${mode}&redirectHost=${redirectHost}`;
};

/**
 * Create a URL to check hapi status for a purchaseId
 */
export const getPullingHapiStatusUrl = (
  purchaseId = 'NO_PURCHASEID'
): string => {
  const baseUrl = getPublicConfig().api.hapi.base_url;
  const path = getPublicConfig().api.hapi.path_pulling_hapi_redirect_payment;
  const pullingRedirectPaymentPath = fillTemplate(path, [
    ['purchaseId', purchaseId],
  ]);
  return `${baseUrl}${pullingRedirectPaymentPath}`;
};

export const getListUrl = (
  cmsToken: string,
  listType: string,
  appKey: AppKey
): string => {
  const {
    baseUrl,
    paths: { list },
  } = getPublicConfig().api.hodor;

  return fillTemplate(`${baseUrl}${list}`, [
    ['appKey', appKey],
    ['cmsToken', cmsToken],
    ['listType', listType],
  ]);
};

// Remove every prefixing from the hostname (dev, rec, etc...)
export const getProductionHostname = (url: string): string => {
  let ret = url;

  const possibleUrlPrefix = ['dev.', 'rec.'];
  possibleUrlPrefix.map((value) => {
    ret = ret.replace(value, '');
    return ret;
  });

  if (
    ret !== url &&
    ret.indexOf('www') === -1 &&
    ret.indexOf('kids') === -1 &&
    ret.indexOf('vod') === -1
  ) {
    ret = `www.${ret}`;
  }

  return ret;
};

/**
 * Return Hodor url to fetch Profile Api, with:
 *
 * - no appKey
 * - a properly filled media
 */
export const getProfilesListUrl = (media: PassPlatform): string => {
  const {
    api: {
      hodor: {
        baseUrl,
        paths: { profile },
      },
    },
  } = getPublicConfig();

  const baseUrlWithoutAppKey = baseUrl.replace('/{appKey}', '');

  return fillTemplate(`${baseUrlWithoutAppKey}${profile}`, [
    ['media', media],
    ['portailId', portailId],
  ]);
};

/**
 * Return Hodor url to fetch Avatar Api, with:
 *
 * - a properly filled media
 */
export const getAvatarUrl = (media: PassPlatform, appKey: AppKey): string => {
  const {
    api: {
      hodor: {
        baseUrl,
        paths: { avatar },
      },
    },
  } = getPublicConfig();

  return fillTemplate(`${baseUrl}${avatar}`, [
    ['appKey', appKey],
    ['media', media],
    ['portailId', portailId],
  ]);
};

export const getSaveLiveGridFavoritesUrl = (
  cmsToken: string,
  appKey: string
): string => {
  const {
    baseUrl,
    paths: { saveFavoritesLiveGrid },
  } = getPublicConfig().api.hodor;

  return fillTemplate(`${baseUrl}${saveFavoritesLiveGrid}`, [
    ['appKey', appKey],
    ['cmsToken', cmsToken],
  ]);
};

/**
 * Gives you the Id of the selected theme through the url
 * @param {string} theme query of the url
 * @returns {number | string (empty)}
 */
export const getThemeId = (theme?: string): number | string | undefined =>
  theme && Number(theme.split('-')[0]);

/**
 * Gives you the name of the selected theme through the url
 * @param {string} theme query of the url
 * @returns {bool | string (empty)}
 */
export const getThemeName = (theme?: string): string | undefined =>
  theme && theme.split('-')[1];

/**
 * Replace {tokenCMS} in the url
 * @param    {string} url       url to parse
 * @param    {string} tokenCMS  value of the tokenCMS
 * @returns  {string}  -        return the new url with tokenCMS
 */
export const getUrlWithTokenCMS = (url: string, tokenCMS: string): string =>
  url.replace('{tokenCMS}', tokenCMS);

/**
 * Returns url without default langKey
 */
export const getUrlWithoutDefaultLang = (url?: string): string => {
  const defaultLangKey = getPublicConfig().defaultLocale?.langKey;
  return url?.replace(`/${defaultLangKey}/`, '/') || '';
};

const getOfferLocationPath = (offerLocation: string, pathname: string) => {
  const defaultOfferLocation = getPublicConfig().defaultLocale?.offerLocation;

  if (pathname.includes('/ch/de')) {
    return '/ch/de';
  }

  return offerLocation === defaultOfferLocation ? '/' : `/${offerLocation}`;
};

/**
 * return the path when changing profile on WhoIsWatching view
 * @param {string} offerLocation offerLocation (fr, sn, ch...)
 * @param {string} pathName pathname with including offerLocation path
 * @param {string | undefined} kidsHomeUrl url of kidsHome for isKids profile, undefined if it's not a isKids profile
 * @returns {string}
 */
export const getRedirectPathOnWhoIsWatchingProfile = (
  offerLocation: OfferLocation,
  currentPathName: string,
  kidsHomeUrl?: string
): string => {
  const offerLocationPath = getOfferLocationPath(
    offerLocation,
    currentPathName
  );

  if (kidsHomeUrl) {
    // avoid duplication of '/'
    if (offerLocationPath === '/') {
      return kidsHomeUrl;
    }

    return `${offerLocationPath}${kidsHomeUrl}`;
  }

  // if we are coming from isKids Profile, --> redirect to homepage with current offerLocation
  if (currentPathName.includes(HOME_KIDS_PATHNAME)) {
    return offerLocationPath;
  }

  return currentPathName;
};

/**
 * return the redirectUrl when changing profile on ProfileSelector (Header)
 * @param {string} offerLocation offerLocation (fr, sn, ch...)
 * @param {string} currentPathName current pathname with including offerLocation path
 * @param {bool} isTvDevice
 * @param {string | undefined} kidsHomeUrl url of kidsHome for isKids profile, undefined if it's not a isKids profile
 * @returns {string}
 */
export const getRedirectUrlOnSelectProfile = (
  offerLocation: OfferLocation,
  currentPathName: string,
  isTvDevice: boolean = false,
  kidsHomeUrl?: string
): string => {
  const offerLocationPath = getOfferLocationPath(
    offerLocation,
    currentPathName
  );
  const defaultOfferLocation = getPublicConfig().defaultLocale?.offerLocation;
  const isDefaultOfferLocation = offerLocation === defaultOfferLocation;

  let redirectPath = offerLocationPath;

  if (kidsHomeUrl) {
    redirectPath = !isDefaultOfferLocation
      ? `${offerLocationPath}${kidsHomeUrl}`
      : kidsHomeUrl;
  }

  if ($_BUILD_RENDERMODE_CSR) {
    // on defaut offerLocation (fr) and not isKids profile
    if (isDefaultOfferLocation && !kidsHomeUrl) {
      return window.location.href;
    }

    // On CSR Mode, It is necessary to make the redirection only by modifying the "path" query (See "oneDiscoveryBuffer" helper)
    return addQueryParam(window.location.href, 'path', redirectPath);
  }

  if (isTvDevice) {
    // On OneDiscovery (except Full CSR Mode), we have to redirect on buffer page to keep the tv context
    redirectPath = constants.HOMEPAGE_TV_PATH;
  }

  return `${window.location.origin}${redirectPath}`;
};

export const arePathnamesEqual = (
  pathname1: string,
  pathname2: string
): boolean => pathname1.split('?')[0] === pathname2.split('?')[0];

export const isIntegration = (): boolean =>
  isClientSide() &&
  new URL(window.location.origin).host.split('.').includes('int');
